import React, {Fragment} from "react";
import {Outlet} from "react-router-dom";
import { ToastContainer } from "react-toastify";
// Layout
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";

// Components
import ThemeOptions from "../../../Layout/ThemeOptions";
import AppFooter from "Layout/AppFooter";

const Weekstaten = () => {

    return (
        <Fragment>
            <AppHeader/>
            <ThemeOptions/>
            <div className="app-main">
                <AppSidebar/>
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Outlet />
                    </div>
                </div>
            </div>
            <ToastContainer/>
            <AppFooter/>
        </Fragment>
    )
};

export default Weekstaten;

import React from 'react';
import moment from 'moment';
import TextLimit from "Api/TextLimit";
import { Table } from 'reactstrap';

const WeekTable = ({ list, sum }) => {
    return (
        <Table size="sm" borderless className="mb-3 table-responsive">
            <thead>
                <tr>
                    <th className="text-left w-32">&nbsp;</th>
                    <th className="text-left w-32">&nbsp;</th>
                    <th className="text-left w-32">&nbsp;</th>
                    <th colSpan="6" className="custom-border-left text-center">Ochtend</th>
                    <th colSpan="6" className="custom-border-left text-center">Middag</th>
                    <th colSpan="2" className="custom-border-left text-center">Totaal</th>
                </tr>
                <tr>
                    <th className="text-left">Week</th>
                    <th className="text-left">Dag</th>
                    <th className="text-left">Datum</th>
                    <th className="text-left custom-border-left">School</th>
                    <th>Bestuur</th>
                    <th>Verzoek</th>
                    <th>DT</th>
                    <th>WTF</th>
                    <th>Uren</th>
                    <th className="text-left custom-border-left">School</th>
                    <th>Bestuur</th>
                    <th className="text-left">Verzoek</th>
                    <th>DT</th>
                    <th>WTF</th>
                    <th>Uren</th>
                    <th className="custom-border-left text-center">WTF</th>
                    <th className="text-center">Uren</th>
                </tr>
            </thead>
            <tbody>
                {list && Object.keys(list).map((key) => {
                    const ochtendData = list[key].ochtend || {};
                    const middagData = list[key].middag || {};

                    const week = ochtendData.weeknr || middagData.weeknr || '';
                    const day = new Date(moment(key, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' });
                    const wtf = (middagData.wtf || 0) + (ochtendData.wtf || 0);
                    const uren = (middagData.uren || 0) + (ochtendData.uren || 0);

                    return (
                        <tr key={key}>
                            <td>{week}</td>
                            <td>{day}</td>
                            <td>{key}</td>
                            <td className="custom-border-left">
                                {ochtendData.school && (
                                    window.innerWidth > 1250
                                        ? TextLimit.limitStringLargeScreen(ochtendData.school)
                                        : TextLimit.limitStringSmallScreen(ochtendData.school)
                                )}
                            </td>
                            <td>{ochtendData.bestuur && (
                                window.innerWidth > 1250
                                    ? TextLimit.limitStringLargeScreen(ochtendData.bestuur)
                                    : TextLimit.limitStringSmallScreen(ochtendData.bestuur)
                            )}</td>
                            <td>{ochtendData.verzoek}</td>
                            <td>{ochtendData.dt || ''}</td>
                            <td>{ochtendData.wtf?.toFixed(4) || ''}</td>
                            <td>{ochtendData.uren?.toFixed(4) || ''}</td>
                            <td className="custom-border-left">
                                {middagData.school && (
                                    window.innerWidth > 1250
                                        ? TextLimit.limitStringLargeScreen(middagData.school)
                                        : TextLimit.limitStringSmallScreen(middagData.school)
                                )}
                            </td>
                            <td>{middagData.bestuur && (
                                window.innerWidth > 1250
                                    ? TextLimit.limitStringLargeScreen(middagData.bestuur)
                                    : TextLimit.limitStringSmallScreen(middagData.bestuur)
                            )}</td>
                            <td>{middagData.verzoek}</td>
                            <td>{middagData.dt || ''}</td>
                            <td>{middagData.wtf?.toFixed(4) || ''}</td>
                            <td>{middagData.uren?.toFixed(4) || ''}</td>
                            <td className="custom-border-left text-center">{wtf.toFixed(4)}</td>
                            <td className="text-center">{uren.toFixed(4)}</td>
                        </tr>
                    );
                })}
                <tr>
                    <td colSpan={3}></td>
                    <td colSpan={6} className="custom-border-left"></td>
                    <td colSpan={6} className="custom-border-left"></td>
                    <td className="custom-border-left text-center">{sum?.wtf !== undefined ? sum?.wtf.toFixed(4) : ''}</td>
                    <td className="text-center">{sum?.uren !== undefined ? sum?.uren.toFixed(4) : ''}</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default WeekTable;
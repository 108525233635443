import axiosInstance from "Api";

const distanceList = (value) => {
    return axiosInstance.get('/verzoeken/scherm/distance-list/' + value);
};

const deleteDistance = (data) => {
    return axiosInstance.post('/verzoeken/scherm/remove-distance', data);
};

const updateDistance = (data) => {
    return axiosInstance.post('/verzoeken/scherm/update-distance', data);
};

const DistanceService = {
    distanceList,
    deleteDistance,
    updateDistance
};
export default DistanceService;
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import UserInfoPopup from "./UserInfoPopup";
import {HiOutlineDotsCircleHorizontal} from "react-icons/hi";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { BsCheckCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { TiFolderOpen } from "react-icons/ti";
import Tippy from "@tippyjs/react";

export const iconSwitch = (param) => {
    switch (param) {
        case 'Openstaand':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align"><HiOutlineDotsCircleHorizontal fontSize={"18px"} /></span></Tippy>;
        case 'Aangevraagd':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align"><HiOutlineQuestionMarkCircle color="#ffa73f" fontSize={"18px"} /></span></Tippy>;
        case 'Ingepland':
            return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' fontSize={"14px"} /></span></Tippy>;
        case 'Geannuleerd':
            return <Tippy content={param} placement="bottom"><span style={{ margin: -4 }}><TiDeleteOutline color='#FB2E5F' fontSize={"20px"} /></span></Tippy>;
        case 'Historie':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align"><TiFolderOpen fontSize={"18px"} /></span></Tippy>;
        default:
            return '--';
    }
}

const WeekReportTable = ({ reportData }) => {
    const [totalWtf, setTotalWtf] = useState(0);

    const toNumber = str => {
        if (typeof str !== 'undefined') {
            return parseFloat(str.replace(",", "."));
        } return 0;
    };

    useEffect(() => {
        setTotalWtf(
            Math.round(
                reportData
                    .map(e => toNumber(e.data.O?.vWtfValue) + toNumber(e.data.M?.vWtfValue))
                    .reduce((x, y) => x + y) * 10000) / 10000
        );
    }, [reportData]);



    const columns = (data) => [

        {
            title: "Geboortedatum",
            value: data?.dBirthdate !== undefined && data?.dBirthdate !== null && data?.dBirthdate.length !== 0 ? data.dBirthdate : "--"
        },
        {
            title: "Salaris Id",
            value: data?.vSalarisId !== null && data?.vSalarisId !== undefined && data?.vSalarisId.length !== 0 ? data.vSalarisId : "--"
        }
    ];

    if (!reportData) return null;
    return (
        <div className="dagdelen-tb">
            <h6 className="mb-3 text-blue">Dagdelen</h6>
            <Table responsive hover size="sm" className="mb-0">
                <thead>
                    <tr>
                        <th className="text-lef w-32 py-3 table-right-border" colSpan="2">&nbsp;</th>
                        <th colSpan="6" className="text-center py-3 table-right-border">Ochtend</th>
                        <th colSpan="6" className="text-center py-3">Middag</th>
                    </tr>
                    <tr>
                        <th className="text-left table-right-border" colSpan="2">Datum</th>

                        <th className="text-left">Vervanger</th>
                        <th>WTF</th>
                        <th>CT</th>
                        <th>DT</th>
                        <th className="text-left">Status</th>
                        <th className="text-left table-right-border">Salaris</th>
                        <th className="text-left ">Vervanger</th>
                        <th>WTF</th>
                        <th>CT</th>
                        <th>DT</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Salaris</th>
                    </tr>
                </thead>
                <tbody>
                    {reportData.map((block, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-capitalize" width="35px">
                                    {new Date(moment(block.day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' })}
                                </td>
                                <td className="table-right-border cursor-pointer" width="80px">{block.day}</td>
                                {
                                    block.data.O ?
                                        <>
                                            <td>
                                                <div className="overflow-ellipsis ws">
                                                    <UserInfoPopup name={block.data.O.vAbsentName} columns={columns(block.data.O)} />
                                                </div>
                                            </td>
                                            <td>{block.data.O.iClooserId > 0 ? block.data.O.vWtf + " " + block.data.O.vWtfValue : "--"} </td>
                                            <td>{block.data.O.iClooserId > 0 ? block.data.O.ct : "--"} </td>
                                            <td>{block.data.O.iClooserId > 0 ? block.data.O.dt : "--"} </td>
                                            <td className="text-center">{block.data.O.iClooserId > 0 ? iconSwitch(block.data.O.vStatus) : "--"} </td>
                                            <td className="text-center table-right-border">
                                                {
                                                    block.data.O.bBlock ? '--' :
                                                        (reportData?.disableSalary === 'N' ?
                                                            (block.data.O.bluebox_status === 'idle' ?
                                                                (
                                                                    block.data.O.bluebox_status === 'sent' ? <BsCheckCircle color='#39A825' fontSize={"14px"} /> :
                                                                        <input type="checkbox" disabled={block.data.O.bluebox_status === 'sent' ? true : false} />
                                                                ) : <BsCheckCircle color='#39A825' fontSize={"14px"} />) :
                                                            (
                                                                block.data.O.bluebox_status !== 'idle' ? <BsCheckCircle color='#39A825' fontSize={"14px"} /> :
                                                                <HiOutlineDotsCircleHorizontal fontSize={"18px"} />
                                                            ))
                                                }

                                            </td>
                                        </>
                                        :
                                        <td colSpan="6" className="text-center table-right-border">&nbsp;</td>
                                }
                                {
                                    block.data.M ?
                                        <>
                                            <td>
                                                <div className="overflow-ellipsis ws">
                                                    <UserInfoPopup name={block.data.M.vAbsentName} columns={columns(block.data.O)} />
                                                </div>
                                            </td>
                                            <td>{block.data.M.iClooserId > 0 ? block.data.M.vWtf + " " + block.data.M.vWtfValue : "--"} </td>
                                            <td>{block.data.M.iClooserId > 0 ? block.data.M.ct : "--"} </td>
                                            <td>{block.data.M.iClooserId > 0 ? block.data.M.dt : "--"} </td>
                                            <td className="text-center">{block.data.M.iClooserId > 0 ? iconSwitch(block.data.M.vStatus) : "--"} </td>
                                            <td className="text-center">
                                            {
                                                    block.data.M.bBlock ? '--' :
                                                        (reportData?.disableSalary === 'N' ?
                                                            (block.data.M.bluebox_status === 'idle' ?
                                                                (
                                                                    block.data.M.bluebox_status === 'sent' ? <BsCheckCircle color='#39A825' fontSize={"14px"} /> :
                                                                        <input type="checkbox" />
                                                                ) : <BsCheckCircle color='#39A825' fontSize={"14px"} />) :
                                                            (
                                                                block.data.M.bluebox_status !== 'idle' ? <BsCheckCircle color='#39A825' fontSize={"14px"} /> :
                                                                <HiOutlineDotsCircleHorizontal fontSize={"18px"} />
                                                            ))
                                                }
                                            </td>
                                        </>
                                        :
                                        <td colSpan="6" className="text-center">&nbsp;</td>
                                }
                            </tr>
                        )
                    })
                    }
                </tbody>
            </Table>
        </div>
    );
};

export default WeekReportTable;

import PersonalDetails from "./PersonalDetails";
import ContactDetails from "./ContactDetails";
import Photo from "./Photo";
import LoginDetails from "./LoginDetails";
import Role from "./Role";
import { Button, Card, CardBody, CardFooter } from "reactstrap";
import { useNavigate } from "react-router";
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import GebruikerViewService from "Api/Gebruikers/GebruikersView";
import { useState } from "react";
import { debounce } from "../utils";
import { toast } from "react-toastify";
import Loader from 'react-loaders';

const Algemeen = ({ data, currentUser, isLoading, setDataUpdated }) => {
    const navigate = useNavigate();
    const [errorField, setErrorField] = useState('');

    const callApi = (field, value) => {
        if(field === "vUserName" && value === data?.vUserName){
            return; 
        }
        const bodyData = {
            iAdminId: currentUser.data.clooserId,
            attr: field,
            [field]: value,
        }
        GebruikerViewService.updateGebruikerDetail(bodyData)
            .then(response => {
                if (response.data.status) {setDataUpdated(prev=>!prev); return;}
                if (!response.data.success) {
                    setErrorField(field);
                    const errors = response.data.data[field];
                    if (Array.isArray(errors)) {
                        toast.error(errors.join(','));
                    } else {
                        toast.error(errors);
                    }
                }
                console.log('API response:', response.data);
            })
            .catch(error => {
                console.error('API error:', error);
            });
    };

    const debouncedApiCall = debounce(callApi, 2000);

    const handleFieldChange = (field, value) => {
        setErrorField('');
        debouncedApiCall(field, value);
    };

    return (
        <>
            {
                isLoading ?
                    <Card className="main-card">
                        <CardBody className="d-flex flex-column">
                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                        </CardBody>
                    </Card> :
                    <>
                        <div className='row algemeen'>
                            <div className='col-xxl-4 col-lg-6'>
                                <PersonalDetails data={data} currentUser={currentUser} errorField={errorField} changeHandler={handleFieldChange} />
                            </div>
                            <div className='col-xxl-8 col-lg-6'>
                                <div className='row'>
                                    <div className='col-xxl-6 col-12'>
                                        <ContactDetails data={data} changeHandler={handleFieldChange} errorField={errorField} />
                                    </div>
                                    <div className='col-xxl-6 col-12'>
                                        <Photo data={data} currentUser={currentUser} />
                                    </div>
                                    <div className='col-xxl-6 col-12'>
                                        <LoginDetails data={data} currentUser={currentUser} changeHandler={handleFieldChange} errorField={errorField} />
                                    </div>
                                    <div className='col-xxl-6 col-12'>
                                        <Role currentUser={currentUser} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="chat-layout-footer">
                            <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                                <div className="d-flex gap-3">
                                    <Button
                                        outline
                                        className="btn-outline-primary-blue ms-auto"
                                        onClick={() => {
                                            navigate("/gebruikers")
                                        }}
                                        type="button"
                                        color="primary"
                                    >
                                        <BackIcon />
                                    </Button>
                                </div>
                            </CardFooter>
                        </div>
                    </>
            }
        </>
    )
}

export default Algemeen
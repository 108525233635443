import React from 'react';
import { Navigate } from 'react-router-dom';
import Auth from '../../services/Auth';
import HasPermission from "Components/Router/Permission";

const PrivateRoute = ( { children, hideFor, showFor }) => {

    return Auth.isLoggedIn()
        ? <HasPermission
            hideFor={hideFor}
            showError={true}
            showFor={showFor}
            >{children}</HasPermission>
        : <Navigate to="/login" />;
}

export default PrivateRoute;

import React, { useState } from 'react';
import { Form, Formik, Field } from "formik";
import { Button, Card, CardBody, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import * as Yup from 'yup';
import { ReactComponent as UpdatePasswordIcon } from "../../../../../assets/utils/images/svgs/update-password.svg";
import { toast } from 'react-toastify';
import { GENERATE_PASSWORD, UPDATE_PASSWORD } from '../utils';
import BootstrapInputGroup from 'Pages/Profile/Forms/BootstrapInputGroup';
import GebruikerAlgemeenService from 'Api/Gebruikers/GebruikersView/Algemeen';

const validationSchema = Yup.object({
    password: Yup.string()
        .matches("^\\S*$", "Wachtwoord mag geen spaties hebben")
        .required('Wachtwoord is verplicht')
        .min(8, "Wachtwoord moet minimaal 8 tekens lang zijn")
        .matches("[A-Za-z]+", "Gebruik hoofdletters en kleine letters a-z en A-Z tekens")
        .matches("[0-9]+", "Wachtwoord moet cijfers bevatten")
        .matches("[`!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?~]+", "Wachtwoord moet speciale tekens bevatten !@#$%^&*()_+"),
});

const LoginDetails = ({ data, currentUser, changeHandler, errorField }) => {
    const [modal, setModal] = useState(false);
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [isLoader, setLoader] = useState(false);
    const [linkGenerated, setLinkGenerated] = useState(false);
    const defaultValues = {
        clooserUn: data?.vUserName || '',
    };

    const updatePasswordHandler = (type, password='') => {
        setGeneratedPassword('');
        setLinkGenerated(false)
        const data = {
            iAdminId: currentUser.data.clooserId,
            vPassword: type === GENERATE_PASSWORD ? '' : password
        }
        if (type === GENERATE_PASSWORD) setLoader(true);
        GebruikerAlgemeenService.updateGebruikerPassword(data)
            .then(response => {
                if (response.data.status) {
                    if (type === UPDATE_PASSWORD) {
                        setModal(false);
                        toast.success('Wachtwoord succesvol bijgewerkt.')
                    } else {
                        setGeneratedPassword(response.data.newPassword)
                        setLoader(false);
                    }
                };
            })
            .catch(error => {
                console.error('API error:', error);
            });
    }

    const forgottenPasswordLink = ()=>{
        setGeneratedPassword('');
        const data = {
            iAdminId: currentUser.data.clooserId
        }
        GebruikerAlgemeenService.gebruikerForgotPassword(data)
        .then(response => {
            if (response.data.status) {
                setLinkGenerated(true);
            };  
        })
        .catch(error => {
            console.error('API error:', error);
        });
    }

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <h5 className="mb-4">Inloggegevens</h5>
                    <Formik
                        enableReinitialize
                        initialValues={defaultValues}
                    >
                        {({ values, handleChange }) => (
                            <Form>
                                <FormGroup className={`form-element ${errorField === 'clooserUn' ? 'error' : ''}`}>
                                    <Label for="clooserUn">Gebruikersnaam</Label>
                                    <Input name="clooserUn" 
                                    data-lpignore="true"
                                    autoComplete="off"
                                    value={values.clooserUn} type="text" onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('clooserUn', e.target.value);
                                    }} />
                                </FormGroup>
                                <FormGroup className="form-element">
                                    <Label>Wachtwoord</Label>
                                    <div className="pwd-box d-flex justify-content-center gap-4 w-100 px-3">
                                        wachtwoord wijzigen
                                        <UpdatePasswordIcon onClick={() => setModal(prev => !prev)} className="cursor-pointer" />
                                    </div>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                    <Table responsive hover className='mt-3 tb-gebruikers'>
                        <thead>
                            <tr>
                                <th>Locatie</th>
                                <th>Laatste login</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Website</td>
                                <td>{data?.iLastWebLogin}</td>
                            </tr>
                            <tr>
                                <td>App</td>
                                <td>{data?.iLastAppLogin}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="d-flex gap-3 justify-content-center mt-4">
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3"
                            type="button"
                            color="primary"
                            onClick={forgottenPasswordLink}
                        >
                            Stuur wachtwoord vergeten-mail
                        </Button>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3"
                            type="button"
                            color="primary"
                            onClick={() => { updatePasswordHandler(GENERATE_PASSWORD) }}
                        >
                            Genereer wachtwoord
                        </Button>
                    </div>
                    <div className='mt-3 text-center'>
                        {linkGenerated && <p>Wachtwoord vergeten mail is verstuurd.</p>}
                        {isLoader && <p>Bezig met aanmaken van het wachtwoord, ogenblik geduld...</p>}
                        {generatedPassword && <p>Het nieuwe wachtwoord is: {generatedPassword}</p>}
                    </div>
                </CardBody>
            </Card>
            <Modal isOpen={modal} toggle={() => { setModal(prev => !prev)}} className="feedback-modal-style link-form modal-centered">
                <Formik
                    initialValues={{ password: '' }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        updatePasswordHandler(UPDATE_PASSWORD, values.password)
                    }}
                >
                    {({ handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            <ModalHeader toggle={() => { setModal(prev => !prev) }}>
                                Wachtwoord
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label for="password">Wachtwoord invoeren</Label>
                                    <Field name="password">
                                        {({ field, form, meta }) => (
                                            <BootstrapInputGroup field={field} meta={meta} />
                                        )}
                                    </Field>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4">
                                <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="submit" disabled={isSubmitting}>
                                    Toepassen
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    )
};

export default LoginDetails;

import React, { useState } from "react";
import { Card, CardBody, CardHeader, Button } from "reactstrap";
import { DropdownList } from "react-widgets";
import DatesLayout from "./DatesLayout";
import dayjs from "dayjs";
import CommunityTypesModal from './CommunityTypesModal';
import LegendModal from './LegendModal';

const modalText="Geef hieronder aan voor welk bestuur je een aparte bestuursbeschikbaarheid wilt invullen"

const Availability = () => {
    const [years, setYears] = useState(`${dayjs().year()}-${dayjs().year() + 1}`);
    const [openInstitutions, setOpenInstitutions] = useState(false);
    const [openLegend, setOpenLegend] = useState(false);
    const [communities, setCommunities] = useState([]);
    const isStandart = (communities.length && communities.some(comm => comm.type !== "S")) ? "A" : "S";
    const [type, setType] = useState(isStandart);

    const yearsRange = Array.from({ length: 4 }, (_, i) => `${dayjs().year() + i}-${dayjs().year() + i + 1}`);

    return (
        <div className="availability">
        <LegendModal isOpen={openLegend} open={setOpenLegend} />
        <CommunityTypesModal isOpen={openInstitutions} open={setOpenInstitutions} communities={communities} type={type} setType={setType} text={modalText}/>

        <Card className="main-card mb-3">
            <CardHeader className="row m-0 justify-content-between">
                <div className="col-md-5">
                        <span className="ps-0 planbord">
                            <span className="text-planbord w-100" style={{color: "#878ea6", fontWeight: 500}}>Selecteer  Jaargang</span>
                            <DropdownList
                                data={yearsRange}
                                value={years}
                                textField="name"
                                onChange={val => setYears(val)} />
                        </span>
                </div>
                <div className="col-md-3 w-auto">
                    <Button outline className="me-2 btn-outline btn-color-style custom-btn-padding" 
                        onClick={() => setOpenLegend(true)}
                        type="button" color="secondary">
                        Legenda
                    </Button>
                    <Button outline className="me-2 btn-outline btn-color-style custom-btn-padding"
                        onClick={() => setOpenInstitutions(true)}
                        type="button" color="secondary">
                        Instellingen
                    </Button>
                </div>
            </CardHeader>
            <CardBody className="p-3 table-responsive">
                <DatesLayout years={years} type={type} setCommunities={setCommunities} />
            </CardBody>
        </Card>
        </div>
    )
}

export default Availability;
import React, { useState } from 'react'
import { ReactComponent as CrossIcon } from "../../../../../assets/utils/images/svgs/Cross.svg";
import { BsCheckCircle } from "react-icons/bs";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, Button } from 'reactstrap';
import GebruikerViewService from 'Api/Gebruikers/GebruikersView';
import { roleColors, userRoles } from '../utils';

const RolesIcon = ({ iAdminId, contractId, value, type, id, updateHandler, selectedSchoolId }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const [contractList, setContractList] = useState([]);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [schoolList, setSchoolList] = useState([]);
    const [coorModal, setCoorModal] = useState(false);

    const handleIconClick = (userType) => {
        setSelectedType(userType);
        if (type === userRoles.Coordinator) {
            setCoorModal(true)
        } else {
            toggleDropdown();
            fetchDataByType(userType, id);
        }
    }
    const toggleCoorModal = () => {
        setCoorModal((prev) => !prev)
    }
    const getRoleColor = (index) => roleColors[index % roleColors.length];

    const fetchDataByType = async (type, id) => {
        try {
            if (type === userRoles.Vervanger && id) {
                const response = await GebruikerViewService.contractType(id);
                if (response.data.status === 200 && response.data.data.length > 0) {
                    setContractList(response.data.data);
                }
            } else if (type === userRoles.Melder) {
                const response = await GebruikerViewService.schoolList(id, iAdminId);
                if (response.data.status && response.data.data.length > 0) {
                    setSchoolList(response.data.data);
                }
            }
        } catch (error) {
            console.error(`API error:`, error);
        }
    };

    const handleRadioChange = (schoolId, iAdmschoolId) => {
        const isSelected = selectedSchoolId.length > 0 ? selectedSchoolId.includes(schoolId) : false;
        updateHandler(userRoles.Melder, id, !isSelected, '', schoolId, iAdmschoolId);
        setTimeout(() => toggleDropdown(), 500);
    };

    const renderDropdownItems = () => {
        switch (selectedType) {
            case userRoles.Vervanger:
                return (
                    <>
                        {value === 1 && <DropdownItem onClick={() => updateHandler(userRoles.Vervanger, id, false, '', '')}><div className='role-color'></div>Ontkoppelen als vervanger</DropdownItem>}
                        {value === 0 && <DropdownItem disabled={value === 0 ? true : false}
                            onClick={() => updateHandler(userRoles.Vervanger, id, true, '', '')}>
                            <div className='role-color'></div>Select contractype</DropdownItem>}
                        {contractList.map((item, index) => {
                            const bgColor = getRoleColor(index);
                            return <DropdownItem
                                value={item.iContractTypeId}
                                className={String(contractId) === item.iContractTypeId ? 'selected-item' : ''}
                                style={{
                                    backgroundColor: contractId === item.iContractTypeId ? '#f0f0f0' : 'transparent',
                                }}
                                onClick={() => updateHandler(userRoles.Vervanger, id, true, item.iContractTypeId, '')}
                                key={item.iContractTypeId}>
                                <div className='role-color' style={{ backgroundColor: bgColor }}></div>{item.vContractTypeName}
                            </DropdownItem>
                        })}
                    </>
                );
            case userRoles.Melder:
                return (
                    <div className='p-2 d-flex flex-column gap-2'>
                        {schoolList.map(item => {
                            return <label className='d-flex cursor-pointer gap-2' style={{ flex: 'none' }} key={item.iAdmschoolId}>
                                <input type="checkbox" name="melderOption"
                                    checked={selectedSchoolId?.length > 0 ? selectedSchoolId.includes(item.iSchoolId) : false}
                                    onChange={() => handleRadioChange(item.iSchoolId, item.iAdmschoolId)}
                                    className="travelCost-customRadio"
                                    value={item.iSchoolId} />
                                {item.vSchoolName}
                            </label>
                        })}
                        {schoolList.length === 0 && "Geen opties beschikbaar"}
                    </div>
                );
            default:
                return <></>;
        }
    }

    return (
        <>
            {type === userRoles.Coordinator ? <div className='cursor-pointer text-center'>{value === 1 ? (
                <BsCheckCircle
                    color='#3AC47D'
                    fontSize={"18px"}
                    onClick={() => handleIconClick(type)}
                />
            ) : (
                <CrossIcon
                    fontSize={"18px"}
                    onClick={() => handleIconClick(type)}
                />
            )}</div> : <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className='role-dropdown mx-auto'>
                <DropdownToggle>
                    {value === 1 ? (
                        <BsCheckCircle
                            color='#3AC47D'
                            fontSize={"18px"}
                            onClick={() => handleIconClick(type)}
                        />
                    ) : (
                        <CrossIcon
                            fontSize={"18px"}
                            onClick={() => handleIconClick(type)}
                        />
                    )}
                </DropdownToggle>
                <DropdownMenu>
                    {renderDropdownItems()}
                </DropdownMenu>
            </Dropdown>}
            <Modal isOpen={coorModal} toggle={toggleCoorModal} className="feedback-modal-style role-modal modal-centered">
                <ModalBody>
                    <p className='mt-5 text-center'>
                        {value === 1 ? 'Rol coördinator [Organisation] van [Naam] verwijderen?' : 'Rol coördinator [Organisation] van [Naam] toevoegen?'}
                    </p>
                    <div className="d-flex align-items-center justify-content-center border-bottom-0 gap-4 py-4">
                        <Button className="btn-color-style opslaan-btn px-4" size="lg" outline color="primary"
                            onClick={() => {
                                updateHandler(userRoles.Coordinator, id, value === 1 ? false : true, '', '');
                                toggleCoorModal()
                            }}
                        >
                            Ja
                        </Button>
                        <Button className="btn-color-style opslaan-btn px-4" size="lg" outline color="primary"
                            onClick={toggleCoorModal}>
                            Nee
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};
export default RolesIcon
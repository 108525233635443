import React, {Fragment, useEffect, useState} from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";

// Components
import GeneralInformation from "./Steps/GeneralInformation";
import Calender from "./Steps/Calender";
import MultiStep from "./Wizard"
import AppFooter from "Layout/AppFooter";
import axiosInstance from "Api";
import { useLocation, useNavigate } from "react-router";
import AuthService from "Api/Auth";
import { rolesList } from "Components/Router/Permission";
import { coordinatorPath } from "Layout/AppNav/helpers";
import TextLimit from "Api/TextLimit";

export const initialValues = {
    organisationId: '',
    schoolId: '',
    absentId: '',
    groupsId: [],
    codeId: '',
    type: '',
    planning: '',
    label: '',

    iHowToInformId: '',
    informEmailExtra1: '',
    informEmailExtra2: [],

    gym: [],
    substitutionNote: '',
    schedulerNote: '',
    dateRange: [],
}

const initialFormValues = {
    organisation: '',
    school: '',
    absent: '',
    groups: [],
    code: '',
    type: '',
    planning: '',
    label: '',

    isOpen: false,

    iHowToInformId: '',
    informEmailExtra1: [],
    informEmailExtra2: [],

    gym: [],
    substitutionNote: '',
    schedulerNote: '',
    dateRange: [],
}

const validationSchema = Yup.object().shape({
    organisationId: Yup.string().required('Verplicht veld'),
    schoolId: Yup.string().required('Verplicht veld'),
    // absentId: Yup.string().required('Verplicht veld'),
    groupsId: Yup.array().min(1, 'Selecteer minimaal 1 groep').required('Verplicht veld'),
    codeId: Yup.string().required('Verplicht veld'),
    // type: Yup.string().required('Verplicht veld'),
    planning: Yup.string().required('Verplicht veld'),
    dateRange: Yup.array().min(1, 'Selecteer minimaal 1 of meer dagdelen').required('Verplicht veld'),
    informEmailExtra2: Yup.array().of( Yup.string().email('Ongeldig e-mail')),
});

const steps = (
    formikProps,
    formDataState,
    setFormDataState,
    isDisabled,
    setIsDisabled,
    organizationsList,
    codeListData,
    schools,
    absentsData,
    groupsData,
    getSchoolsByCommunityId,
    getAbsentsList,
    usersData,
    link,
    setLink
) => [
    {
        name: "Basis informatie",
        component: <GeneralInformation
            formikProps={formikProps}
            formDataState={formDataState}
            setFormDataState={setFormDataState}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            organizationsList={organizationsList}
            codeListData={codeListData}
            schools={schools}
            absentsData={absentsData}
            groupsData={groupsData}
            getSchoolsByCommunityId={getSchoolsByCommunityId}
            getAbsentsList={getAbsentsList}
            usersData={usersData}
            link={link}
            setLink={setLink} />
    },
    {
        name: "Selecteer dagen",
        component: <Calender
            formikProps={formikProps}
            formDataState={formDataState}
            setFormDataState={setFormDataState} />
    },
];



const IndienenSubstitution = ({match}) => {
    const [formDataState, setFormDataState] = useState(initialFormValues); // 'global' state for whole form
    const [isDisabled, setIsDisabled] = useState(false); // disable moving to the next step
    // state for basic information column
    const [organizationsList, setOrganizationsList] = useState([]);
    const [codeListData, setcodeListData] = useState([]);
    const [communityId, setcommunityId] = useState(null);
    const [schools, setSchools] = useState([]);
    const [absentsData, setAbsentsData] = useState([]);
    const [groupsData, setgroupsData] = useState([]);
    // state for additional information column
    const [usersData, setUsersData] = useState([]);
    const [link, setLink] = useState(null);
    const [disabledButton, setDisabledButton] = useState(false);

    const location = useLocation();
    const navigate = useNavigate(); // for onSubmit function (to move to 'verzoeken' page)
    const isCoordinator = AuthService.getUserRoles().includes(rolesList.coordinator.name);

    // get organisation data for basic information column
    useEffect(() => {
        if (organizationsList.length === 0) {
            getOrganizationsList();
        }
    }, [organizationsList]);

    const getOrganizationsList = () => {
        axiosInstance.get('organizations-list').then((response) => {
            if (response.status == 200) {
                if (response.data.data != null && response.data.data != undefined) {
                    let organizationsData = response.data.data;
                    setOrganizationsList(organizationsData);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    // get all necessary data for basic information column
    const getSchoolsByCommunityId = (id) => {
        setcommunityId(id);
        getAbsenceCodeByCommunityId(id);
        getUsersList(id);
        getGroupsList(id);
        const url = isCoordinator ? 'schools?iCommunityId=' : 'schools-list?iCommunityId=';
        axiosInstance.get(url + id).then((response) => {
            if (response.status == 200) {
                if (response.data.data != null && response.data.data != undefined) {
                    let userData = response.data.data;
                    setSchools(userData);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const getAbsenceCodeByCommunityId = (id) => {
        axiosInstance.get("absence-codes-list?iCommunityId=" + id).then((response) => {
            if (response.status == 200) {
                if (response.data.data != null && response.data.data != undefined) {
                    let userData = response.data.data;
                    userData.forEach(element => {
                        element.codeName = element.vCode + ' - ' + element.vCodeValue;
                    });
                    setcodeListData(userData);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const getAbsentsList = (schoolId) => {
        axiosInstance.get('absents-list?iSchoolId=' + schoolId + '&iCommunityId=' + communityId).then((response) => {
            if (response.status == 200) {
                if (response.data.data != null && response.data.data != undefined) {
                    let userData = response.data.data;
                    userData.forEach(element => {
                        element.name = TextLimit.AfwezigeLimitOptionOne(element.vFirstName, element.vMiddleName, element.vLastName);
                    });
                    setAbsentsData(userData);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const getGroupsList = (id) => {
        axiosInstance.get("groups-list?iCommunityId=" + id).then((response) => {
            if (response.status == 200) {
                if (response.data.data != null && response.data.data != undefined) {
                    let userData = response.data.data;
                    setgroupsData(userData);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const getUsersList = (id) => {
        axiosInstance.get('organization-users-list?iCommunityId=' + id).then((response) => {
            if (response.status == 200) {
                if (response.data.data != null && typeof response.data.data != 'undefined') {
                    let userData = response.data.data;
                    let updatedUserData = [];

                    if (userData && userData.length !== 0) {
                        userData.forEach(element => {
                            const fullName = TextLimit.AfwezigeLimitOptionOne(element.vFirstName, element.vMiddleName ?? '' , element.vLastName).trim()

                            if (element.vEmail != "") {
                                updatedUserData.push({
                                    iAdminId: element.iAdminId + '_Zakelijk',
                                    name: fullName + ' - Zakelijk',
                                    email: element.vEmail
                                })
                            }

                            if (element.vBusinessEmail != "") {
                                updatedUserData.push({
                                    iAdminId: element.iAdminId + '_Privè',
                                    name: fullName + ' - Privè',
                                    email: element.vBusinessEmail
                                })
                            }
                        });
                        setUsersData(updatedUserData);
                    } else {
                        console.log("User data is empty or not set");
                    }
                }
            }
        }).catch((error) => console.error("catch error:", error));
    }


    // submit forms function
    const onSubmit = (data) => {
        setDisabledButton(true);
        // remove notes fields if they are empty
        const submitData = Object.fromEntries(
            Object.entries(data).filter(item => {
                if (item[0] !== "substitutionNote" && item[0] !== "schedulerNote") {
                    return true
                } else if (item[1].length) {
                    return true
                }
            })
        );
        const isCoordinatorIndienenPath =  coordinatorPath.verzoeken.includes(location.pathname)

        axiosInstance.post('requisition', submitData)
            .then((response) => {
                if (response.data.status === "success") {
                    isCoordinator && isCoordinatorIndienenPath ? navigate("/verzoeken/overzicht") : navigate("/verzoeken");
                    setDisabledButton(false);
                }
            }).catch((err) => console.error(err));
        setFormDataState(initialFormValues);
    }

    return (
        <Fragment>
            <div className="app-main__inner">

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(data) => onSubmit(data)}
                    >
                    {(formikProps) => (
                        <Form>
                            <MultiStep
                                formikProps={formikProps}
                                codeListData={codeListData}
                                nextDisabled={isDisabled}
                                setDisabled={setIsDisabled}
                                disabledButton={disabledButton}
                                handleSubmit={formikProps.handleSubmit}
                                showNavigation={true}
                                leaveLink={link}
                                steps={steps(
                                    formikProps,
                                    formDataState,
                                    setFormDataState,
                                    isDisabled,
                                    setIsDisabled,
                                    organizationsList,
                                    codeListData,
                                    schools,
                                    absentsData,
                                    groupsData,
                                    getSchoolsByCommunityId,
                                    getAbsentsList,
                                    usersData,
                                    link,
                                    setLink
                                )} />
                        </Form>
                    )}
                </Formik>

            </div>
            <AppFooter />
        </Fragment>
    )
};

export default IndienenSubstitution;

import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap'
import DatePicker from "react-datepicker";
import { ReactComponent as LockIcon } from "../../../../../assets/utils/images/svgs/Locked.svg";
import { ReactComponent as UnLockIcon } from "../../../../../assets/utils/images/svgs/unlock.svg";
import moment from "moment";

const Arrangement = ({ data, changeHandler, errorField }) => {
    const defaultValues = {
        iKetenregeling: data?.iKetenregeling,
        d36mnd: data?.d36mnd && data?.d36mnd !== "Invalid Da" ? moment(data?.d36mnd, 'DD-MM-YYYY').toDate() : null,
        dblokkeren: data?.dblokkeren && data?.dblokkeren !== "Invalid Da" ? moment(data?.dblokkeren, 'DD-MM-YYYY').toDate() : null,
        dblokkerenend: data?.dblokkerenend && data?.dblokkerenend !== "Invalid Da" ? moment(data?.dblokkerenend, 'DD-MM-YYYY').toDate() : null,
        dKetenregeling: data?.dKetenregeling && data?.dKetenregeling !== "Invalid Da" ? moment(data?.dKetenregeling, 'DD-MM-YYYY').toDate() : null,
    };
    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Ketenregeling</h5>
                <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                >
                    {({ values, setFieldValue, handleChange }) => (
                        <Form>
                            <FormGroup className="form-element">
                                <Label for="iKetenregeling">Stand ketenregeling</Label>
                                <div className="position-relative w-100">
                                    {data?.iKetenRegeling_is_locked ? 
                                    <LockIcon className="lock-icon" onClick={()=>{changeHandler('iKetenregeling', values.iKetenregeling, false)}}/> : <UnLockIcon className="lock-icon" onClick={()=>{ changeHandler('iKetenregeling', values.iKetenregeling, true)}}/> }
                                    <Input
                                        name="iKetenregeling"
                                        type="text"
                                        value={values.iKetenregeling}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changeHandler('iKetenregeling', e.target.value);
                                        }}
                                        disabled
                                        className="disabled"
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'dKetenregeling' ? 'error' : ''}`}>
                                <Label for="dKetenregeling">Begindatum ketenregeling</Label>
                                <div className="position-relative w-100">
                                <DatePicker
                                    selected={values.dKetenregeling}
                                    onChange={(date) => {
                                        const formattedDate = moment(date).format('DD-MM-YYYY');
                                        setFieldValue("dKetenregeling", date);
                                        changeHandler('dKetenregeling', formattedDate);
                                    }}
                                    placeholderText={'dd-mm-jjjj'}
                                    locale="nl"
                                    dateFormat="dd-MM-yyyy"
                                    className="form-control"
                                />
                                </div>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'd36mnd' ? 'error' : ''}`}>
                                <Label for="d36mnd">36 Maanden datum</Label>
                                <div className="position-relative w-100">
                                {data?.d36mnd_is_locked ? <LockIcon className="lock-icon" onClick={()=>{changeHandler('d36mnd', values.d36mnd, false)}}/> : <UnLockIcon className="lock-icon" onClick={()=>{changeHandler('d36mnd', values.d36mnd, true)}}/> }
                                    <DatePicker
                                        selected={values.d36mnd}
                                        onChange={(date) => {
                                            setFieldValue("d36mnd", date);
                                            changeHandler('d36mnd', moment(date).format('YYYY-MM-DD'));
                                        }}
                                        placeholderText={'dd-mm-jjjj'}
                                        locale="nl"
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control"
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'dblokkeren' ? 'error' : ''}`}>
                                <Label for="dblokkeren">Blokkeerdatum begin</Label>
                                <div className="position-relative w-100">
                                    <DatePicker
                                        selected={values.dblokkeren}
                                        onChange={(date) => {
                                            const formattedDate = moment(date).format('DD-MM-YYYY');
                                            setFieldValue("dblokkeren", date);
                                            changeHandler('dblokkeren', formattedDate);
                                        }}
                                        placeholderText={'dd-mm-jjjj'}
                                        locale="nl"
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control"
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'dblokkerenend' ? 'error' : ''}`}>
                                <Label for="dblokkerenend">Blokkeerdatum eind</Label>
                                <div className="position-relative w-100">
                                    <DatePicker
                                    selected={values.dblokkerenend}
                                    onChange={(date) => {
                                        const formattedDate = moment(date).format('DD-MM-YYYY');
                                        setFieldValue("dblokkerenend", date);
                                        changeHandler('dblokkerenend', formattedDate);
                                    }}
                                    placeholderText={'dd-mm-jjjj'}
                                    locale="nl"
                                    dateFormat="dd-MM-yyyy"
                                    className="form-control"
                                />
                                </div>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )
}

export default Arrangement
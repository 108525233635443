import ReactTableGeneric from 'Components/ReactTableGeneric'
import React from 'react';
import { Card, CardBody } from 'reactstrap'

const MonthlyOverview = ({data}) => {

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Maand',
                accessorKey: 'monthName',
            },
            {
                header: () => 'Dagen',
                accessorKey: 'year'
            },
            {
                header: () => 'Uren cumulatief',
                accessorKey: 'cumulatief'
            },
            {
                header: () => 'Uren totaal',
                accessorKey: 'totaal_uren'
            }
        ],
        [data]
    );

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Maandoverzicht</h5>
                <ReactTableGeneric
                    data={data || []}
                    columns={columns}
                    tableClassName="mb-0 table table-borderless table-sm table-hover"
                    filters={{ search: false, pagination: false, sorting: false }}
                />
            </CardBody>
        </Card>
    )
}

export default MonthlyOverview
import Tippy from "@tippyjs/react";
import { Table } from "reactstrap";
import { parse } from "../utils";
import UserInfoPopup from "./UserInfoPopup";

const WeekReportInfo = ({data}) => {
    // data.vAbsentName - is a string with 3 values
    // perse() splits the string and return object with correct key and values
    let extraData = parse("&nbsp;&nbsp;&nbsp;", data.vAbsentName, ["vAbsentName", "vSalarisId", "dBirthdate"]);

    // put extracted data into general data object
    // replace() returns only numeric value in case if original one is "(SalarisID: 16161)"
    data = {...data, ...{...extraData, vSalarisId: extraData["vSalarisId"]?.replace(/[^0-9]/g, '')}}

    const onHover = (data) => (<Tippy content={data} placement="bottom"><span> {data}</span></Tippy>)

    let columns = [
        {
            title: "Geboortedatum",
            value: data?.dBirthdate && data?.dBirthdate.length !== 0 ? data.dBirthdate : "-"
        },
        {
            title: "Salaris Id",
            value: data?.vSalarisId && data?.vSalarisId.length !== 0 ? data.vSalarisId : "-"
        },
        {
            title: "WTF",
            value: data?.vWtf && data?.vWtf.length !== 0 ? data.vWtf : "-"
        }
    ];

    const showPopup = columns.filter(item => item.value !== "-").length; // show popup if at least one value is not empty

    return (
        <div className="week-info">
            <h6 className="mb-2 text-blue">Verzoek ID  {onHover(data.iSubstitutionId)}</h6>
            <Table size="sm" borderless className="mb-0 border-btm-none">
                <tbody>
                    <tr>
                        <th className="wl">Code</th>
                        <th>Afwezige</th>
                        <th>Verlof- / verzuimmelding</th>
                    </tr>
                    <tr>

                        <td className="overflow-ellipsis wl">
                            {onHover(data.code)}
                        </td>

                        <td className="wl">
                            {showPopup
                                ? <UserInfoPopup name={data.vAbsentName} columns={columns}/>
                                : data.vAbsentName
                            }
                        </td>

                        <td className="wl">
                            {onHover(data.iPlanOption)}
                        </td>
                    </tr>
                    <tr>
                        <th className="wl">Memo t.b.v. weekstaat</th>
                        <th>1e / laatste dag</th>
                        <th>Kostenplaats / project</th>
                    </tr>
                    <tr>
                        <td className="overflow-ellipsis wl">
                            {onHover(data.vMemoWeekstaat)}
                        </td>


                        <td className="wl">
                            {onHover(data.first_day + ' / ' + data.last_day)}
                        </td>

                        <td className="wl">
                            {onHover(data.vKostenplaats)}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
};

export default WeekReportInfo;

import { useEffect, useState } from 'react';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap'
import { ReactComponent as AddIcon } from "../../../../../assets/utils/images/svgs/addBlue.svg"
import { ReactComponent as RemoveIcon } from "../../../../../assets/utils/images/svgs/remove-blue.svg";
import RolesIcon from './RolesIcon';
import { userRoles } from '../utils';
import { toast } from 'react-toastify';
import Loader from 'react-loaders';
import GebruikerAlgemeenService from 'Api/Gebruikers/GebruikersView/Algemeen';
import GebruikerViewService from 'Api/Gebruikers/GebruikersView';

const Role = ({ currentUser }) => {
    const [rolesData, setRolesData] = useState([]);
    const [communityModal, setCommunityModal] = useState(false);
    const [removeCommunityModal, setRemoveCommunityModal] = useState(false);
    const [communityList, setCommunityList] = useState([]);
    const [currentCommunity, setCurrentCommunity] = useState();
    const [loader, setLoader] = useState(true);
    
    useEffect(() => {
        getUserRolesData();
    }, []);

    const getUserRolesData = async () => {
        if (!currentUser.data.clooserId) return;
        try {
            const response = await GebruikerAlgemeenService.getGebruikerRoleList(currentUser.data.clooserId);
            if (response.data.status) {
                setRolesData(response.data.data)
                setLoader(false)
            }
        } catch (error) {
            console.error(`API error:`, error);
            setLoader(false)
        }
    }

    const getCommunityList = async () => {
        try {
            const response = await GebruikerViewService.getCommunityList(currentUser.data.clooserId);
            if (response.data.status && response.data.data.length > 0) {
                setCommunityList(response.data.data);
            }
        } catch (error) {
            console.error(`API error:`, error);
        }
    };

    const updateUserRoleHandler = async (userType = '', commId = '', value = '', contractId = '', schoolId = '', iAdmschoolId = '') => {
        const data = {
            iAdminId: currentUser.data.clooserId,
            role: userType,
            iCommunityId: commId,
            value,
            iContractId: contractId,
            schoolId,
            iAdmschoolId
        }
        try {
            const response = await GebruikerAlgemeenService.gebruikerUpdateRoles(data);
            if (response.data.status) {
                getUserRolesData();
            } else {
                toast.error("Rol niet bijgewerkt! Er is iets misgegaan.")
            }
        } catch (error) {
            console.error(`API error:`, error);
        }
    }

    const addRemoveCommunityHandler = async (actionType) => {
        if (!currentCommunity) return;
        const data = {
            iAdminId: currentUser.data.clooserId,
            iCommunityId: Number(currentCommunity),
            type: actionType
        }
        try {
            const response = await GebruikerAlgemeenService.removeAddCommunity(data);
            if (response.data.status) {
                getUserRolesData();
                setRemoveCommunityModal(false);
                setCommunityModal(false);
                toast.success("Gebruiker heeft de verbinding met de rollen succesvol verbroken.")
            } else {
                toast.error("Kan de gebruiker niet loskoppelen van de rollen.")
            }
        } catch (error) {
            console.error(`API error:`, error);
        }
    }

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column table-responsive">
                <h5 className="mb-4">Rollen</h5>
                {loader ?
                    <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> :
                    <>
                        <Table className='role-tb'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Vervanger</th>
                                    <th>Melder</th>
                                    <th>Coordinator</th>
                                    <th>Ontkoppelen</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rolesData.map(item => {
                                    return <tr key={item.iCommunityId}>
                                        <td>{item.vCommunityName}</td>
                                        <td><RolesIcon iAdminId={currentUser.data.clooserId} contractId={item.contractId} selectedSchoolId={item.schoolList.map(item => item.id)} value={item.isSubstitutor} id={item.iCommunityId} type={userRoles.Vervanger} updateHandler={updateUserRoleHandler} /></td>
                                        <td><RolesIcon iAdminId={currentUser.data.clooserId} selectedSchoolId={item.schoolList.map(item => item.id)} value={item.isRequestor} id={item.iCommunityId} type={userRoles.Melder} updateHandler={updateUserRoleHandler} /></td>
                                        <td><RolesIcon iAdminId={currentUser.data.clooserId} selectedSchoolId={item.schoolList.map(item => item.id)} value={item.isCoordinator} id={item.iCommunityId} type={userRoles.Coordinator} updateHandler={updateUserRoleHandler} /></td>
                                        <td className='text-center'><RemoveIcon onClick={() => { setRemoveCommunityModal(true); setCurrentCommunity(item.iCommunityId) }} fontSize={"18px"} className='cursor-pointer' /></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        <div className="mx-auto mt-4">
                            <Button
                                outline
                                className="btn-outline-primary-blue btn-color-style d-flex justify-content-center align-items-center gap-2"
                                onClick={() => { setCommunityModal(true); getCommunityList() }}
                                type="button"
                                color="primary"
                            >
                                Bestuur <AddIcon />
                            </Button>
                        </div>
                    </>}
                <Modal isOpen={communityModal} toggle={() => { setCommunityModal(prev => !prev) }} className="feedback-modal-style link-form modal-centered">
                    <ModalHeader toggle={() => { setCommunityModal(prev => !prev) }}>
                        Niet gekoppelde besturen
                    </ModalHeader>
                    <ModalBody className='py-5'>
                        {communityList.length === 0 && 'Er zijn geen gemeenschapsgegevens beschikbaar.'}
                        {communityList.length > 0 && <select className="form-select" onChange={(e) => { setCurrentCommunity(e.target.value) }}>
                            <option value=''>Alle besturen</option>
                            {communityList.map(item => {
                                return <option key={item.iCommunityId} value={item.iCommunityId}>{item.vCommunityName}</option>
                            })}
                        </select>}
                    </ModalBody>
                    <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4">
                        <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="button"
                            onClick={() => { addRemoveCommunityHandler("add") }}
                        >
                            Toevoegen
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={removeCommunityModal} toggle={() => { setRemoveCommunityModal(prev => !prev) }} className="feedback-modal-style link-form modal-centered">
                    <ModalHeader toggle={() => { setRemoveCommunityModal(prev => !prev) }}>
                    </ModalHeader>
                    <ModalBody className='py-4'>
                        <h5 style={{ color: '#ff8b02' }} className='mb-3'>Hiermee ontkoppelen we deze gebruiker van de onderstaande rollen, weet u dit zeker?</h5>
                        <p>Hiermee worden de bestuursgegevens van de vervanger verwijderd en de vervanger ontkoppeld van het bestuur. De dagdeel gegevens worden niet verwijderd. Dit kan niet ongedaan gemaakt worden.</p>

                    </ModalBody>
                    <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4">
                        <Button className="btn-color-style opslaan-btn" size="lg" outline color="primary" type="button" onClick={() => addRemoveCommunityHandler("remove")}>
                            Toepassen
                        </Button>
                        <Button className="btn-color-style opslaan-btn" size="lg" outline color="primary" type="button" onClick={() => setRemoveCommunityModal(false)}>
                            Annuleren
                        </Button>
                    </ModalFooter>
                </Modal>
            </CardBody>
        </Card>
    )
}

export default Role
import { Button, Card, CardBody, CardFooter, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap'
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import CommunityTable from './CommunityTable';
import { userTabs } from '../utils';
import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import GebruikerMatchcodesService from 'Api/Gebruikers/GebruikersView/Matchcodes';
import { toast } from 'react-toastify';

const Matchcodes = ({ currentUser, setActiveTab, adminCommunityList, setFilterCommunity, filterCommunity }) => {
    const [loading, setIsLoading] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [matchcodeData, setMatchcodeData] = useState([]);

    const handleCheckboxChange = (event) => {
        const communityId = Number(event.target.value);
        const communityName = event.target.name;
        if (filterCommunity?.length === 1 && filterCommunity.some(item => item.iCommunityId === communityId)) {
            toast.info("Je moet minimaal 1 bord selecteren.")
            return;
        }
        setFilterCommunity(prevOptions => {
            const exists = prevOptions.some(item => item.iCommunityId === communityId);
            if (exists) {
                return prevOptions.filter(item => item.iCommunityId !== communityId);
            } else {
                return [...prevOptions, { iCommunityId: communityId, vCommunityName: communityName }];
            }
        });
    };

    useEffect(() => {
        fetchUrenoverzichtData();
    }, [filterCommunity]);

    const fetchUrenoverzichtData = async () => {
        setIsLoading(true);
        const data = {
            iCommunityId: filterCommunity.map(item => item.iCommunityId).join(","),
            iAdminId: currentUser.data.clooserId,
        };
        try {
            const response = await GebruikerMatchcodesService.getMatchcodesData(data);
            if (response.data.status) {
                setMatchcodeData(response.data.data)
                setIsLoading(false);
            }
        } catch (error) {
            console.error(`API error:`, error);
        }
    };

    return (
        <div className='matchcodes'>
            {loading ?
                <Card className="main-card">
                    <CardBody className="d-flex flex-column">
                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                    </CardBody>
                </Card> : <div className='row'>
                    {matchcodeData?.map(item => {
                        return <div className='col-xl-3 col-md-6 col-12' key={item.iCommunityId}>
                            <CommunityTable data={item} currentUser={currentUser} getDataHandler={fetchUrenoverzichtData}/>
                        </div>
                    })}
                </div>
            }
            <div className="chat-layout-footer">
                <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                    <div className="d-flex gap-3">
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle className="form-select">
                                Alle besturen
                            </DropdownToggle>
                            <DropdownMenu>
                                {adminCommunityList.map(item => {
                                    return <DropdownItem key={item.iCommunityId} toggle={false} className='d-flex justify-content-between'>
                                        {item.vCommunityName}
                                        <Input
                                            type="checkbox"
                                            name={item.vCommunityName}
                                            value={item.iCommunityId}
                                            checked={filterCommunity.some(community => community.iCommunityId === item.iCommunityId)}
                                            onChange={handleCheckboxChange}
                                        />
                                    </DropdownItem>
                                })}
                            </DropdownMenu>
                        </Dropdown>
                        <Button
                            outline
                            className="btn-outline-primary-blue ms-auto"
                            onClick={() => {
                                setActiveTab(userTabs.Beschikbaarheid)
                            }}
                            type="button"
                            color="primary"
                        >
                            <BackIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </div>
    )
}

export default Matchcodes
import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardBody, Col, FormGroup, Input, CardFooter, Label, Row, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ReactTableGeneric from "Components/ReactTableGeneric";
import { BsCheckCircle } from "react-icons/bs";
import { ReactComponent as PenIcon } from "../../../assets/utils/images/svgs/editPen.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/utils/images/svgs/Delete.svg";
import { ReactComponent as AddIcon } from "../../../assets/utils/images/svgs/addBlue.svg"
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import AdminService from "Api/Admin";
import { toast, ToastContainer } from "react-toastify";
import Loader from "react-loaders";
import TitleModal from "Components/Modal/InfoModal/TitleModal";
import SweetAlert from "react-bootstrap-sweetalert";
import { Typeahead } from "react-bootstrap-typeahead";

const ManageLinks = () => {
    const [orgLinkList, setOrgLinkList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [communityIdList, setCommunityIdList] = useState([]);
    const [activeRowIndex, setActiveRowIndex] = useState(undefined);
    const [showAlert, setShowAlert] = useState(false);
    const [modal, setModal] = useState({
        show: false,
        formType: "new"
    });
    const [isDescriptionDisabled, setDescriptionDisabled] = useState(true);

    const toggle = (type) => {
        setModal((prev) => ({
            show: !prev.show,
            formType: type
        }));
        setDescriptionDisabled(true)
    }

    useEffect(() => {
        organisationList()
        communityList()
    }, []);

    const organisationList = () => {
        setIsLoading(true);
        AdminService.organisationList().then((response) => {
            if (response.status == 200) {
                    setOrgLinkList(response.data.data)
            }
            setIsLoading(false);
        }).catch(() => console.error);
    }

    const communityList = () => {
        AdminService.communityIdList().then((response) => {
            if (response.status == 200) {
                if (response.data.data.length > 0) {
                    setCommunityIdList(response.data.data)
                }
            }
        }).catch(() => console.error);
    }

    const deleteOrgLinkHandler = (index) => {
        AdminService.deleteOrganisationLink(orgLinkList[index].orgLinkId).then((response) => {
            if (response.data.status) {
                toast.success("Organisatie koppeling succesvol verwijderd.");
                organisationList();
            }else {
                toast.error("Het schrappen van de organisatiekoppeling is niet gelukt.")
            }
        }).catch(() => console.error);
    }

    const checkCircle = (value) => {
        switch (value) {
            case true:
                return <div className="text-center"><BsCheckCircle color='#39A825' fontSize={"20px"} /></div>;
            default:
                return '-'
        }
    }

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Titel',
                accessorKey: 'title',
            },
            {
                header: () => 'Bestuur',
                accessorKey: 'iCommunityIds',
                cell: value=> <div>{value.row.original.iCommunityIds.map(item=>item.vCommunityName).join(', ')}</div>
            },
            {
                header: () => 'Vervanger',
                accessorKey: 'is_vervanger',
                cell: value => (checkCircle(orgLinkList[value.row.index].is_vervanger))
            },
            {
                header: () => 'Melder',
                accessorKey: 'is_melder',
                cell: value => (checkCircle(orgLinkList[value.row.index].is_melder))
            },
            {
                header: () => 'Coordinator',
                accessorKey: 'is_coordinator',
                cell: value => (checkCircle(orgLinkList[value.row.index].is_coordinator))
            },
            {
                header: () => '',
                accessorKey: 'action',
                cell: (cell) => (
                    <div className="d-flex gap-3 justify-content-center">
                        <TitleModal data={ orgLinkList[cell.row.index]?.description || ''} />
                        <div className="cursor-pointer" onClick={() => { toggle("update"); setActiveRowIndex(cell.row.index) }} ><PenIcon /></div>
                        <div className="cursor-pointer" onClick={() => {setActiveRowIndex(cell.row.index); setShowAlert(true)}}><DeleteIcon /></div>
                    </div>

                )
            }
        ],
        [orgLinkList]
    );

    const validationSchema = Yup.object().shape({
        iCommunityId: Yup.array().min(1, 'Selecteer minimaal 1 bestuur').required('veld is verplicht'),
        link: Yup.string().required("veld is verplicht"),
        title: Yup.string().required("veld is verplicht"),
        description: Yup.string().required("veld is verplicht"),
        role: Yup.array().min(1, 'Er moet minimaal één selectievakje zijn aangevinkt'),
    });

    const handleOrgSubmit = (values) => {
        const isMelder = values.role.includes("melder") ? true : false;
        const isCoordinator = values.role.includes("coordinator") ? true : false;
        const isVervanger = values.role.includes("vervanger") ? true : false;

        const formData = new FormData();
        values.iCommunityId.forEach((item, index) => {
            formData.append(`iCommunityId[${index}]`, item);
        });
  
        formData.append('link',values.link);
        formData.append('title',values.title);
        formData.append('description',values.description);
        formData.append('is_coordinator',isCoordinator);
        formData.append('is_melder',isMelder);
        formData.append('is_vervanger',isVervanger);
        formData.append('orgLinkId',modal.formType === "new" ? "" : orgLinkList[activeRowIndex].orgLinkId);

        const serviceFunction = modal.formType === "new" ? AdminService.saveOrganisationLink : AdminService.updateOrganisationLink;
        const successMsg = modal.formType === "new" ? "Gegevens succesvol ingevoegd." : "Gegevens zijn succesvol bijgewerkt."
        serviceFunction(formData)
            .then((response) => {
                if (response.data.status) {
                    toast.success(successMsg);
                    setModal(prev => ({ ...prev, show: false }));
                    organisationList();
                } else {
                    toast.error("Er is een probleem opgetreden bij het opslaan van gegevens.");
                }
            })
            .catch((error) => {
                console.error("API call error:", error);
                toast.error("Er is een probleem opgetreden bij het opslaan van gegevens.");
            });
    };

    const formDataState = React.useMemo(() => {
        let apiData = {
            iCommunityId: [],
            link: '',
            title: '',
            description: '',
            role: []
        };
        if (modal.formType === "new") {
            return apiData;
        } else if (modal.formType === "update" && activeRowIndex !== null) {
            const formData = orgLinkList[activeRowIndex];
            if (formData) {
                let roles = [];
                if (formData.is_coordinator) roles.push("coordinator");
                if (formData.is_melder) roles.push("melder");
                if (formData.is_vervanger) roles.push("vervanger");

                return {
                    iCommunityId: formData.iCommunityIds?.map(item=>item.iCommunityId),
                    link: formData.link,
                    title: formData.title,
                    description: formData.description,
                    role: roles
                };
            }
        }
        return apiData;
    }, [activeRowIndex, modal.formType, orgLinkList]);

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <Row>
                            <Col md="12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-3">
                                        <Modal isOpen={modal.show} toggle={toggle} className="feedback-modal-style link-form">
                                            <ModalHeader toggle={toggle}>
                                                Link toevoegen
                                            </ModalHeader>
                                            <ModalBody>
                                                <Formik
                                                    enableReinitialize
                                                    initialValues={formDataState}
                                                    validationSchema={validationSchema}
                                                    onSubmit={handleOrgSubmit}
                                                >
                                                    {({ values, handleChange, handleBlur, setFieldValue }) => (
                                                        <Form>
                                                            <FormGroup>
                                                                <Typeahead
                                                                    id="iCommunityId"
                                                                    labelKey="vCommunityName"
                                                                    multiple
                                                                    onChange={(selected) => {
                                                                        setFieldValue('iCommunityId', selected?.map(option => option.iCommunityId));
                                                                    }}
                                                                    onBlur={() => {
                                                                        handleBlur({ target: { name: 'iCommunityId' } });
                                                                    }}
                                                                    options={communityIdList}
                                                                    placeholder="Bestuur"
                                                                    selected={values?.iCommunityId?.map(id => communityIdList.find(option => option.iCommunityId === id)) || []}
                                                                />
                                                            </FormGroup>
                                                            <ErrorMessage name="iCommunityId" component="small"
                                                                className="block mt-2 invalid-feedback" />
                                                            <FormGroup className="mt-3">
                                                                <Label for="link">
                                                                    Link
                                                                </Label>
                                                                <Input name="link"
                                                                    type="text" onChange={handleChange} onBlur={handleBlur}
                                                                    value={values.link} />
                                                                <ErrorMessage name="link" component="small"
                                                                    className="block mt-2 invalid-feedback" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="title">
                                                                    Titel
                                                                </Label>
                                                                <Input name="title"
                                                                    type="text" onChange={handleChange} onBlur={handleBlur}
                                                                    value={values.title} />
                                                                <ErrorMessage name="title" component="small"
                                                                    className="block mt-2 invalid-feedback" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="description" className="d-flex justify-content-between">Omschrijving<span className="cursor-pointer" onClick={()=>setDescriptionDisabled((prev)=>!prev)}><PenIcon /></span></Label>
                                                                <Input type="textarea" name="description" id="description" onChange={handleChange} onBlur={handleBlur}
                                                                    value={values.description} rows={6} disabled={isDescriptionDisabled}/>
                                                                <ErrorMessage name="description" component="small" className="block mt-2 invalid-feedback" />
                                                            </FormGroup>
                                                            <Label>Rollen</Label>
                                                            <div className="d-flex justify-content-between">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        Vervanger
                                                                        <Input
                                                                            type="checkbox"
                                                                            name="role"
                                                                            value="vervanger"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            checked={values.role.includes('vervanger')}
                                                                        />
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        Melder
                                                                        <Input
                                                                            type="checkbox"
                                                                            name="role"
                                                                            value="melder"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            checked={values.role.includes('melder')}
                                                                        />
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        Coordinator
                                                                        <Input
                                                                            type="checkbox"
                                                                            name="role"
                                                                            value="coordinator"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            checked={values.role.includes('coordinator')}
                                                                        />
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                            <ErrorMessage name="role" component="small" className="block mt-2 invalid-feedback" />
                                                            <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                                                <Button className="btn-color-style  opslaan-btn" size="sm" outline color="primary" type="submit">
                                                                    Toepassen
                                                                </Button>
                                                            </ModalFooter>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </ModalBody>
                                        </Modal>
                                        {isLoading ?
                                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> :
                                            <ReactTableGeneric
                                                data={orgLinkList}
                                                columns={columns}
                                                tableClassName="mb-0 table table-borderless table-sm table-hover tb-links"
                                                filters={{ search: false, pagination: false, sorting: false }}
                                            />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <Button
                        outline
                        className="btn-outline-primary-blue btn-color-style d-flex justify-content-center align-items-center gap-2"
                        onClick={() => toggle("new")}
                        type="button"
                        color="primary"
                    >
                        Link <AddIcon />
                    </Button>
                </CardFooter>
            </div>
            <ToastContainer style={{ top: "70px" }} />
            <SweetAlert title="Weet je zeker dat je wilt verwijderen?" show={showAlert}
                type="warning"
                onConfirm={() => {setShowAlert(false); deleteOrgLinkHandler(activeRowIndex)}}
                onCancel={() => setShowAlert(false)}
                cancelBtnText="Nee"
                confirmBtnText="Ja"
                showCancel={true}
            />
        </Fragment>
    )
}

export default ManageLinks

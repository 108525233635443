import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useLocation,useMatch } from "react-router-dom";

function insertComma(name) {
    const words = name.trim().split(' ');
    if (words.length > 2) {
        return `${words[0]} ${words[1]}, ${words.slice(2).join(' ')}`;
    }
    return name;
}

const AppBreadcrumb = ({ additionalBreadcrumbs, useDefaultPath = true, data }) => {
    const route = useLocation();
    const breadcrumbs = useBreadcrumbs();
    let match = useMatch("/searchsubrequests/:substitute");

    return (
        <div>
            <div className="breadcrumb-main">
                <Breadcrumb color="light">
                    <BreadcrumbItem color="light">
                        <a href="#/verzoeken" >
                            <FontAwesomeIcon icon={faHome} />
                        </a>
                    </BreadcrumbItem>
                    {useDefaultPath
                      ? breadcrumbs.map((breadcrumb, index) => {
                        if (breadcrumb.breadcrumb.props.children == "Searchsubrequests" ) {
                          
                            return (
                                <BreadcrumbItem key={index} color="light">
                                    <a href={"#" + breadcrumb.key} >
                                        <span>Zoek resulaten</span>
                                    </a>
                                </BreadcrumbItem>

                            )

                        }else if (breadcrumb.breadcrumb.props.children != "Home" && breadcrumb.key !== "/links/coordinator" && breadcrumb.key !== "/links/substitutor"  && breadcrumb.key !== "/links/requestor") {
                            return (
                                <BreadcrumbItem key={index} color="light">
                                    <a href={"#" + breadcrumb.key} >
                                        { match !== null ? (
                                            <span>{route.state.name}</span>
                                        ) : (
                                            <span>
                                                {
                                                    /^\/gebruikers\/\d+$/.test(breadcrumb.key)
                                                    ? `${insertComma(data?.data?.name)} (${breadcrumb.breadcrumb.props.children})`
                                                    : breadcrumb.breadcrumb.props.children
                                                }
                                            </span>
                                        )
                                        }
                                    </a>
                                </BreadcrumbItem>
                            )

                        }else  if (breadcrumb.breadcrumb.props.children == "Home" && index == 1 ) {
                            return (
                                <BreadcrumbItem key={index} color="light">
                                    <a href={"#" + breadcrumb.key} >
                                        { match !== null ? (
                                            <span>{route.state.name}</span>
                                        ) : (
                                            <span>{breadcrumb.breadcrumb.props.children} </span>
                                        )
                                        }
                                    </a>
                                </BreadcrumbItem>

                            )
                        }
                    })
                    : additionalBreadcrumbs &&
                      additionalBreadcrumbs.map((breadcrumb) => (
                        <BreadcrumbItem key={breadcrumb.href} color="light">
                          <a href={breadcrumb.href}>{breadcrumb.label}</a>
                        </BreadcrumbItem>
                      ))}
                </Breadcrumb>
            </div>
        </div>
    );

}

export default AppBreadcrumb;

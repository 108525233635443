import React, {useState,useRef,useEffect} from 'react'
import { UncontrolledButtonDropdown, DropdownToggle, Nav, DropdownMenu } from "reactstrap";
import { ReactComponent as InfoIcon } from "../../../assets/utils/images/svgs/InformationBlue.svg";

const TitleModal = ({data, title}) => {
    const [infoMenu, setInfoMenu] = useState(false);
    const modalRef = useRef(null);
    const btnRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (btnRef.current && btnRef.current.contains(event.target)) {
                setInfoMenu(prev => !prev);
            }else if(event.target.contains(modalRef.current)){
                setInfoMenu(false)
            }else if(!modalRef.current.contains(event.target)){
                setInfoMenu(false)  
            }
        };

        document.addEventListener('click', handleClickOutside);
      
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return <>
        <UncontrolledButtonDropdown isOpen={infoMenu}>
            <DropdownToggle color="link" className="p-0">
                <div className="cursor-pointer"><InfoIcon ref={btnRef} /></div>
            </DropdownToggle>
            <DropdownMenu className="rm-pointers p-0 weekstaten-popup-border" >
                <div ref={modalRef}>
                    <Nav className="p-2 weekstaten-popup-width flex-column">
                        <div className="btn-color-style travelCost-blue-text mb-2 ps-2">
                            {title}
                        </div>
                        <div className="travelCost-light-text ps-2 text-break">{data}</div>
                    </Nav>
                </div>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    </>
}

export default TitleModal

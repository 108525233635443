import axiosInstance from "Api";

const getRequestList = (formData) => {
    return axiosInstance.post('/verzoeken/scherm', formData);
};

const getPriorityList = (formData) => {
    return axiosInstance.post('/priority-list', formData);
};

//Request List

const updateShiftType = (formData) => {
    return axiosInstance.post('/update/shift-day-type', formData);
};

const updateWtfValue = (formData) => {
    return axiosInstance.post('/verzoeken/scherm/updateWtfValue', formData);
};

const updateMemo = (formData) => {
    return axiosInstance.post('/verzoeken/scherm/updateMemo', formData);
};

//LongContractsRequest

const deleteLongContractById = (id) => {
    return axiosInstance.get('/long-contract/delete/' + id);
};

//Partials

const deleteDayPartById = (id) => {
    return axiosInstance.get('/verzoeken/scherm/' + id + '/dates');
};

const updateDayPartStatus = (data) => {
    return axiosInstance.post('/verzoeken/scherm/update', data);
};

const updateWtf = (data) => {
    return axiosInstance.post('/verzoeken/scherm/updateWtf', data);
};

const getRequestById = (substitutionId) => {
    return axiosInstance.get('verzoeken/scherm/' + substitutionId);
};

const saveMessage = (substitutionId,msg) => {
    return axiosInstance.post('substitutions/' + substitutionId + '/comments', msg);
};

const getArchivedMessageList = (substitutionId,type) => {
    return axiosInstance.get('verzoeken/scherm/comments/' + substitutionId + '/' + type);
};

const saveShiftData = (data) => {
    return axiosInstance.post('verzoeken/save-shift-data', data);
};

const getLongContractAdminList = (id) => {
    return axiosInstance.get('/long-contract-admin-list/' + id);
};

const updateLongContract = (data) => {
    return axiosInstance.post('/long-contract/update', data);
};

const saveLongContract = (data) => {
    return axiosInstance.post('/long-contract', data);
};

const getLongContractDetailsById = (id) => {
    return axiosInstance.get('/long-contract/'+ id);
};

const getBulkShiftDetailsById = (id) => {
    return axiosInstance.get('/verzoeken/bulk-shift/' + id);
};

const vervangerShiftList = (id,value) => {
    return axiosInstance.get('/verzoeken/scherm/shift/' + id + '/' + value);
};

const commentStatus = (data) => {
    return axiosInstance.post('/verzoeken/scherm/changeArchiveStatus', data);
};

const VerzoekenService = {
    getRequestList,
    getPriorityList,
    updateShiftType,
    updateWtfValue,
    updateMemo,
    deleteLongContractById,
    deleteDayPartById,
    updateDayPartStatus,
    updateWtf,
    getRequestById,
    saveMessage,
    getArchivedMessageList,
    saveShiftData,
    getLongContractAdminList,
    updateLongContract,
    saveLongContract,
    getLongContractDetailsById,
    getBulkShiftDetailsById,
    vervangerShiftList,
    commentStatus
};
export default VerzoekenService;
import axiosInstance from "Api";

const communityList = () => {
    return axiosInstance.get("/communities");
};

const absentCodeList = (id) => {
    return axiosInstance.get("absence-codes-list?iCommunityId=" + id);
};

const contractType = (id) => {
    return axiosInstance.get("contracttypes?iCommunityId=" + id);
};

const PlanboardService = {
    communityList,
    absentCodeList,
    contractType
};
export default PlanboardService;
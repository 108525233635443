import ReactTableGeneric from 'Components/ReactTableGeneric'
import { useMemo } from 'react';
import { Card, CardBody } from 'reactstrap'

const Log = ({data=[]}) => {
    const columns = useMemo(
        () => [
            {
                header: () => 'Naam coördinator',
                accessorKey: 'coorName',
                cell: (cell) => `${cell.row.original.cFirstName} ${cell.row.original.cLastName} ${cell.row.original.cMiddleName}`
             },
            {
                header: () => 'Datum / Tijd',
                accessorKey: 'idateTime',
            },
            {
                header: () => 'Type',
                accessorKey: 'type'
            },
            {
                header: () => 'Nieuw',
                accessorKey: 'newValue'
            },
            {
                header: () => 'Oud',
                accessorKey: 'oldValue'
            }
        ],
        [data]
    );

return (
    <Card className="main-card">
        <CardBody className="d-flex flex-column">
            <h5 className="mb-4">Logboek</h5>
            <ReactTableGeneric
                data={data || []}
                columns={columns}
                tableClassName="mb-0 table table-borderless table-sm table-hover"
                filters={{ search: false, pagination: false, sorting: false }}
            />
        </CardBody>
    </Card>
)
}

export default Log
import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { ReactComponent as DeleteIcon } from "../../../../../assets/utils/images/svgs/Delete.svg";
import { ReactComponent as UploadIcon } from "../../../../../assets/utils/images/svgs/Upload.svg";
import { toast } from 'react-toastify';
import GebruikerAlgemeenService from 'Api/Gebruikers/GebruikersView/Algemeen';

const Photo = ({ data, currentUser }) => {
  const existingProfilePic = data?.profileImage; 
  const [userImg, setUserImg] = useState('');

  useEffect(() => {
    if (existingProfilePic) {
      setUserImg(`${existingProfilePic}`);
    }
  }, [existingProfilePic]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file size (2MB)
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
      const validTypes = ['image/png', 'image/jpeg', 'image/bmp'];

      if (file.size > maxSizeInBytes) {
        toast.error('Bestandsgrootte mag niet groter zijn dan 2MB.');
        return;
      }

      if (!validTypes.includes(file.type)) {
        toast.error('Ongeldig bestandsformaat. Alleen PNG, JPG, en BMP zijn toegestaan.');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setUserImg(reader.result);
        updateUserProfile(file)
      };
      reader.readAsDataURL(file);
    }
  };

  const updateUserProfile = (value) => {
    const formData = new FormData();
    formData.append('iAdminId', currentUser.data.clooserId);
    formData.append('file', value);

    GebruikerAlgemeenService.updateGebruikerProfilePhoto(formData)
      .then(response => {
        if (response.data.status) return;
        if (!response.data.success) {
          const errors = response.data.data.file;
          if (Array.isArray(errors)) {
            toast.error(errors.join(','));
          } else {
            toast.error(errors);
          }
        }
      })
      .catch(error => {
        console.error('API error:', error);
      });
  }

  return (
    <Card className="main-card">
      <CardBody className="d-flex flex-column">
        <h5 className="mb-4">{userImg ? 'Foto' : 'Foto toevoegen'}</h5>
        <div className='user-img text-center mx-auto my-auto'>
          {userImg ? (
            <div className='img-box'>
              <img src={userImg} alt='img' />
              <button onClick={() => {
                setUserImg('');
              }}>
                <DeleteIcon />
              </button>
            </div>
          ) : (
            <div className='img-input'>
              <input type='file' accept="image/*" onChange={handleImageUpload} />
              <div className='mb-2'><UploadIcon /></div>
              <p>Sleep of klik op het icoon om foto toe te voegen</p>
              <p>Toegestane bestandsformaten: *.jpg, *.png, *.bmp</p>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Photo;

import PageHeading from "Layout/AppMain/PageHeading";
import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CardFooter, Col, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import Loader from "react-loaders";
import Records from "./table";
import TravelCostService from "Api/TravelCost";

const Default = () => {

    const [data, setData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCommunity, setSelectedCommunity] = useState(0);

    useEffect(() => {
        getTravelCostList(selectedCommunity);
    }, [selectedCommunity]);

    const getTravelCostList = (value) => {
        TravelCostService.travelCostList(value).then((response) => {
            if (response.data.status === true) {
                setData(response.data.data)
                setIsLoading(false)
            }
        }).catch(() => console.error);
    }

    const handleCommunityChange = (event) => {
        setIsLoading(true)
        setSelectedCommunity(event.target.value);
    };

    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Col md="10" lg="10">
                                <PageHeading />
                            </Col>
                        </Row>
                        {isLoading ?
                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                            :
                            <Row>
                                <Col md="12" lg="12" className="pb-5">
                                    <Records data={data.travelcostList} />
                                </Col>
                            </Row>
                        }
                    </div>
                </CSSTransition>
            </TransitionGroup>
            { !isLoading && 
                <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                    <div className="d-flex gap-3">
                        <select
                            className="form-select"
                            style={{ height: '33px' }}
                            value={selectedCommunity}
                            onChange={handleCommunityChange}
                        >
                                <option value={0}>{"Alle Organisaties"}</option>
                            {data.communityList.map((community, index) => (
                                <option key={index} value={community.iCommunityId}>{community.name}</option>
                            ))}
                        </select>
                    </div>
                </CardFooter>
                </div>
            }
            <ToastContainer style={{ top: "70px" }} />
        </Fragment>
    )
};

export default Default;
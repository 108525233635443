import React, { Fragment } from "react";
import { Card, CardBody} from "reactstrap";
import Tippy from "@tippyjs/react";
import WeekstaatService from "Api/Weekstaat";
import { ReactComponent as PDFIcon } from  "../../../../../assets/utils/images/svgs/PDF.svg";

const WeekReportDetails = ({ reportData }) => {
    
    const downloadReport = (id) => {
        WeekstaatService.downloadReport(id).then((response) => {
            if( response.data.file.download !== ''){
                const linkSource = response.data.file.download;
                const downloadLink = document.createElement("a");
                const fileName = "weekstaat-report.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        }).catch(() => console.error);
    }

    const showSchoolData = (school) => {
        const withAddress = [school.school, school.vVisitoraddress].filter(e => e).join(", ");
        let withCommunity;
        if (school.communityName) {
            withCommunity = withAddress.concat(" (", school.communityName, ")")
        }
        return school.school ? 
            onHover(
                withAddress,
                withCommunity
            ) : "---"
    }

    const onHover = (data, content) => (<Tippy content={content ?? data} placement="bottom"><span>{data}</span></Tippy>)

    if (!reportData) return null;
    return (
        <Fragment>
            <Card className="main-card mb-3">
                <CardBody className="p-3 weekstat-data" style={{minHeight: '450px'}}>
                    <h6 className="text-blue mb-3">Weekstaat {reportData.wssid}</h6>

                    <div className="row">
                        <div className="col-6 mb-4 fw-b">School</div>
                        <div className="col-6 text-end mb-4">{showSchoolData(reportData)}</div>

                        <div className="col-6 mb-2 fw-b">Startdatum</div>
                        <div className="col-6 text-end mb-2">{reportData.periode ? onHover(reportData.periode.split(' tot ')[0]) : "--"}</div>
                        
                        <div className="col-6 mb-4 fw-b">Einddatum</div>
                        <div className="col-6 text-end mb-4">{reportData.periode ? onHover(reportData.periode.split(' tot ')[1]) : "--"}</div>
                        
                        <div className="col-6 mb-2 fw-b">Aangemaakt door</div>
                        <div className="col-6 text-end mb-2">
                            {reportData.createdby?.replace(/\-/g, "") ? onHover(reportData.createdby) : "--"} 
                        </div>

                        <div className="col-6 mb-4 fw-b">Datum aangemaakt</div>
                        <div className="col-6 text-end mb-4">
                            {reportData.createdate ? onHover(reportData.createdate) : "--"}
                        </div>

                        <div className="col-6 mb-2 fw-b">Afgemeld door</div>
                        <div className="col-6 text-end mb-2">
                            {reportData.closedby ? onHover(reportData.closedby) : "--"} 
                        </div>

                        <div className="col-6 mb-4 fw-b">Datum afgemeld</div>
                        <div className="col-6 text-end mb-4">
                            {reportData.closedDate ? onHover(reportData.closedDate) : "--"}
                        </div>

                        <div className="col-6 mb-4 fw-b">Download</div>
                        <div className="col-6 text-end mb-4">
                            <a href="#" onClick={()=>downloadReport(reportData.wssid)} download><PDFIcon/></a>
                        </div>
                        
                        <div className="col-6 mb-4 fw-b">Verstuurd naar</div>
                        <div className="col-6 text-end mb-4">
                            {reportData.emails?.toString() ? onHover(reportData.emails.toString()) : "--"}
                        </div>
                    </div>
                </CardBody>
            </Card>

        </Fragment>
    )
};

export default WeekReportDetails;

import { React, useState, useEffect, useMemo } from "react";
import ReactTableGeneric from "Components/ReactTableGeneric";
import { ReactComponent as LinkIcon } from "../../../../assets/utils/images/svgs/open_link.svg";
import Loader from "react-loaders";
import TitleModal from "Components/Modal/InfoModal/TitleModal";
import axiosInstance from "Api";
import { useLocation } from "react-router";

const Table = () => {
    const [linkList, setLinkList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    const userType = useMemo(()=>{
        switch (location.pathname) {
            case "/links/requestor":
              return "requestor";
            case "/links/coordinator":
              return "coordinator";
            case "/links/substitutor":
              return "substitutor";
            default:
              return undefined;
          }
    }, [location.pathname]);
  
    useEffect(() => {
        linksList()
    }, [location.pathname]);

    const linksList = () => {
        setIsLoading(true)
        axiosInstance.get(`organisation-link/fetch-list?type=${userType}`).then((response) => {
            if (response.status == 200) {
                if (response.data.data.length > 0) {
                    setLinkList(response.data.data)
                }
            }
            setIsLoading(false)
        }).catch(() => console.error);
    }

    function prependHttpsIfNeeded(link) {
        if (link && !link.startsWith('http://') && !link.startsWith('https://')) {
          return `https://${link}`;
        }
        return link; 
    }


    const columns = useMemo(
        () => [
            {
                header: () => 'Bestuur',
                accessorKey: 'vCommunityName',
            },
            {
                header: () => 'Titel',
                accessorKey: 'title',
            },
            {
                header: () => 'Open Link',
                accessorKey: 'action',
                cell: (cell) => (
                    <div className="d-flex gap-3">
                        <TitleModal data={linkList[cell.row.index]?.description || ''} title="Omschrijving"/>
                        <a className="cursor-pointer" href={prependHttpsIfNeeded(linkList[cell.row.index]?.link)} target="_blank"><LinkIcon /></a>
                    </div>
                )
            }
        ],
        [linkList]
    );


    return (
        <>
            {isLoading ?
                <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> :
                <ReactTableGeneric
                    data={linkList}
                    columns={columns}
                    tableClassName="mb-0 table table-borderless table-sm table-hover cm-link-tb"
                    filters={{ search: false, pagination: false, sorting: false }}
                />
            }
        </>
    );
};
export default Table;


import PageHeading from "Layout/AppMain/PageHeading";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import ReactTableGeneric from "Components/ReactTableGeneric";
import { Card, CardBody, CardFooter, Button, Input } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { ReactComponent as Arrow } from "../../../../../assets/utils/images/svgs/Arrow 3.svg";
import TextLimit from "Api/TextLimit";
import Loader from "react-loaders";

const SubstitutionList = ({ formDataState, setFormDataState, setStep, getTravelCostSummary, shiftListLoading }) => {

    const [selectAllChecked, setSelectAllChecked] = useState(true);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(()=>{
        if((formDataState.selectedIds.length) != (formDataState.substitutorList.length)){
            setSelectAllChecked(false)
        }
        else{
            setSelectAllChecked(true)
        }
    },[formDataState.selectedIds])

    const toggleCheckbox = (checked, id) => {
        let updatedIds = [...formDataState.selectedIds];
        if (checked) {
            updatedIds.push(id);
        } else {
            updatedIds = updatedIds.filter((selectedId) => selectedId !== id);
        }
        setFormDataState({ ...formDataState, selectedIds: updatedIds })
        setSelectAllChecked(updatedIds.length === formDataState.substitutorList.length);
    };

    const selectAll = (checked) => {
        setSelectAllChecked(checked);
        const updatedIds = checked
            ? formDataState.substitutorList.map((item) => item.iAdminId)
            : [];
        setFormDataState({ ...formDataState, selectedIds: updatedIds })
    };

    const columns = [
        {
            header: () => 'Vervanger',
            accessorKey: 'SubsName',
            cell: (value) => <div>
                <span>{window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                    : TextLimit.AfwezigeLimitOptionTwo(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)}
                </span>
            </div>,
        },
        {
            header: () => 'Woonplaats',
            accessorKey: 'Woonplaats',
            cell: (value) => <div>{value.row.original.vCity}</div>,
        },
        {
            header: () => 'E-mail (privé)',
            accessorKey: 'vEmail',
            cell: (value) => <div>{value.row.original.vEmail}</div>,
        },
        {
            header: () => 'CT',
            accessorKey: 'CT',
            cell: (value) => <div>{value.row.original.CT}</div>,
        },
        {
            header: () => (
                <Input
                    type="checkbox"
                    className="col-md-12"
                    checked={selectAllChecked}
                    onChange={(e) => selectAll(e.target.checked)}
                />
            ),
            accessorKey: 'iAdminId',
            cell: (value) => (
                <Input
                    type="checkbox"
                    className="col-md-12"
                    checked={formDataState.selectedIds.includes(value.row.original.iAdminId)}
                    onChange={(e) => toggleCheckbox(e.target.checked, value.row.original.iAdminId)}
                />
            ),
        },
    ];

    const handleSubmit = () => {
        if (formDataState.automatic != null) {
            if ((formDataState.substitutorList.length == 0) || (formDataState.selectedIds.length != 0)) {
                if (isNaN(formDataState.automatic)) {
                    setShowAlert(true)
                }
                else {
                    getTravelCostSummary()
                    setStep('step3');
                }
            }
            else if ((formDataState.substitutorList.length > 0) && (formDataState.selectedIds.length == 0)) {
                setShowAlert(true)
            }
        }
        else {
            setShowAlert(true)
        }
    }

    return (
        <div>
            <Row>
                <Col md="10" lg="10">
                    <PageHeading />
                </Col>
            </Row>
            {shiftListLoading ? (
                <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
            )
                :
                (
                    <Row>
                        <Col md="12" lg="12">
                            <Card className="main-card mb-3">
                                <CardBody className="p-3 table-responsive">
                                    <ReactTableGeneric
                                        data={formDataState.substitutorList}
                                        columns={columns}
                                        tableClassName="mb-0 table table-sm table-hover table-borderless"
                                        filters={{ search: false, pagination: true, sorting: false }}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex justify-content-end">
                        <Col md="1" className="me-2 footer-option">
                            <div className="selectBy p-0">
                                <select className="form-select select-arrow-style travelCost-bold-text" style={{ height: '37px' }} value={formDataState.automatic}
                                    onChange={(event) => setFormDataState({ ...formDataState, automatic: Number(event.target.value) })}>
                                    <option>Selecteer werkwijze</option>
                                    <option value={1} className="travelCost-light-text">Alle automatisch verwerken</option>
                                    <option value={0} className="travelCost-light-text">Per vervanger verwerken</option>
                                </select>
                            </div>
                        </Col>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn me-2"
                            onClick={handleSubmit}
                            type="button"
                            color="primary"
                        >
                            Verwerken
                        </Button>
                        <Button size="sm" outline className="me-2 btn-color-style footer-btn-styling" color="primary">
                            <Arrow onClick={() => setStep('step1')} />
                        </Button>
                    </div>
                </CardFooter>
            </div>
            <SweetAlert title="Selecteer de vereiste velden" show={showAlert}
                type="warning"
                onConfirm={() => setShowAlert(false)}
                confirmBtnText="Ok"
            />
        </div>
    );
};

export default SubstitutionList;

import ReactTableGeneric from 'Components/ReactTableGeneric'
import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { ReactComponent as CrossIcon } from "../../../../../assets/utils/images/svgs/Cross.svg";
import { BsCheckCircle } from "react-icons/bs";

const NewDocumentTable = () => {
    const dummyData = [
        {
            name: 'Regli, Liesbeth',
            id: '4971',
            contract: 'GC',
            action: 'Y',
        },
        {
            name: 'Regli, Liesbeth',
            id: '4971',
            contract: 'GC',
            action: 'N',
        },
        {
            name: 'Regli, Liesbeth',
            id: '4971',
            contract: 'GC',
            action: 'Y',
        },
        {
            name: 'Regli, Liesbeth',
            id: '4971',
            contract: 'GC',
            action: 'N',
        }
    ]
    const [documentList, setDocumentList] = useState(dummyData);

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Naam',
                accessorKey: 'name',
            },
            {
                header: () => 'Clooser ID',
                accessorKey: 'id'
            },
            {
                header: () => 'Contracten',
                accessorKey: 'contract'
            },
            {
                header: () => 'Eerste inzet',
                accessorKey: 'action',
                cell: (cell) => (
                    <>
                        {cell.row.original.eRuleOut === "Y" ?
                            <BsCheckCircle color='#3AC47D' fontSize="18px" className='ms-5'/> :
                            <CrossIcon fontSize="18px" />}
                    </>
                )
            }
        ],
        [documentList]
    );

    return (
        <Card className="main-card pb-5">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Nieuwe documenten</h5>
                <ReactTableGeneric
                    data={documentList}
                    columns={columns}
                    tableClassName="mb-0 table table-borderless table-sm table-hover"
                    filters={{ search: false, pagination: false, sorting: false }}
                />
            </CardBody>
        </Card>
    )
}

export default NewDocumentTable;
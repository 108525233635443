import { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';


const Documenten = ({ data, isLoading, currentUser, filterCommunity, setFilterCommunity, adminCommunityList, setActiveTab, setDataUpdated }) => {
    const [currentStep, setCurrentStep] = useState(1)

    return (
        <>
            {currentStep === 1 &&
                <Step1 currentUser={currentUser} isLoading={isLoading} documentData={data}
                    setActiveTab={setActiveTab} filterCommunity={filterCommunity}
                    adminCommunityList={adminCommunityList} setFilterCommunity={setFilterCommunity}
                    setDataUpdated={setDataUpdated} />}
            {currentStep === 2 && <Step2 setActiveTab={setActiveTab} />}
            {currentStep === 3 && <Step3 setActiveTab={setActiveTab} />}
        </>
    )
}

export default Documenten
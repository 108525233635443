import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ToastContainer } from "react-toastify";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import CompileService from "Api/Weekstaten/Compile";
import { useNavigate } from "react-router";

const Default = () => {

    const [formDataState, setFormDataState] = useState({
        communityData: '',
        bShowProlonged: 'N',
        selectLangeContract: true,
        selectDagdelen: true,
        startDate: '',
        endDate: '',
        rdApproved: 1,
        selectCoordinator: false,
        selectSchool: true,
        selectSalarisAdmin: false,
        selectCoordinatorAttach: false,
        selectSchoolAttach: false,
        selectSalarisAdminAttach: false,

        informText: `Op www.clooser.nl staat de weekstaat van %SCHOOL% %ORGANISATIE% voor de periode van %FROM_DATE% t/m %TO_DATE% voor u klaar.\nWilt u inloggen op www.clooser.nl en de weekstaat van bovenstaande periode beoordelen.\nIndien u de weekstaat wenst af te keuren dient u dit online toe te lichten.\nDeze mail is een systeembericht, u kunt hier niet op antwoorden.`,
        informEmail: '',
        emailList: [],
        datePickerStyle: "weekstaten-datePicker form-control",

        schoolList: [],
        selectedIds: [],
        automatic: null,

        weekList: [],
        itemsp: [],
        items: []
    });

    const [step, setStep] = useState('step1')
    const [communityId, setCommunityId] = useState(0)

    const [loading, setLoading] = useState(true);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [weekstaatId, setWeekstaatId] = useState(0);
    var currentSchoolId;
    let navigate = useNavigate();

    useEffect(() => {
        getCommunityData();
    }, [communityId])

    const getCommunityData = () => {
        CompileService.communityData(communityId).then((response) => {
            if (response.data.status === true) {
                setFormDataState({
                    ...formDataState,
                    communityData: response.data.data,
                    bShowProlonged: response.data.data.bShowProlonged
                })
            }
        }).catch(() => console.error);
    }

    const getSchoolList = () => {
        setLoading(true)
        CompileService.schoolList(communityId).then((response) => {
            if (response.data.status === true) {
                setFormDataState(prevState => {
                    const updatedState = { ...prevState, schoolList: response.data.data };
                    const schoolIds = response.data.data.map(item => item.iSchoolId);
                    return { ...updatedState, selectedIds: schoolIds };
                });
                setLoading(false)
            }
        })
            .catch((error) => console.error(error));
    };

    const convertEmails = (emailArray) => {
        return emailArray.join(';');
    };

    const saveWeekstaatData = () => {
        setLoading(true)
        const convertedEmails = convertEmails(formDataState.informEmail);
        const dataToSend = {
            school: formDataState.selectedIds,
            iCommunityId: communityId,
            rdAutoSend: formDataState.automatic == 1 ? "Y" : "N",
            rdApproved: formDataState.rdApproved == 1 ? "Y" : "N",
            selectLangeContract: formDataState.selectLangeContract ? "on" : "off",
            selectDagdelen: formDataState.selectDagdelen ? "on" : "off",
            dBeginDate: formDataState.startDate,
            dEndDate: formDataState.endDate,
            selectCoordinator: formDataState.selectCoordinator ? "on" : "off",
            selectSchool: formDataState.selectSchool ? "on" : "off",
            selectSalarisAdmin: formDataState.selectSalarisAdmin ? "on" : "off",
            selectCoordinatorAtach: formDataState.selectCoordinatorAttach ? "on" : "off",
            selectSchoolAttach: formDataState.selectSchoolAttach ? "on" : "off",
            selectSalarisAdminAttach: formDataState.selectSalarisAdminAttach ? "on" : "off",
            informEmail: convertedEmails ? convertedEmails : "",
            informText: formDataState.informText
        }

        CompileService.saveWeekstaatSchools(dataToSend).then((response) => {
            if (response.data.status === true) {
                setWeekstaatId(response.data.weekstaatId);

                if ((formDataState.automatic == 1)) {
                    sessionStorage.setItem('pdfDownloading', true);
                    CompileService.generatePDF().then((response)=>{
                        console.log(response, "this is the response from API")
                    }).catch(error=>{
                        console.log(error)
                    });
                    navigate("/weekstaten/overzicht");
                } else {
                    getWeeklyschedulelist(0, response.data.weekstaatId, "nextId")
                }
                setLoading(false)
            }
        })
            .catch((error) => console.error(error));
    };

    const getWeeklyschedulelist = (index, weekstaatId, type) => {
        currentSchoolId = formDataState.selectedIds[index];
        const convertedItemsp = convertEmails(formDataState.itemsp);
        const convertedItems = convertEmails(formDataState.items);
        setLoading(true)
        const dataToSend = {
            iSchoolId: currentSchoolId,
            weekstaatId: weekstaatId,
            selectLangeContract: formDataState.selectLangeContract ? "on" : "off",
            selectDagdelen: formDataState.selectDagdelen ? "on" : "off",
            dBeginDate: formDataState.startDate,
            dEndDate: formDataState.endDate,
            rdApproved: formDataState.rdApproved == 1 ? "Y" : "N",
            itemsp: type == "refresh" ? "" : convertedItemsp,
            items: type == "refresh" ? "" : convertedItems
        }

        CompileService.weeklyschedulelist(dataToSend).then((response) => {
            if (response.data.status === true) {
                setFormDataState({
                    ...formDataState,
                    weekList: response.data.data,
                    itemsp: [],
                    items: []
                })
                setStep('step4');
                setLoading(false)
            }
        })
            .catch((error) => console.error(error));
    };

    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    {
                        (step == 'step1') ?
                            <Step1 setStep={setStep} formDataState={formDataState} setFormDataState={setFormDataState}
                                communityId={communityId} setCommunityId={setCommunityId} getSchoolList={getSchoolList} /> :
                            (step == 'step2') ?
                                <Step2 setStep={setStep} formDataState={formDataState} setFormDataState={setFormDataState} loading={loading} setLoading={setLoading} /> :
                                (step == 'step3') ?
                                    <Step3 setStep={setStep} loading={loading} formDataState={formDataState} setLoading={setLoading} communityId={communityId}
                                        saveWeekstaatData={saveWeekstaatData} /> :
                                    <Step4 setStep={setStep} formDataState={formDataState} setFormDataState={setFormDataState} communityId={communityId} loading={loading} setLoading={setLoading}
                                        currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} getWeeklyschedulelist={getWeeklyschedulelist} weekstaatId={weekstaatId} />
                    }
                </CSSTransition>
            </TransitionGroup>
            <ToastContainer style={{ top: "70px" }} />
        </Fragment>
    )
};

export default Default;

import React, { Fragment, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import DefaultButton from "Components/Buttons/DefaultButton";
import { useLocation } from "react-router";
import { resultIcon } from "../utils";

const WeekReportForm = ({ reportData, currentIndex, handleSubmit, selectValue, setSelectValue,text, setText, unsubscribe, setTextError, textError}) => {
    const location = useLocation();
    
    useEffect(()=>{
        setSelectValue('0');
        setTextError('');
    }, [currentIndex])

    if (!reportData) return null;
    return (
        <Fragment>
            <Card className="main-card mb-3">
                <CardBody className="p-3 weekstat-data">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <h6 className="text-blue mb-4">Weekstaat beoordelen</h6>
                        <div className="row">
                            <div className="col-6 mb-3 fw-b">Status weekstaat</div>
                            <div className="col-6 mb-3">
                                {reportData.status ? resultIcon(reportData.status) : "--"}
                            </div>

                            {reportData.status === "Openstaand" && <>
                                <div className="col-6 mb-3 fw-b">Beoordelen</div>
                                <div className="col-6 d-flex justify-content-end selectBy mb-3">
                                    <select disabled={unsubscribe} className="form-select" style={{ width: '160px' }} value={selectValue}
                                    onChange={(event) => { setSelectValue(event.target.value); setTextError('') }}>
                                        <option value='0'>Selecteer keuze</option>
                                        <option value='1'>Goedkeuren</option>
                                        <option value='2'>Afkeuren</option>
                                    </select>
                                </div>
                            </>}

                            {selectValue === '2' && reportData.status === "Openstaand"  && <div>
                                <p className="mb-1">Geef toelichting op reden van afkeur</p>
                                <textarea
                                    value={text}
                                    onChange={(e) => { setText(e.target.value); setTextError('') }}
                                    rows="4"
                                    className="w-100"
                                ></textarea>
                            </div>}
                        </div>
                        {reportData.status !== "Openstaand" && <div className="row res">
                            <div className="col-6 mb-3">Beoordeeld door</div>
                            <div className="col-6 mb-3 fw-b">{reportData?.acceptedby || '--'}</div>
                            <div className="col-6 mb-3">Datum beoordeling</div>
                            <div className="col-6 mb-5 fw-b">{reportData?.acceptdate || '--'}</div>

                            <div className="col-12 mb-3 fw-b">Afgemeld</div>
                            <div className="col-6 mb-3">Afgemeld door</div>
                            <div className="col-6 mb-3 fw-b">{reportData?.closedby || '--'}</div>
                            <div className="col-6 mb-3">Datum afmelding</div>
                            <div className="col-6 mb-4 fw-b">{reportData?.closedDate || '--'}</div>
                        </div>}
                        
                        {reportData.status === "Afgekeurd" && <div className="row ">
                            <div className="col-12 fw-b">Memotext</div>
                            <div className="col-12">{reportData?.note}</div>
                        </div>}

                        {textError &&
                            <p className="text-danger">{textError}</p>
                        }
                        {(reportData.status === "Openstaand" && location.pathname === "/weekstaten/overzicht") && <div className="text-center mt-3">
                            <DefaultButton className="btn-color-style" type="submit">Toepassen</DefaultButton>
                        </div>}
                    </form>
                </CardBody>
            </Card>

        </Fragment>
    )
};

export default WeekReportForm;

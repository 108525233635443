import { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Label, Row, FormGroup, Form, Input } from "reactstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ErrorMessage, Formik } from "formik";
import { ReactComponent as Save } from "../../../assets/utils/images/svgs/save_two.svg";
import { ReactComponent as Arrow } from "../../../assets/utils/images/svgs/Frame 585.svg";
import DatePicker from "react-multi-date-picker";
import { DropdownList } from "react-widgets";
import { Typeahead } from "react-bootstrap-typeahead";
import moment from "moment";
import AuthService from "Api/Auth";
import { rolesList } from "Components/Router/Permission";
import axiosInstance from "Api";
import TextLimit from "Api/TextLimit";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";


const UpdateRequestModel = ({ data, isOpen, setEditRequestIsOpen, getRequests }) => {

    const [schools, setSchools] = useState([]);
    const [communityId, setcommunityId] = useState(null);
    const isCoordinator = AuthService.getUserRoles().includes(rolesList.coordinator.name);
    const [codeListData, setcodeListData] = useState([]);
    const [groupsData, setgroupsData] = useState([]);
    const [absentsData, setAbsentsData] = useState([]);
    const [melderList, setMelderList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [formDataState, setFormDataState] = useState({
        iSubstitutionId: '',
        school:'',
        melder:'',
        code:'',
        kostenplaats:'',
        email1:'',
        email2:'',
        groups: [],
        other:'',
        sms:0,
        prive:0,
        excludewwz:'',
        planoption:'',
    });

    let id = data !== undefined && data.iCommunityId !== undefined ? data.iCommunityId : 0;
    let schoolId = data !== undefined && data.iSchoolId !== undefined ? data.iSchoolId : 0;

    useEffect(() => {
        setFormDataState({
                iSubstitutionId: data !== undefined && data.iSubstitutionId !== undefined ? data.iSubstitutionId : '',
                school: data !== undefined && data.iSchoolId !== undefined ? data.iSchoolId : '',
                melder:data !== undefined && data.melderAdminId !== undefined ? data.melderAdminId : '',
                code: data !== undefined && data.iCodeId !== undefined ? data.iCodeId : '',
                kostenplaats:data !== undefined && data.vKostenplaats !== undefined && data.vKostenplaats != 'null' ? data.vKostenplaats : '',
                email1: data !== undefined && data.vExtraEmail1 !== undefined && data.vExtraEmail1 != 'null' ? data.vExtraEmail1 : '',
                email2: data !== undefined && data.vExtraEmail2 !== undefined && data.vExtraEmail2 != 'null' ? data.vExtraEmail2 : '',
                groups: data !== undefined && data.groupList !== undefined ? data.groupList.map(group => group.iSubGroupId) : '',
                other: data !== undefined && data.OtherHowToInform !== undefined && data.OtherHowToInform != 'null' ? data.OtherHowToInform : '',
                sms: data !== undefined && data.sms !== undefined ? data.sms : '',
                prive: data !== undefined && data.prive !== undefined ? data.prive : '',
                excludewwz: data !== undefined && data.eExclude_wwz !== undefined ? data.eExclude_wwz : '',
                planoption: data !== undefined && data.iPlanOption !== undefined ? data.iPlanOption : '',
        });

        if (data != null && data.selectedCode != undefined) {
            let userData = data.selectedCode;
            userData.forEach(element => {
                element.codeName = element.vCode + ' - ' + element.vCodeValue;
            });
            setcodeListData(userData);
        }

        setMelderList(data !== undefined && data.selectedMelder !== undefined ? data.selectedMelder : []);
        setGroupList(data !== undefined && data.groupList !== undefined ? data.groupList : []);
        getSchoolsByCommunityId();
        getGroupsList(id);
        // getAbsentsList(id);
        getMelderList(schoolId, id);
    }, [data]);

    // get all necessary data for basic information column
    const getSchoolsByCommunityId = () => {
        setcommunityId(id);
        getAbsenceCodeByCommunityId(id);
        getGroupsList(id);
        const url = isCoordinator ? 'schools?iCommunityId=' : 'schools-list?iCommunityId=';
        axiosInstance.get(url + id).then((response) => {
            if (response.status == 200) {
                if (response.data.data != null && response.data.data != undefined) {
                    let userData = response.data.data;
                    setSchools(userData);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }
 
    const getAbsenceCodeByCommunityId = (id) => {
        axiosInstance.get("absence-codes-list?iCommunityId=" + id).then((response) => {
            if (response.status == 200) {
                if (response.data.data != null && response.data.data != undefined) {
                    let userData = response.data.data;
                    userData.forEach(element => {
                        element.codeName = element.vCode + ' - ' + element.vCodeValue;
                        setcodeListData(codeListData => [...codeListData, element] );
                    });
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const getGroupsList = (id) => {
        axiosInstance.get("groups-list?iCommunityId=" + id).then((response) => {
            if (response.status == 200) {
                if (response.data.data != null && response.data.data != undefined) {
                    let userData = response.data.data;
                    setgroupsData(userData);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const getMelderList = (id, communityId) => {
        axiosInstance.get("melder/list/" + id + "/" + communityId).then((response) => {
            if (response.status == 200) {
                if (response.data.list != null && response.data.list != undefined) {
                    let userData = response.data.list;
                    userData.forEach(element => {
                        setMelderList(melderList => [...melderList, element]);
                    });
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const uniqueMelderList = melderList.reduce((uniqueArray, currentItem) => {
        // Check if the item with the same ID already exists in the uniqueArray
        const existingItem = uniqueArray.find(item => item.iAdminId === currentItem.iAdminId);
      
        // If not, add the current item to the uniqueArray
        if (!existingItem) {
          uniqueArray.push(currentItem);
        }
      
        return uniqueArray;
    }, []);

    const validationSchema = Yup.object().shape({
        school: Yup.string().required('Verplicht veld'),
        code: Yup.string().required('Verplicht veld'),
        groups: Yup.array().min(1, 'Selecteer minimaal 1 groep').required('Verplicht veld'),
        planoption: Yup.string().required('Verplicht veld'),
    });

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append('iSubstitutionId', formDataState.iSubstitutionId);
        formData.append('school', formDataState.school);
        formData.append('melder', formDataState.melder);
        formData.append('code', formDataState.code);
        formData.append('kostenplaats', formDataState.kostenplaats);
        formData.append('email1', formDataState.email1);
        formData.append('email2', formDataState.email2);
        formData.append('other', formDataState.other);
        formData.append('groups', formDataState.groups);
        formData.append('sms', formDataState.sms);
        formData.append('prive', formDataState.prive);
        formData.append('excludewwz', formDataState.excludewwz);
        formData.append('planoption', formDataState.planoption);
       
        axiosInstance.post('verzoeken/edit', formData).then((response) => {
            if (response.data.status == true) {
                getRequests();
                toast["success"]("update succesvol aangevraagd.")
                setEditRequestIsOpen(false);
            }
        }).catch((error) => console.log("catch error:", error));
    }

    return (
        <Modal id={"tooltip"} isOpen={isOpen} toggle={() => setEditRequestIsOpen(!isOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling edit-request-data">
            <ModalHeader>
                <div className="modal-header-styling">
                    Basis gegevens verzoek wijzigen
                </div>
                <div className="cross-icon-color">
                    <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setEditRequestIsOpen(false)}> </i>
                </div>
            </ModalHeader>

            <ModalBody className="bg-white rounded">

                <Formik
                enableReinitialize
                initialValues={formDataState}
                validationSchema={validationSchema}
                onSubmit={
                    () => handleSubmit()}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                <Form onSubmit={handleSubmit}>
                    <>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>

                                    <Label className="request-model-label-style">
                                        ID
                                    </Label>
                                    <div className="request-value-style">{data !== undefined && data.iSubstitutionId !== undefined && data.iSubstitutionId}</div>

                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        Datum & tijd melding
                                    </Label>
                                    <div className="request-value-style">
                                        {
                                            data !== undefined && data.dAddedByDir !== undefined &&
                                            new Date(moment(data.dAddedByDir, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' }) + " " + data.dAddedByDir
                                        }
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        School
                                    </Label>
                                    <DropdownList dataKey='iSchoolId' textField='vSchoolName'
                                        placeholder="Kies school"
                                        onChange={(nextValue) => {
                                            setFormDataState({ ...formDataState, school: nextValue.iSchoolId })
                                            setFormDataState({ ...formDataState, melder: '' })
                                            setMelderList([]);
                                            getMelderList(nextValue.iSchoolId, id)
                                        }}
                                        name="school"
                                        value={formDataState.school}
                                        data={schools}
                                    />
                                    <ErrorMessage name="school" component="small"
                                            className="block mt-2 invalid-feedback" />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        Melder
                                    </Label>
                                    <DropdownList dataKey='iAdminId' textField='val'
                                        placeholder="Kies melder"
                                        name="iAdminId"
                                        onChange={e => {
                                            setFormDataState({ ...formDataState, melder: e.iAdminId })

                                        }}
                                        value={formDataState.melder}
                                        data={uniqueMelderList}
                                        
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        Code Vervanging
                                    </Label>
                                    <DropdownList dataKey='iCodeId' textField='codeName'
                                        placeholder="Kies code afwezigheid"
                                        name="code"
                                        onChange={e => {
                                            setFormDataState({ ...formDataState, code: e.iCodeId })
                                        }}
                                        value={formDataState.code}
                                        data={codeListData}
                                        
                                    />
                                    <ErrorMessage name="code" component="small"
                                            className="block mt-2 invalid-feedback" /> 
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        Kostenplaats / Project
                                    </Label>
                                    <Input 
                                        value={formDataState.kostenplaats}
                                        onChange={(e) => {
                                            setFormDataState({ ...formDataState, kostenplaats: e.target.value })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        Functie(s)
                                    </Label>

                                    <Typeahead id="iSubGroupId" labelKey="vGroup" 
                                        multiple={true}
                                        options={groupsData}
                                        placeholder="Kies groepen"
                                        name="groupsId"
                                        selected={groupList}
                                        onChange={e => {
                                            setFormDataState({ ...formDataState, groups: e.map(group => group.iSubGroupId) })
                                            setGroupList(e)
                                        }}
                                    />
                                    <ErrorMessage name="groups" component="small"
                                            className="block mt-2 invalid-feedback" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        Ketenregeling toepassen
                                    </Label>
                                    <div>
                                        <Input
                                            type="radio"
                                            name="excludewwz"
                                            value="0"
                                            className="me-2 request-value-style"
                                            checked={formDataState.excludewwz == 0 ? true : false}
                                            onClick={e => {
                                                setFormDataState({ ...formDataState, excludewwz: 0 })
                                            }}
                                        />
                                        <span className="me-2 request-value-style">Ja</span>

                                        <Input
                                            type="radio"
                                            name="excludewwz"
                                            value="1"
                                            className="me-2 request-value-style "
                                            checked={formDataState.excludewwz == 1 ? true : false}
                                            onClick={e => {
                                                setFormDataState({ ...formDataState, excludewwz: 1 })
                                            }}
                                        />
                                        <span className="me-2 request-value-style"> Nee</span>

                                    </div>
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        Planning
                                    </Label>
                                    <div>
                                        <Input
                                            type="radio"
                                            name="planoption"
                                            value="3"
                                            className="me-2"
                                            onClick={e => {
                                                setFormDataState({ ...formDataState, planoption: 3 })
                                            }}
                                            checked={formDataState.planoption == 3 || formDataState.planoption == 0 ? true : false}
                                        />
                                        <span className="me-2 request-value-style">Centrale Planning</span>

                                        <Input
                                            type="radio"
                                            name="planoption"
                                            value="4"
                                            className="me-2" 
                                            onClick={e => {
                                                setFormDataState({ ...formDataState, planoption: 4 })
                                            }}
                                            checked={formDataState.planoption == 4 || formDataState.planoption == 1 ? true : false}
                                        />
                                        <span className="me-2 request-value-style">Schoolniveau</span>

                                    </div>
                                    <ErrorMessage name="planoption" component="small"
                                            className="block mt-2 invalid-feedback" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        Anders
                                    </Label>
                                    <Input 
                                        value={formDataState.other}
                                        onChange={e => {
                                            setFormDataState({ ...formDataState, other: e.target.value })
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        E-mail extra 1
                                    </Label>
                                    <Input
                                        type="text"
                                        name="email1"
                                        onChange={(val) => {
                                            setFormDataState({ ...formDataState, email1: val.target.value })
                                        }}
                                        value={formDataState.email1}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        E-mail extra 2
                                    </Label>
                                    <Input
                                        type="text"
                                        name="email2"
                                        onChange={(val) => {
                                            setFormDataState({ ...formDataState, email2: val.target.value })
                                        }}
                                        value={formDataState.email2}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        Prive E-mail
                                    </Label>
                                    <div>
                                        <Input
                                            type="radio"
                                            name="prive"
                                            className="me-2"
                                            checked={formDataState.prive == 1 ? true : false}
                                            onClick={(val) => {
                                                setFormDataState({ ...formDataState, prive: 1 })
                                            }}
                                        />
                                        <span className="me-2 request-value-style">Inschakelen</span>

                                        <Input
                                            type="radio"
                                            name="prive"
                                            className="me-2"
                                            checked={formDataState.prive == 0 ? true : false}
                                            onClick={(val) => {
                                                setFormDataState({ ...formDataState, prive: 0 })
                                            }}
                                        />
                                        <span className="me-2 request-value-style">Uitschakelen</span>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="request-model-label-style">
                                        SMS
                                    </Label>
                                    <div>
                                        <Input
                                            type="radio"
                                            name="sms"
                                            className="me-2"
                                            checked={formDataState.sms == 1 ? true : false}
                                            onClick={(val) => {
                                                setFormDataState({ ...formDataState, sms: 1 })
                                            }}
                                        /> <span className="me-2 request-value-style">Inschakelen</span>

                                        <Input
                                            type="radio"
                                            name="sms"
                                            className="me-2"
                                            checked={formDataState.sms == 0 ? true : false}
                                            onClick={(val) => {
                                                setFormDataState({ ...formDataState, sms: 0 })
                                            }}
                                        /> <span className="me-2 request-value-style">Uitschakelen</span>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <div className="request-modal-footer">
                            <Button className="d-flex align-items-center btn-color-style  opslaan-btn" size="sm" outline color="primary" type="submit">
                                <Save />
                                Toepassen
                            </Button>

                            <Button className="d-flex align-items-center btn-color-style  opslaan-btn" size="sm" outline color="primary" onClick={() => setEditRequestIsOpen(false)}>
                                <Arrow />
                                Terug
                            </Button>
                        </div>
                    </>
                </Form>
                 )}
            </Formik>
            </ModalBody>

        </Modal>
    );
}

export default UpdateRequestModel;

import React, {useEffect} from 'react'
import {
    Column,
    Table,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    sortingFns,
    getSortedRowModel,
    FilterFn,
    SortingFn,
    ColumnDef,
    flexRender,
} from '@tanstack/react-table'


import Pagination from "./pagination";
import DebouncedInput from "Components/Forms/DebouncedInput";
import { Loader } from 'react-loaders';
import { useLocation } from 'react-router';


const filterTableGeneric = (row, columnId, values, addMeta) => {

    let searchValues = values.split(/(\s+)/).filter( e => e.trim().length > 0);

    return searchValues.every((value)=>{
        return Object.values(row.original).join(" ").toLowerCase().includes(value.toLowerCase());
    });

    // old implementation with ranking
    // TODO: implement search using words ranking
    //     const itemRank = rankItem(row.getValue(columnId), value)
    //     console.log(itemRank);

    // addMeta({
    //     itemRank,
    // })

    // return itemRank.passed;
}

const ReactTableGenericForUsers = ({user, setUser, data, columns, filters, tableClassName, rowCount = 20, filterRecords, userTablePageSize, listSortingByKey, filterList, totalPageCount, paginate, pageNumber, setPageNumber, pageSizeCount, showLoader}) => {
    const [globalFilter, setGlobalFilter] = React.useState('')
    const [sorting, setSorting] = React.useState([]);
    const [sortingArray, setSortingArray] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(pageSizeCount);
    const tableClass =  tableClassName || "mb-0 table table-bordered table-sm table-striped table-hover";
    const {search = true, columnFilter=false, pagination = true, columnSorting=true } = filters || {};
    const fuzzyFilter = filterRecords ?? filterTableGeneric;
   const location = useLocation();
    const pointerStyles = {
        // cursor: 'pointer',
        userSelect: 'none',
    }

    const reactTablePageSize = (value) => {
        userTablePageSize(value);
    }

    // const listSorting = (val) => {
    //     setSorting(val);
    //     setSortingArray(val);
    //     setTimeout(() => {
    //         listSortingByKey();
    //     }, 3000);
    // }

    const updatePaginate = (val) => {
        paginate(val);
    }

    const table = useReactTable({
        data,
        columns,
        state: {
            sortingArray,
            globalFilter,
        },
        onSortingChange: setSortingArray,
        // onSortingChange: listSorting,
        // onSortingChange: (val) => {
        //     listSorting(val);
        // },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel:  getFilteredRowModel(),
        getPaginationRowModel:  getPaginationRowModel(),
        getSortedRowModel:  getSortedRowModel(),
    })

    useEffect(() => {
        table.setPageSize(pageSize)
    }, [pageSize]);

    return (
        <div>
            <div className='d-flex justify-content-end align-items-center gap-3 mb-3'>
                    {(location.pathname === "/beheerder/gebruikers" || location.pathname === "/beheerder")&& <div className="d-flex justify-content-end align-items-center">
                        <div>User</div>
                            <div className="col-md-10 selectBy p-0 ms-2 w-auto cursor-pointer">
                                <select
                                    className="form-select"
                                    style={{ height: '33px' }}
                                    value={user}
                                    onChange={(e)=>setUser(e.target.value)}
                                >
                                    <option value={""}>Select User</option>
                                    <option value={"administrators"}>Administrators</option>
                                    <option value={"coordinator"}>Coordinator</option>
                                    <option value={"melder"}>Melder</option>
                                    <option value={"vervanger"}>Vervanger</option>
                                </select>
                            </div>
                    </div>}
                    { search ?  <div className="form-group pull-right">
                        <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={value => filterList(String(value))}
                        className="form-control search"
                        placeholder="Zoeken..." />
                    </div> : null }
            </div>
                
            <table className={tableClass}>
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : (
                                        <div
                                            {...{
                                                style: header.column.getCanSort()
                                                        ? pointerStyles
                                                        : '',
                                                onClick: header.column.getToggleSortingHandler(),
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {
                                                {
                                                asc: ' 🔼',
                                                desc: ' 🔽',
                                            }[header.column.getIsSorted()] ?? null}
                                        </div>
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                { showLoader === false &&
                     <tbody>
                     {
                         table.getRowModel().rows.length ?
                         table.getRowModel().rows.map(row => (
                             <tr key={row.id}>
                                 {row.getVisibleCells().map(cell => (
                                     <td key={cell.id}>
                                         {flexRender(
                                             cell.column.columnDef.cell,
                                             cell.getContext())
                                         }
                                     </td>
                                 ))}
                             </tr>
                         ))
                         : <tr><td colSpan={9} align='center'>Geen data beschikbaar</td></tr>
                     }
                     </tbody>
                }
            </table>
            { showLoader === true &&
                    <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
            }
            { pagination ? <Pagination table={table} reactTablePageSize={reactTablePageSize} totalPageCount={totalPageCount} updatePaginate={updatePaginate} pageNumber={pageNumber} setPageNumber={setPageNumber} showLoader={showLoader}/> : null }
        </div>
    )
}

export default ReactTableGenericForUsers;

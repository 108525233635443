import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const rolesList = {
  admin: {
    name: "administrator",
    route: "/beheerder",
  },
  requestor: {
    name: "requestor",
    route: "/verzoeken",
  },
  substitutor: {
    name: "substitutor",
    route: "/home",
  },
  coordinator: {
    name: "coordinator",
    route: "/verzoeken/overzicht",
  },
};

// find data for current role
// example: return {name: "administrator", route: "/beheerder"}
export const getCurrentRole = (roles) => {
  const roleEntries = Object.entries(rolesList).find(
    ([key, value]) => value.name == roles[0]
  );
  return roleEntries ? roleEntries[1] : <Navigate to="/no-access" />;
};

// showError(boolean) - error page, in the case "403 Forbidden"

const HasPermission = ({ children, showFor, hideFor, showError }) => {
  const [hasPermission, setHasPermission] = useState(true);
  const userRoles = useSelector((state) => state.UserPermission);
 
  const checkRoles = (roles) => {
    const rolesArray = Array.isArray(roles) ? roles : [roles];
    return rolesArray.some((role) => userRoles.roles.includes(role));
  };
  
  useEffect(() => {
    if (showFor) {
      setHasPermission(checkRoles(showFor));
      
    } else if (hideFor) {
      setHasPermission(!checkRoles(hideFor));
    }
  }, [showFor, hideFor]);

  return hasPermission ? (
    <>{children}</>
  ) : (
    showError && <Navigate to="/no-access" />
  );
};

export default HasPermission;

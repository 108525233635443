import PageHeading from "Layout/AppMain/PageHeading";
import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardBody, Table, CardFooter, Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, Nav } from "reactstrap";
import { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import ReactTableGeneric from "Components/ReactTableGeneric";
import { ReactComponent as Arrow } from "../../../../../assets/utils/images/svgs/Arrow 3.svg";
import { ReactComponent as EyeIcon } from "../../../../../assets/utils/images/svgs/Eye-icon.svg";
import { ReactComponent as Email } from "../../../../../assets/utils/images/svgs/E-mail.svg";
import { ReactComponent as PDF } from "../../../../../assets/utils/images/svgs/PDF.svg";
import Loader from "react-loaders";
import moment from "moment";
registerLocale("nl", nl);

const Step3 = ({ formDataState, setStep, loading, setLoading, communityId, saveWeekstaatData }) => {

    const [filteredData, setFilteredData] = useState([]);
    const [communityName, setCommunityName] = useState('');
    const [menuShow, setMenuShow] = useState(false);

    useEffect(() => {
        getCommunityName();
        getSchoolData();
    }, [])

    const getCommunityName = () => {
        const community = formDataState.communityData.communityList.find(community => community.iCommunityId === communityId);
        if (community) {
            setCommunityName(community.name);
        } else {
            setCommunityName('Community not found');
        }
    };

    const getSchoolData = () => {
        if (formDataState.schoolList.length > 0) {
            const filtered = formDataState.schoolList.filter(obj => formDataState.selectedIds.includes(obj.iSchoolId));
            setFilteredData(filtered);
        }
        else {
            setFilteredData([])
        }
        setLoading(false)
    }

    const columns = [
        {
            header: () => 'School',
            accessorKey: 'vSchoolName',
            cell: (value) => <div>{value.row.original.vSchoolName}</div>,
        },
        {
            header: () => 'BRIN',
            accessorKey: 'vBrinnummer',
            cell: (value) => <div>{value.row.original.vBrinnummer}</div>,
        },
        {
            header: () => 'Plaats',
            accessorKey: 'vVisitcity',
            cell: (value) => <div>{value.row.original.vVisitcity}</div>,
        },
        {
            header: () => 'E-mail',
            accessorKey: 'vWeekStaatMail',
            cell: (value) => <div>{value.row.original.vWeekStaatMail}</div>,
        }
    ];

    const goToLastStep = () => {
        setStep('step2')
    }

    const menuIsOpen = () => {
        if (menuShow === false) {
            setMenuShow(true);
        } else {
            setMenuShow(false);
        }
    }

    const handleSubmit = () => {
        saveWeekstaatData();
    }

    const informerenSelected = (formDataState) => {
        const entities = [];
      
        if (formDataState.selectCoordinator || formDataState.selectCoordinatorAttach) {
          entities.push(
            <>
              Coördinator
              {formDataState.selectCoordinator && <Email className="ms-2" />}
              {formDataState.selectCoordinatorAttach && <PDF className="ms-2" />}
            </>
          );
        }
      
        if (formDataState.selectSchool || formDataState.selectSchoolAttach) {
          entities.push(
            <>
              School
              {formDataState.selectSchool && <Email className="ms-2" />}
              {formDataState.selectSchoolAttach && <PDF className="ms-2" />}
            </>
          );
        }
      
        if (formDataState.selectSalarisAdmin || formDataState.selectSalarisAdminAttach) {
          entities.push(
            <>
              Salaris Administratie
              {formDataState.selectSalarisAdmin && <Email className="ms-2" />}
              {formDataState.selectSalarisAdminAttach && <PDF className="ms-2" />}
            </>
          );
        }
      
        return (
          <div>
            {entities.map((entity, index) => (
              <span key={index} className="d-block">
                {entity}
                {index < entities.length - 1 && " , "}
              </span>
            ))}
            {entities.length === 0 && "-"}
          </div>
        );
    };

    return (
        <div>
            <Row>
                <Col md="10" lg="10">
                    <PageHeading />
                </Col>
            </Row>
            {(loading) ? (
                <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
            )
                :
                (
                    <>
                        <Row>
                            <Col md="12" lg="12" >
                                <Card className="main-card mb-3 sub-detail-table-style">
                                    <CardBody className="p-3">
                                        <Table size="sm" borderless className="mb-0 ">
                                            <tbody>
                                                <tr className="border-bottom-0">
                                                    <th className="fw-bold col-2">Organisatie</th>
                                                    <th className="fw-bold col-2">Periode</th>
                                                    <th className="fw-bold col-2">Ter goedkeuring </th>
                                                    <th className="fw-bold col-2">Verzendwijze</th>
                                                </tr>
                                                <tr className="border-bottom-0">
                                                    <td className="wl">
                                                        {communityName}
                                                    </td>

                                                    <td className="wl">
                                                        {moment(formDataState.startDate, 'YYYY-MM-DD').format('DD-MM-YY') + " tot " + moment(formDataState.endDate, 'YYYY-MM-DD').format('DD-MM-YY')}
                                                    </td>

                                                    <td className="wl">
                                                        {formDataState.rdApproved == 1 ? "Ja" : "Nee"}
                                                    </td>

                                                    <td className="wl">
                                                        {(formDataState.automatic == 1) ? "Alle automatisch verwerken" : "Per school verwerken"}
                                                    </td>
                                                </tr>

                                                <tr className="border-bottom-0">
                                                    <td className="p-0" colSpan="5">
                                                        <Table size="sm" borderless className="mb-0 verzoeken-inner-table">
                                                            <tbody>
                                                                <tr className="border-bottom-0">
                                                                    <th className="fw-bold col-2">Informeren</th>
                                                                    <th className="fw-bold col-2">Extra E-mail adressen</th>
                                                                    <th className="fw-bold col-2">Tekst mail</th>
                                                                    <th className="fw-bold col-2"></th>
                                                                </tr>
                                                                <tr className="border-bottom-0">
                                                                    <td className="wl d-flex align-items-center">
                                                                        {informerenSelected(formDataState)}
                                                                    </td>

                                                                    <td className="wl">
                                                                        {formDataState.informEmail && formDataState.informEmail.length > 0 ? (
                                                                            <span>{formDataState.informEmail.join(', ')}</span>
                                                                        ) : '-'}

                                                                    </td>

                                                                    <td className="wl">
                                                                        <UncontrolledButtonDropdown isOpen={menuShow}>
                                                                            <DropdownToggle color="link" className="p-0">
                                                                                <div className="d-flex align-items-center me-2" onClick={() => menuIsOpen()}>
                                                                                    <EyeIcon />
                                                                                </div>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu className={"rm-pointers p-0 weekstaten-popup-border"}>
                                                                                <Nav className="p-2 weekstaten-popup-width">
                                                                                    <div className="btn-color-style travelCost-blue-text mb-2 ps-2">
                                                                                        Melding
                                                                                    </div>
                                                                                    <div className="travelCost-light-text ps-2">
                                                                                        {formDataState.informText}
                                                                                    </div>
                                                                                </Nav>
                                                                            </DropdownMenu>
                                                                        </UncontrolledButtonDropdown>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" lg="12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-3 table-responsive">
                                        <ReactTableGeneric
                                            data={filteredData}
                                            columns={columns}
                                            tableClassName="mb-0 table table-sm table-hover table-borderless"
                                            filters={{ search: false, pagination: true, sorting: false }}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )
            }
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex justify-content-end">
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn me-2"
                            onClick={handleSubmit}
                            type="button"
                            color="primary"
                        >
                            Verwerken
                        </Button>
                        <Button size="sm" outline className="me-2 btn-color-style footer-btn-styling py-1" color="primary">
                            <Arrow onClick={goToLastStep} />
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </div>
    )
};

export default Step3;

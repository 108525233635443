import React, { useState } from 'react';
import GebruikerMatchcodesService from 'Api/Gebruikers/GebruikersView/Matchcodes';
import ReactTableGeneric from 'Components/ReactTableGeneric'
import { Card, CardBody, Input } from 'reactstrap'

const CommunityTable = ({ data, currentUser, getDataHandler }) => {
    const [matchCodeOption, setMatchCodeOption] = useState("");
    
    const updateDataHandler = async (value, schoolIds) => {
        if(value === -1) return;
        const data = {
            iSchoolIds: String(schoolIds),
            value,
            iAdminId: currentUser.data.clooserId,
        };
        
        try {
            const response = await GebruikerMatchcodesService.saveMatchcodeData(data);
            if (response.data.status) {
                getDataHandler();
            }
        } catch (error) {
            console.error(`API error:`, error);
        }
    };

    const getAllSchoolIds = () => {
        return data?.lists?.map(item => item.id).join(",");
    }

    const columns = React.useMemo(
        () => [
            {
                header: () => <>{data.vCommunityName}</>,
                accessorKey: 'name',
            },
            {
                header: () =>
                    <select
                        value={matchCodeOption}
                        className={`form-select`}
                        onClick={(e) => (e.stopPropagation())}
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            setMatchCodeOption(selectedValue);
                            updateDataHandler(selectedValue, getAllSchoolIds())
                        }}>
                        <option value={"-1"}>Kies</option>
                        {[...Array(21).keys()].map((i) => (
                            <option key={i} value={String(i)}>{i}</option>
                        ))}
                        <option value="99">99</option>
                    </select>,
                accessorKey: 'value',
                cell: (value) => (
                    <div className='d-flex gap-3'>
                        <Input name="link" type="text" defaultValue={value.row.original.mc} onChange={(e) => updateDataHandler(e.target.value, value.row.original.id)} />
                    </div>
                )
            }
        ],
        [data, matchCodeOption]
    );

    return (
        <Card className="main-card" style={{ minHeight: '500px' }}>
            <CardBody className="d-flex flex-column">
                <ReactTableGeneric
                    data={data?.lists || []}
                    columns={columns}
                    tableClassName="mb-0 table table-borderless table-sm table-hover"
                    filters={{ search: false, pagination: false, sorting: false }}
                />
            </CardBody>
        </Card>
    )
}

export default CommunityTable;
import axiosInstance from 'Api';

const getGebruikerDetail = (data) => {
    return axiosInstance.post('gebruiker/get-user-details', data)
};

const updateGebruikerDetail = (data) => {
    return axiosInstance.post('gebruiker/update-details', data)
};

const contractType = (id) => {
    return axiosInstance.get("contracttypes?iCommunityId=" + id);
};

const schoolList = (id, adminId) => {
    return axiosInstance.get(`gebruiker/school-lists?iAdminId=${adminId}&iCommunityId=${id}`);
}

const getCommunityList = (id)=>{
    return axiosInstance.get("gebruiker/communities?iAdminId=" + id);
}

const communityAdminList = (id) => {
    return axiosInstance.get(`gebruiker/community-admin-list?iAdminId=${id}`)
};

const GebruikerViewService = {
    getGebruikerDetail,
    updateGebruikerDetail,
    contractType,
    schoolList,
    getCommunityList,
    communityAdminList
};
export default GebruikerViewService;
import { Table } from "reactstrap";
import moment from "moment";
import React, { useEffect } from "react";
import UserInfoModal from "Components/Modal/UserInfoModal/UserInfoModal";
import {BsCheckCircle} from "react-icons/bs";
import {BsQuestionCircle} from "react-icons/bs";
import {TiDeleteOutline} from "react-icons/ti";
import {HiOutlineDotsCircleHorizontal} from "react-icons/hi";
import classNames from "classnames";
import Tippy from "@tippyjs/react";
import { ReactComponent as Football } from "../../../../../assets/utils/images/svgs/Football.svg";

const SubstitutionTable = ({substitution, subsDateIds, setSubsDateIds,  isDeleted  }) => {
useEffect( () => {
moment.locale('fr');
}, []);

    const selectAll = (checked, dayPart) => {
        const filteredByDayPart = substitution.converted_dayparts.flatMap(e => e.data[dayPart] && e.data[dayPart].iSubstitutorAdminId === 0 ? e.data[dayPart].iSubsDateId : []);

        if (checked) {
            const filteredToSave = filteredByDayPart.filter(id => !subsDateIds.includes(id));
            setSubsDateIds([...subsDateIds, ...filteredToSave])
        } else {
            const filteredToUpdate = subsDateIds.filter(id => !filteredByDayPart.includes(id));
            setSubsDateIds(filteredToUpdate)
        }
    }

    const iconSwitch = (param) => {
        switch(param) {
            case 'Ingepland':
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' font-size={"18px"}/></span></Tippy>;
            case 'Aangevraagd':
                return <Tippy content={param} placement="bottom"><span><BsQuestionCircle color='#F2983E' font-size={"18px"}/></span></Tippy>;
            case 'Geannuleerd':
                return <Tippy content={param} placement="bottom"><span><TiDeleteOutline color='#FB2E5F' font-size={"24px"}/></span></Tippy>;
            default:
              return <Tippy content={'Openstaand'} placement="bottom"><span><HiOutlineDotsCircleHorizontal font-size={"22px"}/></span></Tippy>;
        }
    }

    const selectRow = (data) => {
        const dayParts = ['O', 'M'];
        let onm = [];

        for (let i = 0; i < dayParts.length; i++) {
            onm.push(data[dayParts[i]] && data[dayParts[i]].iSubstitutorAdminId === 0 && data[dayParts[i]].iSubsDateId)
        }

        onm = onm.filter(e => e);
        let notInState = onm.filter(e => !subsDateIds.includes(e));

        if (notInState.length !== 0) {
            setSubsDateIds([...subsDateIds, ...notInState])
        } else {
            const filteredToUpdate = subsDateIds.filter(id => !onm.includes(id));
            setSubsDateIds(filteredToUpdate)
        }
    }

    const toggleCheckbox = (checked, id) => {
        if (checked) {
            setSubsDateIds([...subsDateIds, id])
        } else {
            setSubsDateIds([...subsDateIds.filter(e => e !== id)])
        }
    }
    // add animation when remove data (row)
    const addRemoveClass = (block, dayPart) => classNames({[`delete-rows-${dayPart}`] : isDeleted && subsDateIds.includes(block.data[dayPart].iSubsDateId)});
   
    if (!substitution) return null;
    return (
        <Table hover size="sm" borderless className="mb-0 subs-main-table hidden-row-style">
            <thead>
            <tr>
                <th className="text-lef w-32" colSpan="2">&nbsp;</th>
                <th colSpan="7" className="">Ochtend</th>
                <th className="w-6">&nbsp;</th>
                <th colSpan="7" className="">Middag</th>
            </tr>
            <tr>
                <th className="text-left" colSpan="2">Datum</th>

                <th><input type="checkbox"  onChange={e => selectAll(e.target.checked, "O")}  className=""/></th>
                <th className="text-left">Vervanger</th>
                <th>Gym</th>
                <th>CT</th>
                <th>DT</th>
                <th>WTF</th>
                <th className="text-right text-center">Status</th>

                <th>&nbsp;</th>

                <th><input type="checkbox"  onChange={e => selectAll(e.target.checked, "M")}  className=""/></th>
                <th className="text-left">Vervanger</th>
                <th>Gym</th>
                <th>CT</th>
                <th>DT</th>
                <th>WTF</th>
                <th className="text-right text-center">Status</th>
            </tr>
            </thead>
            <tbody>
            { substitution.converted_dayparts.map((block, i) => {
                    return (
                        <tr key={i}>
                            <td class="text-capitalize">
                                {new Date(moment(block.day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' })}
                            </td>
                            <td className="cursor-pointer" onClick={() => selectRow(block.data)} className="table-right-border cursor-pointer">{new Date(moment(block.day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", {
                                year: '2-digit',
                                month: '2-digit',
                                day: '2-digit'
                            })}</td>
                            {
                                block.data.O ?
                                    <>
                                        <td className={addRemoveClass(block, "O")}>{block.data.O.iSubstitutorAdminId == 0 ?
                                            <input type="checkbox" checked={subsDateIds.includes(block.data.O.iSubsDateId)}
                                                   onChange={e => toggleCheckbox(e.target.checked, block.data.O.iSubsDateId)} /> : "--"} </td>
                                        <td  className={addRemoveClass(block, "O")}>
                                            <div className="overflow-ellipsis ws">
                                                <UserInfoModal
                                                    id={block.data.O.iSubstitutorAdminId}
                                                    name={block.data.O.substitutor_full_name}
                                                    substitutionId={block.data.O.iSubstitutionId}
                                                    />
                                            </div>
                                        </td>
                                        <td  className={addRemoveClass(block, "O")}>{block.data.O.eGym === "+" ? <Football/> : block.data.O.eGym}</td>
                                        <td  className={addRemoveClass(block, "O")}>{block.data.O.iSubstitutorAdminId > 0 ? block.data.O.contract_type : "--"} </td>
                                        <td  className={addRemoveClass(block, "O")}>{block.data.O.iSubstitutorAdminId > 0 ? block.data.O.day_type : "--"} </td>
                                        <td  className={addRemoveClass(block, "O")}>{block.data.O.iSubstitutorAdminId > 0 ? block.data.O.wtf + " " + block.data.O.wtfValue : "--"} </td>
                                        <td  className={"text-center" + addRemoveClass(block, "O")}>{block.data.O.eSubStatus !== undefined && block.data.O.iSubstitutorAdminId > 0 ? iconSwitch(block.data.O.eSubStatus) : <Tippy content={'Openstaand'} placement="bottom"><span><HiOutlineDotsCircleHorizontal font-size={"22px"}/></span></Tippy>} </td>
                                    </>
                                    :
                                    <td colSpan="7" className="text-center"></td>
                            }
                            <td className="table-right-border">&nbsp;</td>
                            {
                                block.data.M ?
                                    <>
                                        <td className={addRemoveClass(block, "M")}>{block.data.M.iSubstitutorAdminId == 0 ?
                                            <input type="checkbox"  checked={subsDateIds.includes(block.data.M.iSubsDateId)}
                                                   onChange={e => toggleCheckbox(e.target.checked, block.data.M.iSubsDateId)} /> : "--"} </td>
                                        <td className={addRemoveClass(block, "M")}>
                                            <div className="overflow-ellipsis ws">
                                                <UserInfoModal
                                                    id={block.data.M.iSubstitutorAdminId}
                                                    name={block.data.M.substitutor_full_name}
                                                    substitutionId={block.data.M.iSubstitutionId}
                                                    classes="feedback-modal-style"
                                                    />
                                            </div>
                                        </td>
                                        <td className={addRemoveClass(block, "M")}>{block.data.M.eGym === "+" ? <Football/> : block.data.M.eGym}</td>
                                        <td className={addRemoveClass(block, "M")}>{block.data.M.iSubstitutorAdminId > 0 ? block.data.M.contract_type : "--"} </td>
                                        <td className={addRemoveClass(block, "M")}>{block.data.M.iSubstitutorAdminId > 0 ? block.data.M.day_type : "--"} </td>
                                        <td className={addRemoveClass(block, "M")}>{block.data.M.iSubstitutorAdminId > 0 ? block.data.M.wtf + " " + block.data.M.wtfValue : "--"} </td>
                                        <td className={"text-center" + addRemoveClass(block, "M")} >{(block.data.M.eSubStatus !== undefined && block.data.M.iSubstitutorAdminId > 0) ? iconSwitch(block.data.M.eSubStatus) : <Tippy content={'Openstaand'} placement="bottom"><span><HiOutlineDotsCircleHorizontal font-size={"22px"}/></span></Tippy>}</td>
                                    </>
                                    :
                                    <td colSpan="7" className="text-center"></td>
                            }
                        </tr>
                    )
                })
            }
            </tbody>
        </Table>
    );
};

export default SubstitutionTable;
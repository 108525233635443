import axiosInstance from "Api";

const travelCostList = (value) => {
    return axiosInstance.get('travelcost-list/' + value);
};

const downloadDocument = (id, type) => {
    return axiosInstance.get('/document/' + id + '/' + type + '/travelcost');
};

const TravelCostService = {
    travelCostList,
    downloadDocument
};
export default TravelCostService;
import React, { Fragment, useState } from "react";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";

const Default = ({ data, setOverviewPage, setProcessData, processData }) => {
    
    const [step, setStep] = useState('step1')
    const [formDataState, setFormDataState] = useState({
        rdApproved: 1,
        selectCoordinator: false, 
        selectSchool: false, 

        selectSalarisAdmin: false, 
        selectCoordinatorAttach: false, 
        selectSchoolAttach: false,
        selectSalarisAdminAttach: false, 

        informText: `Op www.clooser.nl staat de weekstaat van %SCHOOL% %ORGANISATIE% voor de periode van %FROM_DATE% t/m %TO_DATE% voor u klaar.`,
        informEmail: '',
        emailList: [],
        rdSalaris: 0,
        // automatic: null,
    });

    return (
        <Fragment>
            {
                (step === 'step1') ?
                    <Step1 setStep={setStep} formDataState={formDataState} setFormDataState={setFormDataState} setProcessData={setProcessData} setOverviewPage={setOverviewPage} /> :
                    (step === 'step2') ?
                        <Step2 data={data} setStep={setStep} formDataState={formDataState} /> :
                        <Step3 data={data} processData={processData} setStep={setStep} formDataState={formDataState} setProcessData={setProcessData} setOverviewPage={setOverviewPage}/>
            }
        </Fragment>
    )
};

export default Default;

import { Table } from "reactstrap";
import React from "react";
import TextLimit from "Api/TextLimit";

const ContactDetailsTable = ({data}) => {

    return (
        <>
            <Table hover size="sm" borderless className="mb-0">
                <thead>
                    <tr>
                        <th></th>
                        <th>Naam</th>
                        <th>CT</th>
                        <th>E-mail</th>
                        <th>E-mail privé</th>
                        <th>Telefoon zakelijk</th>
                        <th>Telefoon mobiel </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>School</td>
                        <td>{data.schoolName && data.schoolName !== '' ? data.schoolName : '----'}</td>
                        <td>----</td>
                        <td>{data.svVisitoremail && data.svVisitoremail !== '' ? data.svVisitoremail :  '----'}</td>
                        <td>----</td>
                        <td>----</td>
                        <td>{data.svVisitorphone && data.svVisitorphone !== '' ? data.svVisitorphone : '----'}</td>
                    </tr>

                    <tr>
                        <td>Melder</td>
                        <td>
                            {window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(data.firstName, data.middleName, data.lastName)
                                                        :TextLimit.AfwezigeLimitOptionTwo(data.firstName, data.middleName, data.lastName)}</td>
                        <td>----</td>
                        <td>{data.mvBusinessEmail && data.mvBusinessEmail !== '' ? data.mvBusinessEmail : '----'}</td>
                        <td>{data.mvEmail && data.mvEmail !== '' ? data.mvEmail :'----'}</td>
                        <td>{data.miMobileNumber && data.miMobileNumber !== '' ? data.miMobileNumber : '----'}</td>
                        <td>{data.mvPhonePrivate && data.mvPhonePrivate !== '' ? data.mvPhonePrivate : '----'}</td>
                    </tr>

                    {data.vervangerNameList.map((key, i) => {
                        return (
                            <tr>
                                <td>{i === 0 ? 'Vervanger' : ''}</td>
                                <td>
                                {window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(key.vFirstName, key.vMiddleName, key.vLastName)
                                                        :TextLimit.AfwezigeLimitOptionTwo(key.vFirstName, key.vMiddleName, key.vLastName)}

                                </td>
                                <td>{key.CT || "----"}</td>
                                <td>{key.vBusinessEmail !== '' && key.vBusinessEmail !== null ? key.vBusinessEmail : '----'}</td>
                                <td>{key.vEmail !== '' && key.vEmail !== null ? key.vEmail : '----'}</td>
                                <td>{key.vPhoneWork !== '' && key.vPhoneWork !== null ? key.vPhoneWork : '----'}</td>
                                <td>{key.iMobileNumber !== '' && key.iMobileNumber !== null ? key.iMobileNumber : '----'}</td>
                            </tr>
                        )
                    })}

                </tbody>
            </Table>
        </>
    );
};

export default ContactDetailsTable;
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Card, CardBody, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import BootstrapInputGroup from 'Pages/Profile/Forms/BootstrapInputGroup';
import DefaultButton from 'Components/Buttons/DefaultButton';
import axiosInstance from 'Api';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .matches("^\\S*$", "Wachtwoord mag geen spaties hebben")
        .required('Wachtwoord is verplicht')
        .min(8, "Wachtwoord moet minimaal 8 tekens lang zijn")
        .matches("[A-Za-z]+", "Gebruik hoofdletters en kleine letters a-z en A-Z tekens")
        .matches("[0-9]+", "Wachtwoord moet cijfers bevatten")
        .matches("[`!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?~]+", "Wachtwoord moet speciale tekens bevatten !@#$%^&*()_+"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Wachtwoorden komen niet overeen')
        .required('Bevestig uw wachtwoord')
});


const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const resetCode = searchParams.get('reset');
    const time = searchParams.get('time');
    const [isUrlValid, setUrlValid] = useState('');
    const [username, setUserName] = useState('');
    const [message, setMessage] = useState({ type: null, text: '' });
    const [loader, setLoader] = useState(true);
    console.log(isUrlValid, "setUrlValid")
    useEffect(() => {
        axiosInstance.get(`check-validity?reset=${resetCode}&time=${time}`).then(response => {
            if (response.data.status) {
                setUrlValid('');
                setLoader(false);
                setUserName(response.data.vUserName)
            } else if (!response.data.status) {
                setUrlValid(response.data.error_message)
            }
        }).catch(err => {
            if (err) {
                console.error(err)
            }
        });
    }, [resetCode, time]);

    return (
        <>
            <div className="h-100 bg-heavy-rain custom-footerbar bg-animation">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <Col md="8" className="mx-auto app-login-box">
                        <div className="app-logo-inverse mx-auto mb-3" />
                        {!isUrlValid && !loader &&
                            <>
                                <div className="text-center mx-auto my-4">
                                    <strong>Clooser.nl</strong> vereist het gebruik van een sterk wachtwoord.<br />
                                    Uw wachtwoord voldoet aan de minimale gestelde eisen indien er een groen vinkje verschijnt.<br />
                                    Gebruik een combinatie van hoofd- en kleine letters eventueel aangevuld met cijfers en/of leestekens.<br />
                                </div>

                                <Formik
                                    className="mt-5 mx-auto"
                                    initialValues={{ password: '', password_confirmation: '' }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { resetForm }) => {
                                        const data = {
                                            ...values,
                                            time,
                                            resetcode: resetCode
                                        }
                                        setTimeout(() => {
                                            axiosInstance.post('reset-password', data).then(response => {
                                                if (response.data.status) {
                                                    setMessage({ type: 'success', text: 'Wachtwoord succesvol aangepast' })
                                                    resetForm();
                                                    setTimeout(() => navigate('/'), 3000)
                                                } else {
                                                    setMessage({ type: 'fail', text: 'Kan het wachtwoord niet bijwerken' })
                                                }
                                            }).catch(err => {
                                                console.log(err);
                                            });
                                        }, 500)
                                    }}
                                >
                                    {(formik) => (
                                        <Form>
                                            {< Container>
                                                <Row className='justify-content-center mt-5'>
                                                    <Col sm="6">
                                                        <Card className="main-card p-5">
                                                            <CardBody className="d-flex flex-column">
                                                                <Row>
                                                                    <Col sm="6">
                                                                        <FormGroup>
                                                                            <Label for="username">
                                                                                Uw huidige gebruikersnaam is:
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                {username}
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <FormGroup>
                                                                            <Label for="password">
                                                                                Kies een nieuwe wachtwoord
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <FormGroup>
                                                                            <Field name="password">
                                                                                {({ field, form, meta }) => (
                                                                                    <BootstrapInputGroup field={field} meta={meta} />
                                                                                )}
                                                                            </Field>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="6">
                                                                        <FormGroup>
                                                                            <Label for="password_confirmation">
                                                                                Bevestig je nieuwe wachtwoord
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <FormGroup>
                                                                            <Field name="password_confirmation">
                                                                                {({ field, form, meta }) => (
                                                                                    <BootstrapInputGroup field={field} meta={meta} />
                                                                                )}
                                                                            </Field>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={{ size: 6, offset: 6 }} className=" px-2">
                                                                        <DefaultButton type="submit" disabled={formik.isValid ? false : true}
                                                                            className="mb-2 me-0 btn-color-style" color="primary">
                                                                            Wachtwoord opnieuw instellen                                                                </DefaultButton>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>
                                            }
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        }

                        {message.type && (
                            <section>
                                <Container>
                                    <Row className="justify-content-center mt-5">
                                        <Col sm="6">
                                            <div className="text-center">
                                                {message.text && (
                                                    <div
                                                        className={`alert my-4 py-5 ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`}
                                                        role="alert"
                                                    >
                                                        {message.text}
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        )}

                        {isUrlValid && <section>
                            <Container>
                                <Row className='justify-content-center mt-5'>
                                    <Col sm="6">
                                        <div className="text-center">
                                            {isUrlValid && <div class="alert alert-danger my-4 py-5" role="alert">
                                                {isUrlValid}
                                            </div>}

                                            <Link to="/">
                                                Klik hier om uw wachtwoord opnieuw aan te vragen.
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>}

                    </Col>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;

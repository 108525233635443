import React, { Fragment } from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import GeneralSettingsForm from './GeneralSettingsForm';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ToastContainer } from "react-toastify";

const GeneralSettings = () => {

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Card className="main-card mb-3">
                        <GeneralSettingsForm />
                    </Card>
                </CSSTransition>
            </TransitionGroup>
            <ToastContainer style={{ top: "70px" }} />
        </Fragment>
    )
}

export default GeneralSettings;

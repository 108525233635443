import AppFooter from 'Layout/AppFooter'
import PageHeading from 'Layout/AppMain/PageHeading'
import React, { Fragment, useEffect, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Default from "../WeekReportInfo/Partials";
import { useLocation } from 'react-router';
import WeekstatenService from 'Api/Weekstaten';

const WeeklyStatements = () => {
    const [showLoader, setShowLoader] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [weekReportData, setWeekReportData] = useState();
    const location = useLocation();

    const processData = location.state?.data;

    useEffect(() => {
        setShowLoader(true)
        const body = {
            id: processData.iWeekstaatId,
            wid: processData.wid,
            sid: processData.iSchoolId
        }
        WeekstatenService.getWeekstaatData(body).then((response) => {
            if (response.data.status === true) {
                setShowLoader(false)
                setWeekReportData(response.data.data)
            }
        }).catch(() => console.error);
    }, [])

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Fragment>
                        <Default
                            setCurrentIndex={setCurrentIndex}
                            currentIndex={currentIndex}
                            weekReportData={weekReportData}
                            showLoader={showLoader}
                            setWeekReportData={setWeekReportData}
                        />
                    </Fragment>
                </CSSTransition>
            </TransitionGroup>
            <AppFooter />
        </Fragment>
    )
}

export default WeeklyStatements
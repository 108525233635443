import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Card, CardBody, Col, CardFooter, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ReactTableGeneric from "Components/ReactTableGeneric";
import AdminService from "Api/Admin";
import { toast, ToastContainer } from "react-toastify";
import Loader from "react-loaders";

const Sms = () => {
    const [orgLinkList, setOrgLinkList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        organisationList()
    }, []);

    const organisationList = () => {
        setIsLoading(true);
        AdminService.organisationList().then((response) => {
            if (response.status === 200) {
                setOrgLinkList(response.data.data)
            }
            setIsLoading(false);
        }).catch(() => console.error);
    }

    const dummyData = [
        {
            iCommunity: "Bestuur107",
            total_sent: "232",
            total_credits: "2323"
        },
        {
            iCommunity: "Bestuur107",
            total_sent: "232",
            total_credits: "2323"
        },
        {
            iCommunity: "Bestuur107",
            total_sent: "232",
            total_credits: "2323"
        },
        {
            iCommunity: "Bestuur107",
            total_sent: "232",
            total_credits: "2323"
        },
        {
            iCommunity: "Bestuur107",
            total_sent: "232",
            total_credits: "2323"
        },
        {
            iCommunity: "Bestuur107",
            total_sent: "232",
            total_credits: "2323"
        },
    ]

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Bestuur',
                accessorKey: 'iCommunity'
            },
            {
                header: () => 'Totaal aantal SMS verzonden',
                accessorKey: 'total_sent'
            },
            {
                header: () => 'Totaal aantal credits',
                accessorKey: 'total_credits'
            },
        ],
        []
    );

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Row>
                        <Col md="12">
                            <Card className="main-card mb-3">
                                <CardBody className="p-3">
                                    {isLoading ?
                                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> :
                                        <ReactTableGeneric
                                            data={dummyData}
                                            columns={columns}
                                            tableClassName="mb-0 table table-borderless table-sm table-hover tb-links"
                                            filters={{ search: true, pagination: false, sorting: false }}
                                        />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                        <select className="form-select w-auto">
                            <option value='vervanger'>Jaar</option>
                            <option value='vervanger'>2024</option>
                            <option value='melder'>2023</option>
                            <option value='coordinator'>2022</option>
                        </select>
                </CardFooter>
            </div>
            <ToastContainer style={{ top: "70px" }} />

        </Fragment>
    )
}

export default Sms

import { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Label, Row, FormGroup, Form, Input, Table, InputGroup } from "reactstrap";
import "cropperjs/dist/cropper.css";
import { ErrorMessage, Formik } from "formik";
import DatePicker from "react-datepicker";
import { DropdownList } from "react-widgets";
import { ReactComponent as CalenderIcon } from "../../../assets/utils/images/svgs/CalenderIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "Api";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

const UpdateRequestInBulkModel = ({ isOpen, data, bulkFormData, setBulkFormData, setBulkRequestIsOpen, checkedList, setCheckedList, getRequests }) => {

    const params = useParams();
    const substitutionId = params.id || '';
    let navigate = useNavigate();
    const [wtfCheckedList, setWtfCheckedList] = useState({
        MO: '',
        MM: '',
        DO: '',
        DM: '',
        WO: '',
        WM: '',
        TO: '',
        TM: '',
        VO: '',
        VM: '',
    });

    const [wtfValues, setWtfValues] = useState({
        MO: '',
        MM: '',
        DO: '',
        DM: '',
        WO: '',
        WM: '',
        TO: '',
        TM: '',
        VO: '',
        VM: '',
    });

    const toggleInputs = (inputName) => {
        setCheckedList(prevValues => ({
            ...prevValues,
            [inputName]: prevValues[inputName] === 0 ? 1 : 0
        }));

        if (checkedList[inputName] === 0) {
            setWtfCheckedList(prevValues => ({
                ...prevValues,
                [inputName]: prevValues[inputName] = ''
            }));
            setWtfValues(prevValues => ({
                ...prevValues,
                [inputName]: prevValues[inputName] = ''
            }));
        }
        updateItemValue(inputName);
    }

    const toggleWtfInputs = (inputName, val) => {
        setWtfCheckedList(prevValues => ({
            ...prevValues,
            [inputName]: prevValues[inputName] = inputName + '|' + val
        }));
    }

    const updateItemValue = (key) => {
        if (bulkFormData.items && bulkFormData.items.hasOwnProperty(key)) {
            bulkFormData.items[key] = bulkFormData.items[key] === 0 ? 1 : 0;
        } else {
            console.error(`Key "${key}" not found in the 'items' array.`);
        }
    };

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append('id', substitutionId);
        formData.append('start', bulkFormData.start);
        formData.append('end', bulkFormData.end);
        formData.append('substitutor', bulkFormData.substitutor);
        formData.append('current_state', bulkFormData.current_state);
        formData.append('newDayType', bulkFormData.newDayType);
        formData.append('newWtfType', bulkFormData.newWtfType);
        formData.append('newStatus', bulkFormData.newStatus);
        formData.append('newInform', bulkFormData.newInform);
        formData.append('items', Object.keys(bulkFormData.items).filter(key => bulkFormData.items[key] === 0));
        formData.append('wtfItems', Object.values(wtfCheckedList).filter(value => value !== '' && value !== null && value !== undefined));

        axiosInstance
        .post('/verzoeken/update-dagdelen', formData)
        .then((response) => {
            if (response.data.status === true) {
                setBulkRequestIsOpen(false);
                if (response.data.navigate === 'verzoeken_page') {
                    navigate("/verzoeken/overzicht");
                } else if (response.data.navigate === 'details_page') {
                    getRequests();
                    toast["success"]("Wijzigingen zijn succesvol opgeslagen")
                }
            } else {
                setBulkRequestIsOpen(false);
                toast["warning"]("Er konden geen wijzigen worden aangebracht met de bovenstaande selectie wbt de WTF!")
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    return (
        <Modal id={"tooltip"} isOpen={isOpen} toggle={() => setBulkRequestIsOpen(!isOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling">
            <ModalHeader>
                <div className="modal-header-styling" >
                    Dagdelen wijzigen
                </div>
                <div className="cross-icon-color">
                    <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setBulkRequestIsOpen(false)}> </i>
                </div>

            </ModalHeader>

            <ModalBody className="bg-white rounded">

                <Formik
                    // enableReinitialize
                    initialValues={bulkFormData}
                // validationSchema={validationSchema}
                    onSubmit={() => handleSubmit()}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <>
                                <Row>
                                    <Col sm={6}>
                                        <Label className="danglen-modal-styling">
                                            Selecteer de gewenste periode, vervanger en/of dagdelen die u with wijzigen
                                        </Label>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup row>
                                                    <Label sm={4} className="danglen-modal-styling text-align-left">
                                                        Periode
                                                    </Label>
                                                    <Col sm={8}>
                                                        <InputGroup className="periode-input-style">
                                                            <div className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                                            </div>
                                                            <DatePicker
                                                                locale="nl"
                                                                name="date1"
                                                                dateFormat="dd-MM-yy"
                                                                placeholderText={'dd-mm-yy'}
                                                                className="long-contract-modal-style"
                                                                selected={new Date(bulkFormData.start)}
                                                                onChange={(val) => {
                                                                    setBulkFormData({ ...bulkFormData, start: moment(val).format('yyyy-MM-DD') })
                                                                }}
                                                            />

                                                        </InputGroup>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup row>
                                                    <Label sm={4} className="danglen-modal-styling text-align-left">
                                                        t/m
                                                    </Label>
                                                    <Col sm={8}>
                                                        <InputGroup className="periode-input-style">
                                                            <div className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                                            </div>
                                                            <DatePicker
                                                                locale="nl"
                                                                name="date1"
                                                                dateFormat="dd-MM-yy"
                                                                placeholderText={'dd-mm-yy'}
                                                                className="long-contract-modal-style"
                                                                selected={new Date(bulkFormData.end)}
                                                                onChange={(val) => {
                                                                    setBulkFormData({ ...bulkFormData, end: moment(val).format('yyyy-MM-DD')})
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup row>
                                                    <Label sm={2} className="danglen-modal-styling text-align-left">
                                                        Vervanger
                                                    </Label>
                                                    <Col sm={10}>
                                                        <select className="form-select select-arrow-style" 
                                                             onChange={(e) => {
                                                                setBulkFormData({ ...bulkFormData, substitutor: e.target.value})
                                                            }}
                                                        >
                                                            <option value="-1">...</option>
                                                            {
                                                                data.substList.length !== 0 &&
                                                                <option value="all">Alle vervanger(s)</option>
                                                            }
                                                            
                                                            {
                                                                data.substList.map((val) => {
                                                                    return (
                                                                        <option value={val.iAdminId}>{val.name}</option>
                                                                    )
                                                                })    
                                                            }
                                                           
                                                        </select>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup row>
                                                    <Label sm={2} className="danglen-modal-styling text-align-left">
                                                        Met status
                                                    </Label>
                                                    <Col sm={10}>
                                                        <select className="form-select select-arrow-style" 
                                                             onChange={(val) => {
                                                                setBulkFormData({ ...bulkFormData, current_state: val.target.value})
                                                            }}
                                                        >
                                                            {
                                                                data.substList.length != 0 ? 
                                                                <>
                                                                    <option value="-1">...</option>
                                                                    <option value="Openstaand" >Openstaand</option>
                                                                    <option value="Ingepland" >Ingepland</option>
                                                                    <option value="Aangevraagd" >Aangevraagd</option>
                                                                    <option value="Geannuleerd" >Geannuleerd</option>
                                                                </>
                                                                : <option value="Openstaand">Openstaand</option>
                                                            }
                                                           
                                                        </select>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12}>
                                                <Table size="sm" borderless className="mb-0 subs-main-table">
                                                    <thead>
                                                        <tr>
                                                            <th >Dagdelen</th>
                                                            <th ><Input type="checkbox"/>Ochtend</th>
                                                            <th ><Input type="checkbox"/>Middag</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="vervanger-table">
                                                        {bulkFormData.newWtfType !== 'h' &&
                                                            <>
                                                                <tr >
                                                                    <td>
                                                                        Maandag
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {
                                                                            data.dayparts.MO == 0 ? <Input type="checkbox" value="MO" checked={checkedList.MO == 0 ? true : false} 
                                                                                onChange={() => toggleInputs('MO')}
                                                                            /> : '----'
                                                                        }
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {
                                                                            data.dayparts.MM == 0 ? <Input type="checkbox" value="MM" checked={checkedList.MM == 0 ? true : false} 
                                                                                onChange={() => toggleInputs('MM')}
                                                                            /> : '----'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr className="custom-border-top">
                                                                    <td >
                                                                        Dinsdag
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {
                                                                            data.dayparts.DO == 0 ? <Input type="checkbox" value="DO" checked={checkedList.DO == 0 ? true : false}  
                                                                                onChange={() => toggleInputs('DO')}
                                                                            /> : '----'
                                                                        }
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {
                                                                            data.dayparts.DM == 0 ? <Input type="checkbox" value="DM"  checked={checkedList.DM == 0 ? true : false}
                                                                                onChange={() => toggleInputs('DM')}
                                                                            /> : '----'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr className="custom-border-top">
                                                                    <td >
                                                                        Woensdag
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {
                                                                            data.dayparts.WO == 0 ? <Input type="checkbox" value="WO" checked={checkedList.WO == 0 ? true : false} 
                                                                                onChange={() => toggleInputs('WO')}
                                                                            /> : '----'
                                                                        }
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {
                                                                            data.dayparts.WM == 0 ? <Input type="checkbox" value="WM" checked={checkedList.WM == 0 ? true : false} 
                                                                                onChange={() => toggleInputs('WM')}
                                                                            /> : '----'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr className="custom-border-top">
                                                                    <td >
                                                                        Donderdag
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {
                                                                            data.dayparts.TO == 0 ? <Input type="checkbox" value="TO" checked={checkedList.TO == 0 ? true : false} 
                                                                                onChange={() => toggleInputs('TO')}
                                                                            /> : '----'
                                                                        }
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {
                                                                            data.dayparts.TM == 0 ? <Input type="checkbox" value="TM" checked={checkedList.TM == 0 ? true : false}
                                                                                onChange={() => toggleInputs('TM')}
                                                                            /> : '----'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr className="custom-border-top">
                                                                    <td >
                                                                        Vrijdag
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {
                                                                            data.dayparts.VO == 0 ? <Input type="checkbox" checked={checkedList.VO == 0 ? true : false}
                                                                                onChange={() => toggleInputs('VO')}
                                                                            /> : '----'
                                                                        }
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {
                                                                            data.dayparts.VM == 0 ? <Input type="checkbox" checked={checkedList.VM == 0 ? true : false} 
                                                                                onChange={() => toggleInputs('VM')}
                                                                            /> : '----'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        }

                                                        {bulkFormData.newWtfType === 'h' &&

                                                             <>
                                                                <tr >
                                                                <td>
                                                                    Maandag
                                                                </td>
                                                                <td className="vervanger-cell-styler">
                                                                    {
                                                                        data.dayparts.MO == 0 ?
                                                                        <>
                                                                            <Input type="checkbox" checked={checkedList.MO == 0 ? true : false} 
                                                                                onChange={() => toggleInputs('MO')}
                                                                            />
                                                                            <Input type="number" min="0" max="1" step="0.0001" value={wtfValues.MO} readOnly={checkedList.MO == 0 ? false : true}
                                                                                onChange={(e) => {
                                                                                    toggleWtfInputs('MO', e.target.value)
                                                                                    setWtfValues({...wtfValues, MO: e.target.value})
                                                                            }}
                                                                            />
                                                                        </>
                                                                        : '----'
                                                                    }
                                                                </td>
                                                                <td className="vervanger-cell-styler">
                                                                    {
                                                                        data.dayparts.MM == 0 ?
                                                                        <>
                                                                            <Input type="checkbox" checked={checkedList.MM == 0 ? true : false} 
                                                                                onClick={() => toggleInputs('MM')}
                                                                            />
                                                                            <Input type="number" min="0" max="1" step="0.0001" value={wtfValues.MM} readOnly={checkedList.MM == 0 ? false : true}
                                                                               onChange={(e) => {
                                                                                toggleWtfInputs('MM', e.target.value)
                                                                                setWtfValues({...wtfValues, MM: e.target.value})
                                                                            }}
                                                                            />
                                                                        </>
                                                                        : '----'
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="custom-border-top">
                                                                <td >
                                                                    Dinsdag
                                                                </td>
                                                                <td className="vervanger-cell-styler">
                                                                    {
                                                                        data.dayparts.DO == 0 ?
                                                                        <>
                                                                            <Input type="checkbox" checked={checkedList.DO == 0 ? true : false} 
                                                                                    onChange={() => toggleInputs('DO')}
                                                                            />
                                                                            <Input type="number" min="0" max="1" step="0.0001" value={wtfValues.DO} readOnly={checkedList.DO == 0 ? false : true}
                                                                                onChange={(e) => {
                                                                                    toggleWtfInputs('DO', e.target.value)
                                                                                    setWtfValues({...wtfValues, DO: e.target.value})
                                                                            }}
                                                                            />
                                                                        </> : '----'
                                                                    }
                                                                </td>
                                                                <td className="vervanger-cell-styler">
                                                                    {
                                                                        data.dayparts.DM == 0 ?
                                                                        <>
                                                                            <Input type="checkbox" checked={checkedList.DM == 0 ? true : false} 
                                                                                onChange={() => toggleInputs('DM')}
                                                                            />
                                                                            <Input type="number" min="0" max="1" step="0.0001" value={wtfValues.DM} readOnly={checkedList.DM == 0 ? false : true}
                                                                                onChange={(e) => {
                                                                                    toggleWtfInputs('DM', e.target.value)
                                                                                    setWtfValues({...wtfValues, DM: e.target.value})
                                                                                }}
                                                                            />
                                                                        </> : '----'
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="custom-border-top">
                                                                <td >
                                                                    Woensdag
                                                                </td>
                                                                <td className="vervanger-cell-styler">
                                                                    {
                                                                        data.dayparts.WO == 0 ?
                                                                        <>
                                                                            <Input type="checkbox" checked={checkedList.WO == 0 ? true : false} 
                                                                                    onChange={() => toggleInputs('WO')}
                                                                            />
                                                                            <Input type="number" min="0" max="1" step="0.0001" value={wtfValues.WO} readOnly={checkedList.WO == 0 ? false : true}
                                                                                onChange={(e) => {
                                                                                    toggleWtfInputs('WO', e.target.value)
                                                                                    setWtfValues({...wtfValues, WO: e.target.value})
                                                                                }}
                                                                            />
                                                                        </> : '----'
                                                                    }
                                                                </td>
                                                                <td className="vervanger-cell-styler">
                                                                    {
                                                                        data.dayparts.WM == 0 ?
                                                                        <>
                                                                            <Input type="checkbox" checked={checkedList.WM == 0 ? true : false} 
                                                                                onChange={() => toggleInputs('WM')}
                                                                            />
                                                                            <Input type="number" min="0" max="1" step="0.0001" value={wtfValues.WM} readOnly={checkedList.WM == 0 ? false : true}
                                                                                onChange={(e) => {
                                                                                    toggleWtfInputs('WM', e.target.value)
                                                                                    setWtfValues({...wtfValues, WM: e.target.value})
                                                                                }}
                                                                            />
                                                                        </>
                                                                        : '----'
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="custom-border-top">
                                                                <td >
                                                                    Donderdag
                                                                </td>
                                                                <td className="vervanger-cell-styler">
                                                                    {
                                                                        data.dayparts.TO == 0 ? 
                                                                        <>
                                                                            <Input type="checkbox" checked={checkedList.TO == 0 ? true : false} 
                                                                                        onChange={() => toggleInputs('TO')}
                                                                            />
                                                                            <Input type="number" min="0" max="1" step="0.0001"  value={wtfValues.TO} readOnly={checkedList.TO == 0 ? false : true}
                                                                                onChange={(e) => {
                                                                                    toggleWtfInputs('TO', e.target.value)
                                                                                    setWtfValues({...wtfValues, TO: e.target.value})
                                                                                }}
                                                                            />
                                                                        </>
                                                                        : '----'
                                                                    }
                                                                </td>
                                                                <td className="vervanger-cell-styler">
                                                                    {
                                                                        data.dayparts.TM == 0 ? 
                                                                        <>
                                                                            <Input type="checkbox" checked={checkedList.TM == 0 ? true : false} 
                                                                                onChange={() => toggleInputs('TM')}
                                                                            />
                                                                            <Input type="number" min="0" max="1" step="0.0001" value={wtfValues.TM} readOnly={checkedList.TM == 0 ? false : true}
                                                                                onChange={(e) => {
                                                                                    toggleWtfInputs('TM', e.target.value)
                                                                                    setWtfValues({...wtfValues, TM: e.target.value})
                                                                                }}
                                                                            />
                                                                        </>
                                                                        : '----'
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="custom-border-top">
                                                                <td >
                                                                    Vrijdag
                                                                </td>
                                                                <td className="vervanger-cell-styler">
                                                                    {
                                                                        data.dayparts.VO == 0 ? 
                                                                        <>
                                                                            <Input type="checkbox" checked={checkedList.VO == 0 ? true : false} 
                                                                                    onChange={() => toggleInputs('VO')}
                                                                            />
                                                                            <Input type="number" min="0" max="1" step="0.0001" value={wtfValues.VO} readOnly={checkedList.VO == 0 ? false : true}
                                                                               onChange={(e) => {
                                                                                toggleWtfInputs('VO', e.target.value)
                                                                                setWtfValues({...wtfValues, VO: e.target.value})
                                                                            }}
                                                                            />
                                                                        </>
                                                                         : '----'
                                                                    }
                                                                </td>
                                                                <td className="vervanger-cell-styler">
                                                                    {
                                                                        data.dayparts.VM == 0 ?
                                                                        <> 
                                                                            <Input type="checkbox" checked={checkedList.VM == 0 ? true : false} 
                                                                                onChange={() => toggleInputs('VM')}
                                                                            /> 
                                                                            <Input type="number" min="0" max="1" step="0.0001" value={wtfValues.VM}  readOnly={checkedList.VM == 0 ? false : true}
                                                                                onChange={(e) => {
                                                                                    toggleWtfInputs('VM', e.target.value)
                                                                                    setWtfValues({...wtfValues, VM: e.target.value})
                                                                                }}
                                                                            />
                                                                        </> : '----'
                                                                        
                                                                    }
                                                                </td>
                                                            </tr>
                                                         </>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Label className="danglen-modal-styling text-align-left" style={{ paddingTop: "20px" }}>
                                            Welke gegevens wilt u aanpassen?
                                        </Label>

                                        { data.substList.length !== 0 &&
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup row>
                                                            <Label sm={2} className="danglen-modal-styling text-align-left">
                                                                Dagtype
                                                            </Label>
                                                            <Col sm={10}>
                                                                
                                                                <select className="form-select select-arrow-style"
                                                                    onChange={(val) => {
                                                                        setBulkFormData({ ...bulkFormData, newDayType: val.target.value})
                                                                    }}
                                                                >
                                                                    <option value="-1">...</option>
                                                                    {
                                                                        data.dayTypes.map((val) => {

                                                                            return (
                                                                                <option value={val.id}>{val.abbr}</option>
                                                    
                                                                            )
                                                                        })
                                                                        
                                                                    }
                                                                </select>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                        }

                                        { data.substList.length !== 0 &&
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup row>
                                                        <Label sm={2} className="danglen-modal-styling text-align-left">
                                                            Wtf
                                                        </Label>
                                                        <Col sm={10}>
                                                            
                                                            <select className="form-select select-arrow-style"
                                                                onChange={(val) => {
                                                                    setBulkFormData({ ...bulkFormData, newWtfType: val.target.value})
                                                                }}
                                                            >
                                                                <option value="-1">...</option>
                                                                <option value="k">Kort</option>
                                                                <option value="l">Lang</option>
                                                                <option value="p">Parttimer</option>
                                                                <option value="h">Handmatig</option>
                                                            </select>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        }
                                      

                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup row>
                                                    <Label sm={2} className="danglen-modal-styling text-align-left">
                                                        Status
                                                    </Label>
                                                    <Col sm={10}>
                                                        
                                                        <select className="form-select select-arrow-style"
                                                            onChange={(val) => {
                                                                setBulkFormData({ ...bulkFormData, newStatus: val.target.value})
                                                            }}
                                                        >
                                                            {
                                                                data.substList.length != 0 ? 
                                                                <>
                                                                    <option value="-1">...</option>
                                                                    <option value="Ingepland">Ingepland</option>
                                                                    <option value="Aangevraagd">Aangevraagd</option>
                                                                    <option value="Geannuleerd">Geannuleerd</option>
                                                                </>
                                                                : ''
                                                            }
                                                            <option value="Verwijderen">Verwijderen</option>
                                                        </select>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="request-day-parts">
                                            <Row>
                                                <Col sm={12}>
                                                    <div className="modal-blue-header" style={{paddingBottom:"10px"}}>
                                                        Overzicht verzoek
                                                    </div>
                                                    <Table size="sm" borderless className="mb-0 subs-main-table table-background hidden-row-style homedetail-page-style">
                                                        <thead>
                                                            <tr>
                                                                <th>Dag</th>
                                                                <th>Dagdeel</th>
                                                                <th>Datum</th>
                                                                <th>Vervanger</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="vervanger-table">
                                                            {
                                                                data.reqOverViewList.map(key => (
                                                                    <tr >
                                                                    <td>
                                                                        {key.dd}
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {key.v}
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {key.d}
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {key.n}
                                                                    </td>
                                                                    <td className="vervanger-cell-styler">
                                                                        {key.s}
                                                                    </td>
                                                                </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="request-modal-footer">
                                    <Button className="d-flex align-items-center btn-color-style  opslaan-btn" size="sm" outline color="primary" type="submit">
                                        Doorgaan
                                    </Button>

                                    <Button className="d-flex align-items-center btn-color-style  opslaan-btn" size="sm" outline color="primary" onClick={() =>  setBulkRequestIsOpen(false)}>
                                        Annuleren
                                    </Button>
                                </div>
                            </>
                        </Form>
                    )}
                </Formik>
            </ModalBody>

        </Modal>
    );
}

export default UpdateRequestInBulkModel;

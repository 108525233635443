import React, { useEffect,useState} from "react";
import { useFormikContext } from "formik";
import DropdownList from "react-widgets/DropdownList";
import axiosInstance from "Api";
import ExportService from "Api/Export";
import {ErrorMessage} from "formik";
import InputWrapper from "Pages/Profile/Forms/InputWrapper";

import {
  Col,
  FormGroup,
  InputGroup,
  Label,
  Row,
  Input,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Form, Formik } from "formik";
import DefaultButton from "Components/Buttons/DefaultButton";
import { ReactComponent as PdfIcon } from "../../../assets/utils/images/svgs/PDF.svg";
import * as Yup from "yup";


const DefaultForm = ({ saveDocTypeValue, doc }) => {

    const [docTypeList, setDocTypeList] = useState([]);

    const details = {
        docType: '',
        comType: '',
        omschrijving: '',
        gelding: '',
        herinneringDatum: '',
        file: ''
    };

    const handleSubmit = (values, setSubmitting) => {
        console.log('submit');
        console.log(values);
        // setTimeout(() => {
        // console.log(values);
        // let data;
        // data = {
        //     iMaxTravelDistance : values.iMaxTravelDistance,
        //     reminderSetting : values.reminderSetting,
        //     memo : values.memo,
        //     eGym : values.eGym,
        // };
        // axiosInstance.post('settings/update', data).then((response) => {
        //     if (response.status == 200) {
        //             toast["success"]("Instellingen zijn bijgewerkt");
        //     }
        // }).catch((error) => console.log("catch error:", error));

        //     setSubmitting(false);
        //  }, 400);
    };

    useEffect(() => {
        axiosInstance.get('doc/types').then((response) => {
            if (response.status == 200) {
                if (response.data.status === true) {
                    setDocTypeList(response.data.data);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }, []);
   
    
    return (
        <>
               <Formik
                        enableReinitialize
                        initialValues={details}
                        onSubmit={
                            (values, { setSubmitting } ) => handleSubmit(values, setSubmitting)}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div>
                                        <Row>
                                            <Col md="12" sm="12" lg="12">

                                                <FormGroup row >
                                                    <Label className={"setting-lebel-style text-start"}>
                                                        Geselecteerd bestand
                                                    </Label>
                                                    <Col sm={12} className="setting-lebel-style text-start">
                                                        <PdfIcon /> {doc !== '' && doc.name !== undefined ? doc.name : ''}
                                                    </Col>
                                                </FormGroup>

                                                <div className="custom-styling  mb-3">

                                                    <InputWrapper label="Document type *" labelCol={3} className={'setting-lebel-style text-start'}>
                                                        <DropdownList dataKey='vName' textField='vLabel'
                                                            placeholder="Selecteer"
                                                            className={"border rounded  text-start"}
                                                            onChange={(nextValue) => {
                                                                saveDocTypeValue(nextValue.iDocTypeId, nextValue.vName)
                                                            }}
                                                            name="docType"
                                                            data={docTypeList}
                                                            required="true"
                                                        />
                                                    </InputWrapper>
                                                    <ErrorMessage name="docType" component="small"
                                                            className="block mt-2 invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Button className="d-flex align-items-center btn-color-style mb-2 me-2 float-end" size="sm" outline color="primary" type="submit" disabled={isSubmitting} >
                                            Opslaan
                                        </Button> */}
                                    </div>
                                </Form>

                            )}
                        </Formik>
              
            </>
    )
}

export default DefaultForm;

import { useNavigate } from 'react-router'
import { Button, CardFooter } from 'reactstrap'
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import { ReactComponent as AddIcon } from "../../../../../assets/utils/images/svgs/addBlue.svg"
import DocTable from './DocTable';
import SubstituteTable from './SubstituteTable';
import { userTabs } from '../utils';


const Step3 = ({setActiveTab}) => {
    const navigate = useNavigate();
    return (
        <>
            <div className='row documenten'>
                <div className='col-12'>
                    <DocTable/>
                </div>
                <div className='col-12'>
                    <SubstituteTable/>
                </div>
            </div>
            <div className="chat-layout-footer">
                <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                    <div className="d-flex gap-3">
                        <select className={`form-select`}>
                            <option value={''}>Demo 1 Sevenum</option>
                            <option value='vervanger'>Vervangers</option>
                            <option value='melder'>Melders</option>
                            <option value='coordinator'>Coördinatoren</option>
                        </select>
                        <Button
                            outline
                            className="btn-outline-primary-blue d-flex justify-content-center align-items-center gap-2"

                            type="button"
                            color="primary"
                        >
                            Document <AddIcon />
                        </Button>
                        <Button
                            outline
                            className="btn-outline-primary-blue ms-auto"
                            onClick={() => {
                                setActiveTab(userTabs.Instellingen)
                            }}
                            type="button"
                            color="primary"
                        >
                            <BackIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </>
    )
}

export default Step3
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { ReactComponent as Collapse } from "../../../../assets/utils/images/svgs/subtract.svg";
import { ReactComponent as Expand } from "../../../../assets/utils/images/svgs/Add.svg";
import GebruikersService from 'Api/Gebruikers';
import Loader from 'react-loaders';
import { RenderCommunityRow } from './utils';
import { useNavigate } from 'react-router';

const ExpandableTable = ({ filterData, search }) => {
    const navigate = useNavigate();
    const [expandedRows, setExpandedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sorting, setSorting] = useState({
        sortBy: '',
        sort: '',
    });
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [adminData, setAdminData] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timerId = setTimeout(() => {
            adminList();
        }, 1000)
        setExpandedRows([]);
        return () => {
            clearTimeout(timerId)
        }
    }, [filterData, currentPage, search, rowsPerPage, sorting]);

    useEffect(() => {
        setCurrentPage(1)
    }, [search])

    const adminList = () => {
        const data = {
            iCommunityId: filterData.community,
            userType: filterData.user,
            contractType: filterData.contractType,
            pageSize: rowsPerPage,
            search,
            page: currentPage,
            sortBy: sorting.sortBy,
            sort: sorting.sort
        }
        setIsLoading(true);
        GebruikersService.adminList(data).then((response) => {
            if (response.status === 200) {
                setIsLoading(false);
                setAdminData(response.data.data.list);
                setTotalPages(response.data.data.pageCount);
            }
        }).catch(() => console.error);
    }

    const handleRowClick = (id) => {
        setExpandedRows((prevState) => {
            return prevState.map(item => {
                if (item.clooserId === id) {
                    return { ...item, isRowExpended: !item.isRowExpended };
                } else {
                    return item;
                }
            });
        });
    };

    const sortingHandler = (sortBy) => {
        setSorting(prev => {
            if (prev.sortBy === sortBy) {
                if (prev.sort === 'desc') {
                    return { sortBy: '', sort: '' }
                }
                else if (prev.sort === 'asc') {
                    return { sortBy, sort: 'desc' }
                } else {
                    return { sortBy, sort: 'asc' }
                }
            } else {
                return {
                    sortBy,
                    sort: 'asc'
                }
            }
        })
    }

    useEffect(() => {
        if (adminData.length === 0) return;
        adminData.forEach((item) => {
            if (item.communityList.length > 1) {
                setExpandedRows((prev) => {
                    return [...prev, { clooserId: item.clooserId, isRowExpended: false }]
                })
            }
        });
    }, [adminData]);

    const isrowCollapsed = (id) => {
        const row = expandedRows.find(item => item.clooserId === id);
        return row ? row.isRowExpended : undefined;
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleFirstPage = () => setCurrentPage(1);

    const handleLastPage = () => setCurrentPage(totalPages);

    return (
        <>
            <Table responsive hover className='mb-0 tb-gebruikers'>
                <thead>
                    <tr>
                        <th className='cursor-pointer' onClick={() => sortingHandler('fullname')}>Naam
                            {sorting.sortBy === 'fullname' && <span >{
                                sorting.sort === 'asc' ? '🔼' : sorting.sort === 'desc' ? '🔽' : ''
                            }</span>}
                        </th>
                        <th className='cursor-pointer' onClick={() => sortingHandler('vCity')}>Plaats
                            {sorting.sortBy === 'vCity' && <span>{
                                sorting.sort === 'asc' ? '🔼' : sorting.sort === 'desc' ? '🔽' : ''
                            }</span>}
                        </th>
                        <th className='cursor-pointer' onClick={() => sortingHandler('iAdminId')}>Clooser-id
                            {sorting.sortBy === 'iAdminId' && <span>{
                                sorting.sort === 'asc' ? '🔼' : sorting.sort === 'desc' ? '🔽' : ''
                            }</span>}
                        </th>
                        <th className='text-center'>Bestuur</th>
                        <th className='text-center'>Coördinator</th>
                        <th className='text-center'>Melder</th>
                        <th className='text-center'>Vervanger</th>
                        <th className='text-center'>Salaris-id</th>
                        <th className='text-center'>WTF</th>
                        <th className='text-center'>Contracttype</th>
                        <th className='text-center'>VOG</th>
                        <th className='text-center'>ID</th>
                        <th className='text-center'>Kr</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading && <tr className='mx-auto'>
                        <td colSpan={13}>
                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                        </td>
                    </tr>}
                    {!isLoading && (
                        adminData?.length === 0 ? <>
                            <tr className='mx-auto'>
                                <td colSpan={13} align='center'>
                                    Geen data beschikbaar
                                </td>
                            </tr>
                        </> : (adminData.map((item, index) => (
                            <>
                                <tr key={`${item.clooserId}-${index}`}>
                                    <td className='cursor-pointer' onClick={() => {
                                        navigate(`/gebruikers/${item.clooserId}`, { state: { data: item } });
                                    }}>{item.name}</td>
                                    <td className='cursor-pointer'
                                        onClick={() => {
                                            navigate(`/gebruikers/${item.clooserId}`, { state: { data: item } });
                                        }}>{item.plaats || '-'}</td>
                                    <td className='cursor-pointer'
                                        onClick={() => {
                                            navigate(`/gebruikers/${item.clooserId}`, { state: { data: item } });
                                        }}>{item.clooserId}</td>
                                    {item.communityList.length === 1 &&
                                        <>
                                            <RenderCommunityRow community={item.communityList[0]} />
                                        </>}
                                    {item.communityList.length > 1 &&
                                        <>
                                            <td colSpan={10}>
                                                <button className="btn px-4 py-0 ms-2" onClick={() => handleRowClick(item.clooserId)}>
                                                    {!isrowCollapsed(item.clooserId) ? <Expand /> : <Collapse />}
                                                </button>
                                            </td>
                                        </>
                                    }
                                </tr>
                                {isrowCollapsed(item.clooserId) &&
                                    item.communityList.map((community, idx) => (
                                        <>
                                            <tr key={`${community.iCommunityId}-${idx}`}>
                                                <td colSpan={3}></td>
                                                <RenderCommunityRow
                                                    community={community} />
                                            </tr>
                                        </>
                                    ))}
                            </>
                        )))
                    )}
                </tbody>
            </Table>

            {/* Pagination */}
            <nav className="pagination-wrapper border-top-0">
                <span className="pagination__number pnm-4">Rijen per pagina:</span>
                <div className="pagination__rows">
                    <select
                        aria-label="Rijen per pagina"
                        className="pagination__rows-num"
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                    >
                        {[20, 40, 60, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Toon {pageSize}
                            </option>
                        ))}
                    </select>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M7 10l5 5 5-5z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                </div>
                <span className="pagination__number pnm-24">
                    {currentPage} van {totalPages || 0}
                </span>
                <div className="pagination__btns">
                    <button
                        id="pagination__first-page"
                        className="pagination__page"
                        type="button"
                        aria-label="first page"
                        onClick={handleFirstPage}
                        disabled={isLoading || currentPage === 1}
                        aria-disabled="true"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" viewBox="0 0 24 24" role="presentation">
                            <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                            <path d="M24 24H0V0h24v24z" fill="none"></path>
                        </svg>
                    </button>

                    <button
                        id="pagination__prev-page"
                        onClick={handlePrevPage}
                        disabled={isLoading || currentPage === 1}
                        className="pagination__page"
                        type="button"
                        aria-label="previous page"
                        aria-disabled="true"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" viewBox="0 0 24 24" role="presentation">
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                    </button>

                    <button
                        id="pagination__next-page"
                        className="pagination__page"
                        type="button"
                        onClick={handleNextPage}
                        disabled={isLoading || currentPage >= totalPages}
                        aria-label="next page"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" viewBox="0 0 24 24" role="presentation">
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                    </button>

                    <button
                        id="pagination__last-page"
                        className="pagination__page"
                        type="button"
                        onClick={handleLastPage}
                        disabled={isLoading || currentPage >= totalPages}
                        aria-label="last page"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" viewBox="0 0 24 24" role="presentation">
                            <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                    </button>
                </div>
            </nav>
        </>

    );
};

export default ExpandableTable;

import React, { Fragment, useState } from "react";
import { Card, CardBody } from "reactstrap";
import DefaultButton from "Components/Buttons/DefaultButton";
import { resultIcon } from "Pages/Melder/WeekReport/utils";
import { useLocation, useNavigate } from "react-router";
import axiosInstance from "Api";

const WeekReportForm = ({ reportData }) => {
    const [selectValue, setSelectValue] = useState('');
    const [text, setText] = useState(null);
    const [textError, setTextError] = useState('');
    let navigate = useNavigate();
    let { state } = useLocation();

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!selectValue) {
            setTextError('Selecteer een van de opties.')
            return;
        }
        if (selectValue === '2' && !text) {
            setTextError("Het veld 'Weigerbericht' is verplicht.");
            return;
        }
        setTextError(false);
        const msg = {
            declineMessage: text,
            accepted: Number(selectValue)
        }
        axiosInstance.patch('weekstaat-schools/' + state.wsId, msg)
            .then((response) => {
                navigate("/weekstaat");
            })
            .catch(error => {
                if (error.status === 422) {
                    console.error(error.data.message);
                } console.error(error);
            });
    }

    if (!reportData) return null;
    return (
        <Fragment>
            <Card className="main-card mb-3">
                <CardBody className="p-3 weekstat-data">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <h6 className="text-blue mb-4">Weekstaat beoordelen</h6>
                        <div className="row">
                            <div className="col-6 mb-3 fw-b">Status weekstaat</div>
                            <div className="col-6 mb-3">{reportData.status ? resultIcon(reportData.status) : "--"}</div>

                            {reportData.status === "Openstaand" && <>
                                <div className="col-6 mb-3 fw-b">Beoordelen</div>
                                <div className="col-6 d-flex justify-content-end selectBy mb-3">
                                    <select className="form-select" style={{ width: '160px' }} onChange={(event) => { setSelectValue(event.target.value); setTextError('') }}>
                                        <option value='' selected>Selecteer keuze</option>
                                        <option value='1'>Goedkeuren</option>
                                        <option value='2'>Afkeuren</option>
                                    </select>
                                </div>
                            </>}

                            {selectValue === '2' && <div className="">

                                <p className="mb-1">Geef toelichting op reden van afkeur</p>
                                <textarea
                                    value={text}
                                    onChange={(e) => { setText(e.target.value); setTextError('') }}
                                    rows="4"
                                    className="w-100"
                                ></textarea>
                            </div>}
                        </div>
                        {reportData.status !== "Openstaand" && <div className="row res">
                            <div className="col-6 mb-3">Beoordeeld door</div>
                            <div className="col-6 mb-3 fw-b">{reportData?.acceptedBy}</div>
                            <div className="col-6 mb-3">Datum beoordeling</div>
                            <div className="col-6 mb-5 fw-b">{reportData?.acceptedDate || '--'}</div>

                            <div className="col-12 mb-3 fw-b">Afgemeld</div>
                            <div className="col-6 mb-3">Afgemeld door</div>
                            <div className="col-6 mb-3 fw-b">{reportData?.cloosedBy}</div>
                            <div className="col-6 mb-3">Datum afmelding</div>
                            <div className="col-6 mb-4 fw-b">{reportData?.cloosedDate || '---'}</div>
                        </div>}
                        
                        {reportData.status === "Afgekeurd" && <div className="row ">
                            <div className="col-12 fw-b">Memotext</div>
                            <div className="col-12">{reportData?.note}</div>
                        </div>}

                        {textError &&
                            <p className="text-danger">{textError}</p>
                        }
                        {reportData.status === "Openstaand" && <div className="text-center mt-3">
                            <DefaultButton className="btn-color-style" type="submit">Toepassen</DefaultButton>
                        </div>}

                    </form>
                </CardBody>
            </Card>

        </Fragment>
    )
};

export default WeekReportForm;

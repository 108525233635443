import axiosInstance from 'Api';

const updateGroupStatus = (data) => {
    return axiosInstance.post("gebruiker/group/update", data);
};

const getFunctiesData = (data, iAdminId) => {
    return axiosInstance.post(`gebruiker/functies?iAdminId=${iAdminId}`, data)
};

const GebruikerFunctiesService = {
    updateGroupStatus,
    getFunctiesData
};
export default GebruikerFunctiesService;
import React, { useMemo } from "react";
import ReactTableGeneric from "Components/ReactTableGeneric";
import { Card, CardBody } from "reactstrap";
import { ReactComponent as PDF_disabled } from "../../../../assets/utils/images/svgs/PDF_disabled.svg";
import { ReactComponent as Document } from "../../../../assets/utils/images/svgs/Document.svg";
import { ReactComponent as AfasSoftware } from "../../../../assets/utils/images/svgs/AfasSoftware.svg";
import TextLimit from "Api/TextLimit";
import TravelCostService from "Api/TravelCost";

const Records = ({ data }) => {

    const downloadDocument = (id, type) => {
        TravelCostService.downloadDocument(id, type).then((response) => {
            if (response.data.file.download !== '') {
                const linkSource = response.data.file.download;
                const downloadLink = document.createElement("a");
                var fileName;
                if (response.data.file.extension == "afas.csv") {
                    fileName = "travelcost_" + id + response.data.file.extension;
                }
                else {
                    fileName = "travelcost_" + id;
                }
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        }).catch(() => console.error);
    }

    const columns = useMemo(()=>[
        {
            header: () => 'Organisaties',
            accessorKey: 'vFirstName',
            cell: value => (
                <div>
                    {value.row.original.organization}
                </div>
            )
        },
        {
            header: () => 'Periode',
            accessorKey: 'periode',
            cell: (value) => (
                <div>
                    {value.row.original.periode ? (value.row.original.periode) : 
                    (value.row.original.startDate + " tot " + value.row.original.endDate)}
                </div>
            )
        },
        {
            header: () => 'Aanmaakdatum',
            accessorKey: 'created_at',
            cell: (value) => (
                <div>
                    {value.row.original.created_at}
                </div>
            )
        },
        {
            header: () => 'Coördinator',
            accessorKey: 'name',
            cell: (value) => (
                <div>
                    {window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(value.row.original.first_name, value.row.original.middle_name, value.row.original.last_name)
                        : TextLimit.AfwezigeLimitOptionTwo(value.row.original.first_name, value.row.original.middle_name, value.row.original.last_name)}
                </div>
            )
        },
        {
            header: () => 'Download',
            accessorKey: 'download',
            cell: (value) => (
                <div>
                    <a href="javascript:void(0)" onClick={() => downloadDocument(value.row.original.id, 'pdf')}><PDF_disabled /></a>
                    <a href="javascript:void(0)" onClick={() => downloadDocument(value.row.original.id, 'csv')}><Document className="mx-4" /></a>
                    <a href="javascript:void(0)" onClick={() => downloadDocument(value.row.original.id, 'afas.csv')}><AfasSoftware /></a>
                </div>
            )
        }
    ], [])
    
    return (
        <>
            <Card className="main-card mb-3">
                <CardBody className="p-3 table-responsive">
                    <ReactTableGeneric data={data} columns={columns} tableClassName="mb-0 table table-sm table-hover table-borderless"/>
                </CardBody>
            </Card>
        </>
    );
};
export default Records;
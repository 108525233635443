import PageHeading from "Layout/AppMain/PageHeading";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsCheckCircle, BsQuestionCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { Col, Row, Table, CardFooter, Button, UncontrolledButtonDropdown, DropdownMenu, DropdownToggle, Nav, NavLink, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Card, CardBody, ModalFooter } from "reactstrap";
import Loader from "react-loaders";
import { ReactComponent as Memo } from "../../../../../assets/utils/images/svgs/Memo.svg";
import { ReactComponent as EditPen } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { ReactComponent as Delete } from "../../../../../assets/utils/images/svgs/Delete.svg";
import { ReactComponent as Save } from "../../../../../assets/utils/images/svgs/save_two.svg";
import { ReactComponent as Small_Arrow } from "../../../../../assets/utils/images/svgs/Small_Arrow.svg";
import TextLimit from "Api/TextLimit";
import { useNavigate } from "react-router-dom";

const Calculate = ({ formDataState, setFormDataState, currentIndex, setCurrentIndex, getSubstitutorShifts, shiftListLoading, substitutorssData, setShiftListLoading, shiftsData, setShiftsData, setSubstitutorssData, saveTravelcostData }) => {
    const [menuShow, setMenuShow] = useState(false);
    const [memoIsOpen, setMemoIsOpen] = useState(false);
    const [distanceTotal, setDistanceTotal] = useState(0);
    const [priceTotal, setPriceTotal] = useState(0);
    const [lastIndex, setLastIndex] = useState(false)
    const [messageSalaris, setMessageSalaris] = useState("");
    const [messageVervanger, setMessageVervanger] = useState("");
    const [showUser, setShowUser] = useState({ message: '', type: null });
    let navigate = useNavigate();

    useEffect(() => {
        calculateTotalDistance();
    }, [shiftsData, memoIsOpen]);

    const calculateTotalDistance = () => {
        const totalDistance = shiftsData.reduce((total, shift) => {
            return Number(total) + Number(shift.iDistance);
        }, 0);
        const totalPrice = substitutorssData && substitutorssData.reduce((total, item) => {
            if (item.iSubstitutorAdminId == formDataState.selectedIds[currentIndex]) {
                return total + item.shifts.reduce((shiftTotal, shift) => {
                    return shiftTotal + Number(shift.Vergoeding);
                }, 0);
            }
            return total;
        }, 0);
        totalPrice ? setPriceTotal(totalPrice) :
            setPriceTotal(formDataState.substitutorShiftData.subTotal)
        setDistanceTotal(totalDistance);
    };

    const menuIsOpen = () => {
        setFormDataState({ ...formDataState, editable: null })
        if (menuShow === false) {
            setMenuShow(true);
        } else {
            setMenuShow(false);
        }
    }
    const iconSwitch = (param) => {
        switch (param) {
            case 'Ingepland':
                return <BsCheckCircle color='#39A825' fontSize={"18px"} />;
            case 'Aangevraagd':
                return <BsQuestionCircle color='#F2983E' font-size={"18px"} />;
            case 'Geannuleerd':
                return <TiDeleteOutline color='#FB2E5F' font-size={"24px"} />;
            default:
                return <span>----</span>;
        }
    }

    const updateSubstitutors = () => {
        setSubstitutorssData(prevState => {
            return prevState.map(item => {
                if (item.iSubstitutorAdminId === formDataState.selectedIds[currentIndex]) {
                    return {
                        ...item,
                        iDistanceTotal: distanceTotal,
                        subtotal: parseFloat(Number(priceTotal).toFixed(2)),
                        total: Number((Number(priceTotal) + Number(formDataState.vCorrect)).toFixed(2)),
                        vCorrect: Number(formDataState.vCorrect),
                        messageVervanger: messageVervanger,
                        messageSalaris: messageSalaris
                    };
                }
                return item;
            });
        });
        formDataState.idsToSend.push(formDataState.selectedIds[currentIndex]);
    }

    const skipSubstitutorId = () => {
        let filteredSubstitutorArray = substitutorssData.filter(item => (item.iSubstitutorAdminId !== formDataState.selectedIds[currentIndex]))
        setSubstitutorssData(filteredSubstitutorArray);
    }

    useEffect(() => {
        if (lastIndex === true) {
            saveTravelcostData();
        }
    }, [lastIndex])

    const handleButtonClick = () => {
        setShiftListLoading(true)
        if (currentIndex == formDataState.selectedIds.length - 1) {
            if (formDataState.idsToSend.length == 0) {
                navigate("/reiskosten/overzicht")
            }
            else {
                setLastIndex(true)
            }
            setShiftListLoading(false)
        }
        else {
            setPriceTotal('')
            setMessageSalaris('')
            setMessageVervanger('')
            const nextIndex = (currentIndex + 1) % formDataState.selectedIds.length;
            setCurrentIndex(nextIndex);
            getSubstitutorShifts(nextIndex)
        }
    };

    const getValueBySubsDateId = (targetSubsDateId) => {
        const foundItem = shiftsData.find(item => item.iSubsDateId == targetSubsDateId);
        return foundItem ? foundItem.iDistance : null;
    };

    const updateValueBySubsDateId = (day, targetSubsDateId, newValue) => {

        var updatedData;
        updatedData = shiftsData.map(item =>
            item.iSubsDateId == targetSubsDateId
                ? { ...item, iDistance: newValue }
                : item
        );
        setShiftsData(updatedData);
        setSubstitutorssData(prevState => {
            return prevState.map(item => {
                if (item.shifts) {
                    return {
                        ...item,
                        shifts: item.shifts.map(shift => {
                            if (shift.iSubsDateId === targetSubsDateId) {
                                return {
                                    ...shift,
                                    iDistance: parseFloat(newValue)
                                };
                            }
                            return shift;
                        })
                    };
                }
                return item;
            });
        });
        countVergoeding(targetSubsDateId, day, newValue, updatedData)
    };

    const countVergoeding = (targetSubsDateId, day, newValue, updatedData) => {
        var iMinusDistance = Number(formDataState.vKm);
        var iMaxBedrag = Number(String(formDataState.vBedrag).replace(',', '.'));

        const foundItem = updatedData.find(item => {
            if (item.iSubsDateId == targetSubsDateId) {
                return item;
            } else if (item.iSubsDateId == targetSubsDateId) {
                return item;
            }
            return false;
        });
        var distance = Number(foundItem.iDistance, 10) - Number(iMinusDistance, 10);
        if (distance < 0) {
            distance = 0;
        }
        var sum = (Number(distance, 10) * Number(String(formDataState.vPrice).replace(',', '.'), 10));
        if (sum > iMaxBedrag) {
            sum = iMaxBedrag;
        }
        sum = parseFloat(Number(sum).toFixed(2))
        updateVergoeding(sum, targetSubsDateId, day, newValue)
    }

    const updateVergoeding = (sum, targetSubsDateId, day, newValue) => {
        setSubstitutorssData(prevState => {
            return prevState.map(item => {
                if (item.shifts) {
                    return {
                        ...item,
                        shifts: item.shifts.map(shift => {
                            if (shift.iSubsDateId === targetSubsDateId) {
                                return {
                                    ...shift,
                                    Vergoeding: sum
                                };
                            }
                            return shift;
                        })
                    };
                }
                return item;
            });
        });
        setFormDataState(prevState => {
            var updatedList = prevState.substitutorShiftData && prevState.substitutorShiftData.list.map(item => {
                if (item.day === day) {
                    if ((item.data.Ochtend.iSubsDateId === targetSubsDateId)) {
                        return {
                            ...item,
                            data: {
                                ...item.data,
                                Ochtend: {
                                    ...item.data.Ochtend,
                                    Vergoeding: sum,
                                    iDistance: newValue
                                }
                            }
                        };
                    }
                    else if (item.data.Middag.iSubsDateId === targetSubsDateId) {
                        return {
                            ...item,
                            data: {
                                ...item.data,
                                Middag: {
                                    ...item.data.Middag,
                                    Vergoeding: sum,
                                    iDistance: newValue
                                }
                            }
                        };
                    }
                }
                return item;
            });
            return {
                ...prevState,
                substitutorShiftData: {
                    ...prevState.substitutorShiftData,
                    list: updatedList
                }
            };
        });
    };

    const updateVcorrectValue = (e) => {
        setFormDataState({ ...formDataState, vCorrect: e.target.value })
        setSubstitutorssData(prevState => {
            return prevState.map(item => {
                if (item.iSubstitutorAdminId === formDataState.selectedIds[currentIndex]) {
                    return {
                        ...item,
                        vCorrect: Number(e.target.value),
                    };
                }
                return item;
            });
        });
    }

    const handleMemoClick = (type) => {
        setMenuShow(false)
        if (type == "vervanger") {
            const updatedShowUser = { ...showUser, message: messageVervanger, type: type };
            setShowUser(updatedShowUser);
        }
        else {
            const updatedShowUser = { ...showUser, message: messageSalaris, type: type };
            setShowUser(updatedShowUser);
        }
    };

    const onChangeMessage = (value, type) => {
        const key = type === "vervanger" ? "messageVervanger" : "messageSalaris";
        setSubstitutorssData(prevState => {
            return prevState.map(item => {
                if (item.iSubstitutorAdminId === formDataState.selectedIds[currentIndex]) {
                    return {
                        ...item,
                        [key]: value,
                    };
                }
                return item;
            });
        });
        if (type === "vervanger") {
            setMessageVervanger(value);
        } else {
            setMessageSalaris(value);
        }
        setShowUser(prevShowUser => ({ ...prevShowUser, message: value }));
    }

    const handleSubmit = (type) => {
        setFormDataState(prevState => {
            const updatedState = { ...prevState, editable: null };
            if (type === "vervanger") {
                updatedState.messageVervanger = messageVervanger;
            } else {
                updatedState.messageSalaris = messageSalaris;
            }
            return updatedState;
        });
        setMemoIsOpen(false);
    }

    const onCancel = (type) => {
        if (type == "vervanger") {
            setMessageVervanger(formDataState.messageVervanger)
        } else {
            setMessageSalaris(formDataState.messageSalaris)
        }
        setMemoIsOpen(false)
    }

    const deleteTxt = (type) => {
        if (type == "vervanger") {
            setMessageVervanger('')
            setFormDataState({ ...formDataState, messageVervanger: '' })
            setSubstitutorssData(prevState => {
                return prevState.map(item => {
                    if (item.iSubstitutorAdminId === formDataState.selectedIds[currentIndex]) {
                        return {
                            ...item,
                            messageVervanger: '',
                        };
                    }
                    return item;
                });
            });
        } else {
            setMessageSalaris('')
            setFormDataState({ ...formDataState, messageSalaris: '' })
            setSubstitutorssData(prevState => {
                return prevState.map(item => {
                    if (item.iSubstitutorAdminId === formDataState.selectedIds[currentIndex]) {
                        return {
                            ...item,
                            messageSalaris: '',
                        };
                    }
                    return item;
                });
            });
        }
    }

    return (
        <div>
            <Row>
                <Col md="10" lg="10">
                    <PageHeading />
                </Col>
            </Row>
            {((shiftListLoading) || (!shiftsData || shiftsData.length === 0)) ?
                <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                :
                (
                    <Row>
                        <Col md="12" lg="12">
                            <Card className="main-card mb-3 sub-detail-table-style">
                                <CardBody className="p-3">
                                    <Table size="sm" borderless className="mb-0 ">
                                        <tbody>
                                            <tr className="border-bottom-0">
                                                <th className="fw-bold col-2">Vervanger</th>
                                                <th className="fw-bold col-2">Organisatie </th>
                                                <th className="fw-bold col-2">Periode</th>
                                                <th className="fw-bold col-2">Contracttype</th>
                                            </tr>
                                            <tr className="border-bottom-0">
                                                <td className="wl">
                                                    {window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(formDataState.substitutorShiftData.vFirstName, formDataState.substitutorShiftData.vMiddleName, formDataState.substitutorShiftData.vLastName)
                                                        : TextLimit.AfwezigeLimitOptionTwo(formDataState.substitutorShiftData.vFirstName, formDataState.substitutorShiftData.vMiddleName, formDataState.substitutorShiftData.vLastName)}
                                                </td>
                                                <td className="wl">
                                                    {formDataState.substitutorShiftData.communityName}
                                                </td>
                                                <td className="wl">
                                                    {formDataState.substitutorShiftData.startDate + " tot " + formDataState.substitutorShiftData.endDate}
                                                </td>
                                                <td className="wl">
                                                    {formDataState.substitutorShiftData.contractTypes}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                            <Card className="main-card mb-3 sub-detail-table-style">
                                <CardBody className="p-3">
                                    <Table hover size="sm" className="mb-0 coor-verzoeken-table">
                                        <thead>
                                            <tr>
                                                <th className="text-lef w-32 table-right-border table-bottom-border" colSpan="2">&nbsp;</th>
                                                <th colSpan="6" className="table-right-border text-center table-bottom-border py-2">Ochtend</th>
                                                <th colSpan="6" className="text-center table-bottom-border py-2">Middag</th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th className="text-left table-right-border py-2">Dag</th>

                                                <th className="py-2">Status</th>
                                                <th className="py-2">School</th>
                                                <th className="py-2">Code</th>
                                                <th className="py-2">DT</th>
                                                <th className="travelCost-card-text-width py-2">Km Afstand</th>
                                                <th className="table-right-border travelCost-card-text-width py-2">Vergoeding</th>

                                                <th className="py-2">Status</th>
                                                <th className="py-2">School</th>
                                                <th className="py-2">Code</th>
                                                <th className="py-2">Dt</th>
                                                <th className="travelCost-card-text-width py-2">Km Afstand </th>
                                                <th className="travelCost-card-text-width py-2">Vergoeding</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(formDataState.substitutorShiftData && formDataState.substitutorShiftData.list).map((key) => (
                                                <tr>
                                                    <td class="text-capitalize">
                                                    </td>
                                                    <td className="cursor-pointer table-right-border">
                                                        {new Date(moment(formDataState.substitutorShiftData.list[key].day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' })}
                                                        {" " + formDataState.substitutorShiftData.list[key].day}
                                                    </td>

                                                    {
                                                        formDataState.substitutorShiftData.list[key].data.Ochtend ?
                                                        <>
                                                            <td class="py-1">
                                                                {iconSwitch(formDataState.substitutorShiftData.list[key].data.Ochtend.eSubStatus)}
                                                            </td>
                                                            <td class="py-1">
                                                                {formDataState.substitutorShiftData.list[key].data.Ochtend.vSchoolName}
                                                            </td>
                                                            <td class="py-1">
                                                                {formDataState.substitutorShiftData.list[key].data.Ochtend.vCode}
                                                            </td>
                                                            <td class="py-1">
                                                                {formDataState.substitutorShiftData.list[key].data.Ochtend.DT}
                                                            </td>
                                                            <td class="py-1">
                                                                <input value={getValueBySubsDateId(formDataState.substitutorShiftData.list[key].data.Ochtend.iSubsDateId)} className="w-50 travelCost-card-input-style" min="0"
                                                                    onChange={(e) => { updateValueBySubsDateId(formDataState.substitutorShiftData.list[key].day, formDataState.substitutorShiftData.list[key].data.Ochtend.iSubsDateId, e.target.value) }}  type="number"/>
                                                            </td>
                                                            <td className="table-right-border py-1">
                                                                <input value={formDataState.substitutorShiftData.list[key].data.Ochtend.Vergoeding === 0 ? '0.00' : formDataState.substitutorShiftData.list[key].data.Ochtend.Vergoeding} readOnly className="w-50 travelCost-card-input-style cursor-auto"  />
                                                            </td>
                                                        </>
                                                        :
                                                        <td className="table-right-border py-1" colSpan={6}></td>
                                                    }

                                                    {
                                                        formDataState.substitutorShiftData.list[key].data.Middag ?
                                                            <>
                                                                <td class="py-1">
                                                                    {iconSwitch(formDataState.substitutorShiftData.list[key].data.Middag.eSubStatus)}
                                                                </td>
                                                                <td class="py-1">
                                                                    {formDataState.substitutorShiftData.list[key].data.Middag.vSchoolName}
                                                                </td>
                                                                <td class="py-1">
                                                                    {formDataState.substitutorShiftData.list[key].data.Middag.vCode}
                                                                </td>
                                                                <td class="py-1">
                                                                    {formDataState.substitutorShiftData.list[key].data.Middag.DT}
                                                                </td>
                                                                <td class="py-1">
                                                                    <input value={getValueBySubsDateId(formDataState.substitutorShiftData.list[key].data.Middag.iSubsDateId)} className="w-50 travelCost-card-input-style cursor-default" min="0"
                                                                        onChange={(e) => {  updateValueBySubsDateId(formDataState.substitutorShiftData.list[key].day, formDataState.substitutorShiftData.list[key].data.Middag.iSubsDateId, e.target.value) }}  type="number"/>
                                                                </td>
                                                                <td className="py-1">
                                                                    <input value={formDataState.substitutorShiftData.list[key].data.Middag.Vergoeding === 0 ? '0.00' : formDataState.substitutorShiftData.list[key].data.Middag.Vergoeding} readOnly className="w-50 travelCost-card-input-style cursor-auto"  type="number"/>
                                                                </td>
                                                            </>
                                                            : <td colSpan={6} className="py-1"> </td>
                                                    }  
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                            <Card className="main-card mb-3 sub-detail-table-style">
                                <CardBody className="p-2">
                                    <Row>
                                        <Col md="6" lg="6" className="">
                                            {
                                                formDataState.messageSalaris != '' &&
                                                <>
                                                    <Row className="justify-content-start my-3">
                                                        <Col className="travelCost-text-head d-block">Memo voor salarisadministratie:-</Col>
                                                    </Row>
                                                    <Row className="justify-content-center my-3">
                                                        <Col className="travelCost-row-text-data">
                                                            {
                                                                formDataState.editable == "administrator" ?
                                                                    (
                                                                        <div>
                                                                            <textarea className="travelCost-textArea"
                                                                                value={messageSalaris}
                                                                                onChange={(e) => {
                                                                                    onChangeMessage(e.target.value, 'administrator')
                                                                                }}
                                                                            />
                                                                            <Button className="d-flex btn-color-style justify-content-center my-3 w-25" size="sm" outline color="primary" type="submit" onClick={() => handleSubmit('administrator')} >
                                                                                Opslaan
                                                                            </Button>
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            <div>{formDataState.messageSalaris.length < 45 ? formDataState.messageSalaris : formDataState.messageSalaris.substring(0, 45) + "..."}</div>
                                                                            <EditPen className="mx-1" onClick={() => setFormDataState({ ...formDataState, editable: "administrator" })} />
                                                                            <Delete onClick={() => deleteTxt('administrator')} />
                                                                        </>
                                                                    )
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                            {formDataState.messageVervanger != '' &&
                                                <>
                                                    <Row className="justify-content-start my-3">
                                                        <Col className="travelCost-text-head d-block">Memo voor vervanger:-</Col>
                                                    </Row>
                                                    <Row className="justify-content-center my-3">
                                                        <Col className="travelCost-row-text-data">
                                                            {
                                                                formDataState.editable == "vervanger" ?
                                                                    (
                                                                        <div>
                                                                            <textarea className="travelCost-textArea"
                                                                                value={messageVervanger}
                                                                                onChange={(e) => {
                                                                                    onChangeMessage(e.target.value, 'vervanger')
                                                                                }}
                                                                            />
                                                                            <Button className="d-flex btn-color-style justify-content-center my-3 w-25" size="sm" outline color="primary" type="submit" onClick={() => { handleSubmit('vervanger') }} >
                                                                                Opslaan
                                                                            </Button>
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            <div>{formDataState.messageVervanger.length < 45 ? formDataState.messageVervanger : formDataState.messageVervanger.substring(0, 45) + "..."}</div>
                                                                            <EditPen className="mx-1" onClick={() => setFormDataState({ ...formDataState, editable: "vervanger" })} />
                                                                            <Delete onClick={() => deleteTxt('vervanger')} />
                                                                        </>
                                                                    )
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </Col>
                                        <Col md="6" lg="6" className="justify-content-end align-items-center">
                                            <Row className="justify-content-end my-3">
                                                <Col md="2" lg="2" className="travelCost-text-head">Afstand (Km)</Col>
                                                <Col md="2" lg="2" className="travelCost-text-data">{distanceTotal}</Col>
                                            </Row>
                                            <Row className="justify-content-end my-3">
                                                <Col md="2" lg="2" className="travelCost-text-head">Subtotaal</Col>
                                                <Col md="2" lg="2" className="travelCost-text-data">€ {Number(priceTotal).toFixed(2)}</Col>
                                            </Row>
                                            <Row className="justify-content-end my-3">
                                                <Col md="2" lg="2" className="travelCost-text-head">Correctie</Col>
                                                <Col md="2" lg="2" className="travelCost-text-data">
                                                    <span>€</span>
                                                    <input className="travelCost-correct-input ms-2" value={formDataState.vCorrect} onChange={updateVcorrectValue} />
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-end my-3">
                                                <Col md="2" lg="2" className="travelCost-text-head">Totaal</Col>
                                                <Col md="2" lg="2" className="travelCost-text-data">€ {(Number(priceTotal) + Number(formDataState.vCorrect)).toFixed(2)}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )
            }
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex justify-content-between">
                        <UncontrolledButtonDropdown isOpen={menuShow}>
                            <DropdownToggle color="link" className="p-0">
                                <Button className="d-flex align-items-center btn-color-style markeren-btn mb-2 me-2" size="sm" outline color="primary" onClick={() => menuIsOpen()}>
                                    <Memo />
                                </Button>
                            </DropdownToggle>
                            <DropdownMenu className={"rm-pointers dropdown-menu-lg travelCost-dropdown"}>
                                <Nav vertical>
                                    <NavLink className="travelCost-light-text" onClick={() => { setMemoIsOpen(true); handleMemoClick('administrator') }}>
                                        Memo voor salarisadministratie
                                    </NavLink>

                                    <NavLink className="border-top travelCost-light-text" onClick={() => { setMemoIsOpen(true); handleMemoClick('vervanger') }}>
                                        Memo voor vervanger
                                    </NavLink>
                                </Nav>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <div>
                            <Button
                                outline
                                className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn"
                                onClick={() => { skipSubstitutorId(); handleButtonClick() }}
                                type="button"
                                color="primary"
                            >
                                Overslaan
                            </Button>
                            <Button
                                outline
                                className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn mx-2"
                                onClick={() => { updateSubstitutors(); handleButtonClick() }}
                                type="button"
                                color="primary"
                            >
                                Verwerken ({currentIndex + 1}/{formDataState.selectedIds.length})
                            </Button>
                        </div>
                        <Modal id={"tooltip"} isOpen={memoIsOpen} toggle={() => setMemoIsOpen(!memoIsOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling info-modal-width ">
                            <ModalHeader>
                                <div className="modal-header-styling">
                                    {showUser.type == 'vervanger' ? 'Memo voor vervanger' : 'Memo voor salarisadministratie'}
                                </div>
                                <div className="cross-icon-color">
                                    <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => onCancel(showUser.type)}> </i>
                                </div>
                            </ModalHeader>

                            <ModalBody className="bg-white rounded">
                                <div className="d-flex justify-content-center">
                                    <textarea onChange={(e) => {
                                        onChangeMessage(e.target.value, showUser.type)
                                    }}
                                        value={showUser.message}
                                        className="form-style opmerking-input-styling my-2 travelCost-light-text"
                                        rows="6"
                                    ></textarea>
                                </div>
                                <ModalFooter>
                                    <Button className="d-flex align-items-center btn-color-style  opslaan-btn" size="sm" outline color="primary" onClick={() => handleSubmit(showUser.type)}>
                                        <Save />
                                        Opslaan
                                    </Button>
                                    <Button className="d-flex align-items-center btn-color-style back-btn" size="sm" outline color="primary" onClick={() => onCancel(showUser.type)}>
                                        <Small_Arrow />
                                        Terug
                                    </Button>
                                </ModalFooter>
                            </ModalBody>
                        </Modal>
                    </div>
                </CardFooter>
            </div>
        </div>
    )
};

export default Calculate;
import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Card, CardBody, CardFooter } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ExpandableTable from "./ExpandableTable";
import { ReactComponent as SearchIcon } from "../../../../assets/utils/images/svgs/search.svg";
import { ReactComponent as AddIcon } from "../../../../assets/utils/images/svgs/addBlue.svg";
import GebruikersService from "Api/Gebruikers";

const LinkPage = () => {
    const [filterData, setFilterData] = useState({
        community: '',
        user: '',
        contractType: '',
    });
    const [search, setSearch] = useState('');
    const [communities, setCommunities] = useState([]);
    const [contractList, setContractList] = useState([]);

    useEffect(() => {
        communityContractList();
    }, []);

    useEffect(() => {
        if (filterData?.community && communities?.length > 0) {
            const data = communities.find(item => filterData.community === String(item.iCommunityId))
            if (data) {
                setContractList(data?.contracttypes)
            }
        }
    }, [filterData?.community, communities])

    const communityContractList = () => {
        GebruikersService.getCommunityContractList().then((response) => {
            if (response.status === 200) {
                setCommunities(response.data.data)
            }
        }).catch((err) => console.error(err));
    }

    const filterHandler = (type, value) => {
        setFilterData((prev) => {
            return { ...prev, [type]: value }
        })
    }

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Card className="main-card mb-5 gebruikers-tb">
                        <CardBody className="d-flex flex-column">
                            <div className="form-group">
                                <SearchIcon />
                                <input type="text" className="form-control mb-2 search" placeholder="Zoeken..." onChange={(e) => setSearch(e.target.value)} />
                            </div>
                            <ExpandableTable filterData={filterData} search={search} />
                        </CardBody>
                    </Card>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                    <div className="d-flex gap-3">
                        <select className={`form-select`} value={filterData.community} onChange={(e) => { 
                            filterHandler('community', e.target.value); 
                            filterHandler('contractType', '')
                            }}>
                            <option value={''}>Alle besturen</option>
                            {communities?.map(item => {
                                return <option value={item.iCommunityId}>{item.vCommunityName}</option>
                            })}
                        </select>
                        <select className={`form-select`} value={filterData.user} onChange={(e) => { filterHandler('user', e.target.value) }}>
                            <option value={''}>Alle gebruikers</option>
                            <option value='vervanger'>Vervangers</option>
                            <option value='melder'>Melders</option>
                            <option value='coordinator'>Coördinatoren</option>
                        </select>
                        <select className={`form-select select`} value={filterData.contractType} onChange={(e) => { filterHandler('contractType', e.target.value) }}>
                            <option value={''}>Alle contracttypen</option>
                            {contractList?.map(item => {
                                return <option value={item.id}>{`${item.abbr} - ${item.name}`}</option>
                            })}
                        </select>
                        <button className="btn btn-outline-primary btn-color-style d-flex justify-content-center align-items-center gap-2">
                            Excel <AddIcon />
                        </button>
                    </div>
                </CardFooter>
            </div>
        </Fragment>
    )
}
export default LinkPage
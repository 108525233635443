import React, { useState } from "react";
import DefaultModal from 'Components/Modal/DefaultModal';
import { Input, Table } from "reactstrap";
import axiosInstance from "Api";

import PopupBasic from "Components/Popup/BasicPopup";
import { BiLock } from "react-icons/bi";


const CommunityTypesModal = ({ isOpen, open, communities, setType, text, iAdminId="" }) => {

    const handleSubmit = (commId, commType) => {
        const type = commType === "S" ? "A" : "S";
        const url = `${iAdminId  ? `gebruiker/` : ''}availability/${commId}/edit?type=${type}${iAdminId  ? `&iAdminId=${iAdminId}` : ''}`;
        axiosInstance.get(url).then((response) => {
            if (response.data.status === "success") {
                setType(commId+commType);
            }
        })
        .finally(() => open(false))
        .catch(() => console.error);
    }

    return (
        <DefaultModal 
            modal={isOpen}
            headerTitle={"Instellingen"}
            toggle={() => open(false)} 
            showFooterBtns={false}
            classes="modal-dialog__over feedback-modal-style availability-modal wc-500"
        >

            <p>{text}</p>

            <Table borderless className="mb-0">
                <tbody>
                    {
                        communities.length && communities.map((comm, id) => 
                            <tr key={id}>
                                <th className="col-1">{comm.name}</th>
                                <td className="popup-parent">
                                    
                                    {comm.type !== "S" && comm.locked != 0 ?
                                    <>
                                        <PopupBasic
                                            name={
                                                <Input
                                                    id={`popup-${id}`}
                                                    type="checkbox"
                                                    name={comm.name}
                                                    value={comm.type}
                                                    disabled={true}
                                                    checked={true}
                                                />                                                
                                            }
                                            content={
                                                <div className="d-flex justify-content-between align-items-center gap-3">
                                                    <BiLock fontSize={"50px"} />
                                                    <p>
                                                        Sommige dagdelen zijn geblokkeerd, wilt u de beschikbaarheid wijzigen neem dan contact op met de des betreffende coördinator
                                                    </p>
                                                </div>
                                            }
                                        />
                                    </>
                                        :
                                        <Input
                                        type="checkbox"
                                        name={comm.name}
                                        value={comm.type}
                                        onChange={e => handleSubmit(comm.id, e.target.value)}
                                        checked={comm.type === "A"}
                                        />
                                    }
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </DefaultModal>
    )
}

export default CommunityTypesModal;
